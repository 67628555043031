import styled from "styled-components";
import {presentation} from "../proto/compiled";
import {observer} from "mobx-react-lite";
import {useContext} from "react";
import {TpStoreContext} from "../App";
import {StyledProps} from "../theme";
import {MdArrowBack, MdArrowForward} from "react-icons/md";
import useWave from "use-wave";

const Container = styled.div`
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 100%;
  display: flex;

  * {
    pointer-events: auto;
  }
`;

const IconButton = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: 70px;
  height: 70px;
  border: solid 1px ${({theme}: StyledProps) => theme.content};
  background-color: ${({theme}: StyledProps) => theme.primary};
  transition: all 300ms;
  color: ${({theme}: StyledProps) => theme.content};
  font-size: 30px;

  :hover {
    opacity: 1;
  }
`;

const Positioned = styled.div<{ top?: string, left?: string, bottom?: string, right?: string }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: ${({top}) => top};
  bottom: ${({bottom}) => bottom};
  left: ${({left}) => left};
  right: ${({right}) => right};
`;

export const PresentationControls = observer(() => {
    const store = useContext(TpStoreContext).presentation;
    const wave = useWave();
    return <Container>
        {store.presentationSlice?.prevSlideId && <Positioned top={'0px'} bottom={'0px'} left={'8px'}>
            <IconButton ref={wave} onClick={() => store.prev()}>
                <MdArrowBack/>
            </IconButton>
        </Positioned>}
        {store.presentationSlice?.nextSlideId && <Positioned top={'0px'} bottom={'0px'} right={'8px'}>
            <IconButton ref={wave} onClick={() => store.next()}>
                <MdArrowForward/>
            </IconButton>
        </Positioned>}
    </Container>;
})