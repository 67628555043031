import styled from "styled-components";
import {presentation} from "../proto/compiled";
import {STATIC_BASE} from "../strings";

const Container = styled.div`
  position: absolute;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  * {
    pointer-events: auto;
  }
`;

interface PresentationContentVideoProps {
    content: presentation.IContentVideo
}

export const PresentationContentVideo = (props: PresentationContentVideoProps) => {
    return <Container>
        {STATIC_BASE + props.content.src}
    </Container>;
}