import {observer} from "mobx-react-lite";
import styled, {useTheme} from "styled-components";
import {Theme} from "../theme";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import useWave from 'use-wave'
import {ApiContext} from "../App";
import {useContext, useEffect, useState} from "react";
import {OverlayContainer} from "./overlays";
import {DialogContainer} from "./dialogs";
import {MdArrowBack} from "react-icons/md";
import {IconButton} from "./decorations";
import {useNavigate} from "react-router-dom";

const Container = styled.div`
  display: flex;
  height: 100vh;
`;

const PathContainer = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  height: 54px;
  left: 16px;
  top: 8px;
`;

export const TpScaffold = observer(({
                                        children,
                                        path,
                                        onBack,
                                        waitConnected
                                    }: { children: any, path?: any[], onBack?: () => any, waitConnected?: boolean }) => {
    const api = useContext(ApiContext);
    const [connected, setConnected] = useState(api.connection.connected);
    useEffect(() => {
        if (waitConnected)
            api.connection.whenConnected.then(() => setConnected(true));
    }, [api, waitConnected]);

    const theme = useTheme() as Theme;
    const navigate = useNavigate();
    const wave = useWave();

    return <Container>
        {(!waitConnected || connected) && children}
        {(path?.length || undefined) && <PathContainer>
            <IconButton
                ref={wave}
                style={{marginRight: '8px'}}
                onClick={() => onBack ? onBack() : navigate(-1)}><MdArrowBack/></IconButton>
            {path?.map((p) => <theme.textTheme.headline5.span key={p ?? 'unknown'}>
                {p}
            </theme.textTheme.headline5.span>)}
        </PathContainer>}
        <ConnectedOverlay/>
        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    </Container>
});

export const ConnectedOverlay = () => {
    const api = useContext(ApiContext);
    const theme = useTheme() as Theme;

    // handle connected overlay
    const [connected, setConnected] = useState(true);
    useEffect(() => {
        const sub = api.connection.connectedChanges.subscribe((connected) => setConnected(connected));
        const timeout = new Promise((res) => setTimeout(() => res(null), 1000));
        Promise.race([api.connection.whenConnected, timeout]).then(
            (_) => setConnected(api.connection.connected)
        );
        return () => sub.unsubscribe();
    }, [api.connection]);

    return <OverlayContainer hidden={connected}>
        <DialogContainer style={{padding: '50px'}}>
            <theme.textTheme.headline3.span>
                Povezava s strežnikom ni uspela!
            </theme.textTheme.headline3.span>
            <theme.textTheme.body.span>
                <ol>
                    <li>Preverite ali imate delujočo internetno povezavo.</li>
                    <li>Poskusite malo kasneje. Morda poteka nadgradnja ali vzdrževanje sistema.</li>
                </ol>
            </theme.textTheme.body.span>
        </DialogContainer>
    </OverlayContainer>;
};