import styled from "styled-components";
import {StyledProps} from "../theme";
import {OverlayContainer} from "./overlays";
import {TpButton} from "./buttons";
import {useEffect, useState} from "react";
import useWave from "use-wave";

export const DialogFooter = styled.div`
  width: 100%;
  border-top: solid 1px #CCCCCC;
  background-color: ${({theme}: StyledProps) => theme.content10};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DialogContainer = styled.div<{ width?: string, height?: string }>`
  position: relative;
  background-color: ${({theme}: StyledProps) => theme.neutral};
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0.25em 0.5em rgba(0, 45, 98, .3);
  max-width: ${({width}) => width};
  max-height: ${({height}) => height};
  width: ${({width}) => width ? 'calc(100% - 32px)' : ''};
  height: ${({width}) => width ? 'calc(100% - 32px)' : ''};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ContentUnitFrame = styled.div<{ padding?: string }>`
  position: relative;
  padding: ${({padding}) => padding};
  border: solid 1px ${({theme}: StyledProps) => theme.content};
`;

export const ContentUnitFrameSnack = styled.div<{ padding?: string, error?: boolean }>`
  position: absolute;
  top: 100%;
  left: -1px;
  right: -1px;
  background-color: ${({theme, error}: StyledProps) => error ? theme.error : theme.content};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px;
  ${({theme}: StyledProps) => theme.textTheme.headline6.with({color: 'white'}).css};
`;

export const YesNoDialog = ({children, result}: { children?: any, result: (result: boolean) => any }) => {
    const [first, setFirst] = useState(true);
    useEffect(() => setFirst(false), []);
    const wave = useWave();

    return <OverlayContainer hidden={first}>
        <DialogContainer style={{padding: '0', width: '500px'}}>
            <div style={{
                marginTop: 'auto',
                marginBottom: 'auto',
                minHeight: '100px',
                padding: '16px 50px 16px 50px',
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}>
                {children}
            </div>
            <DialogFooter style={{height: '50px'}}>
                <TpButton
                    ref={wave}
                    onClick={() => {
                        setFirst(true);
                        setTimeout(() => result(false), 300);
                    }}
                    height={'30px'}
                    width={'110px'}
                    style={{padding: 0, justifyContent: 'center'}}
                >NE</TpButton>
                <TpButton
                    ref={wave}
                    onClick={() => {
                        setFirst(true);
                        setTimeout(() => result(true), 300);
                    }}
                    height={'30px'}
                    width={'110px'}
                    style={{padding: 0, justifyContent: 'center', marginLeft: '16px'}}
                >DA</TpButton>
            </DialogFooter>
        </DialogContainer>
    </OverlayContainer>;
};