import styled from "styled-components";
import React from "react";
import {StyledProps} from "../theme";

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto 0 auto;
`;

export const FormStepContainer = styled.div`
  margin-bottom: 60px;
  
  @media (max-width: 1216px) {
    padding: 0 16px 0 16px;
  }

  && * {
    box-sizing: border-box;
  }
`;

const FormContentContainerStyle = styled.div`
  width: 100%;
`;

export const FormContentContainer = ({children, step}: { children: any, step: number }) => {
    return <FormContentContainerStyle>{React.Children.toArray(children)[step]}</FormContentContainerStyle>;
}

export const HeaderContainer = styled.div`
  width: 100%;
  background-position: 50%;
  background-size: cover;
  padding: 60px 67px 60px 67px;
  text-align: center;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`

export const HeaderTitle = styled.span`
  ${({theme}: StyledProps) => theme.textTheme.headline1.css};
  padding-bottom: 8px;
`;

export const HeaderSubtitle = styled.span`
  ${({theme}: StyledProps) => theme.textTheme.headline3.css}
`;

export const FormStepsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const FormStep = styled.div<{ selected: boolean }>`
  background-color: ${({theme, selected}: StyledProps) => selected ? theme.primary : theme.neutral};
  cursor: pointer;
  transition: background-color 300ms ease;
  box-shadow: 0 0.25em 0.5em rgba(0, 45, 98, .3);
  margin: -22.5px 8px 0 8px;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({theme}: StyledProps) => theme.textTheme.headline5.css}
`;

export const IconButton = styled.div`
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  color: ${({theme}: StyledProps) => theme.content};
  font-size: 20px;
  width: 44px;
  height: 44px;
  border-radius: 10px;

  :hover {
    background-color: ${({theme}: StyledProps) => theme.content5};
  }
`;