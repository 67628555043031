import {makeAutoObservable} from "mobx";
import {SocketApi} from "proto_socket_typescript";
import {proto} from "../proto/messages";
import {org, tp_types} from "../proto/compiled";
import {TpRoutes} from "../strings";
import {PresentationStore} from "./presentation_store";

enum CurrentlyPicking {
    locations,
    sublocations,
    presentations,
}

export class TpStore {
    api: SocketApi;
    path: string[] = [];
    title?: string;
    items?: tp_types.ITpItem[];
    presentations?: org.Presentations;
    presentation: PresentationStore

    constructor(api: SocketApi) {
        this.api = api;
        this.presentation = new PresentationStore(api);

        this.api.getMessageHandler(new proto.RxLocations()).subscribe((m) => {
            this.title = undefined;
            this.items = m.proto.items;
        });
        this.api.getMessageHandler(new proto.RxSubLocations()).subscribe((m) => {
            this.title = m.proto.title;
            this.items = m.proto.items;
        });
        this.api.getMessageHandler(new proto.RxPresentations()).subscribe((m) => {
            this.title = m.proto.title;
            this.items = m.proto.items;
            this.presentations = m.proto
        });

        makeAutoObservable(this);
    }

    async loadLocations() {
        return await this.api.sendMessage(proto.TxLoadLocations.create(), {ack: true});
    }

    async loadSublocations(locationId: string) {
        return await this.api.sendMessage(proto.TxLoadSublocations.create({
            location: locationId,
        }), {ack: true});
    }

    async loadPresentations(sublocationId: string) {
        return await this.api.sendMessage(proto.TxLoadPresentations.create({
            sublocation: sublocationId,
        }), {ack: true});
    }

    get currentlyPicking(): CurrentlyPicking {
        const location = window.location.href;
        if (location.includes(TpRoutes.kSublocation)) return CurrentlyPicking.sublocations;
        if (location.includes(TpRoutes.kPresentation)) return CurrentlyPicking.presentations;
        return CurrentlyPicking.locations;
    }

    route(i: tp_types.ITpItem): string {
        switch (this.currentlyPicking) {
            case CurrentlyPicking.locations:
                return TpRoutes.pickSublocation(i.text!);
            case CurrentlyPicking.sublocations:
                return TpRoutes.pickPresentation(i.text!);
            case CurrentlyPicking.presentations:
                return TpRoutes.presentation(
                    this.presentations!.location!.text!,
                    this.presentations!.sublocation!.text!,
                    i.text!
                );
            default:
                return TpRoutes.pickLocation;
        }
    }
}
