import {observer} from "mobx-react-lite";
import styled, {useTheme} from "styled-components";
import React, {useContext, useEffect} from "react";
import {TpStoreContext} from "./App";
import {useParams} from "react-router-dom";
import {PresentationContent} from "./presentation/presentation_content";
import {PresentationControls} from "./presentation/presentation_controls";

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Presentation = observer(() => {
    const store = useContext(TpStoreContext);
    const {sublocationId, locationId, presentationKey, sequenceId, slideId} = useParams();

    // open presentation
    useEffect(() => {
        if (!locationId || !sublocationId || !presentationKey) return;
        store.presentation.open(locationId, sublocationId, presentationKey);
    }, [sublocationId, locationId, presentationKey, store.presentation, sequenceId, slideId]);

    // load slide
    useEffect(() => {
        if (!store.presentation.presentation) return;
        store.presentation.loadSlide(sequenceId, slideId);
    }, [sequenceId, slideId, store.presentation, store.presentation.presentation]);

    const slice = store.presentation.presentationSlice

    return <Container>
        {slice?.content && <PresentationContent content={slice.content}/>}
        {slice?.overlay && <PresentationContent content={slice.overlay}/>}
        {slice?.defaultControls && <PresentationControls/>}
    </Container>;
});