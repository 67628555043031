import React, {createContext} from 'react';
import {BrowserRouter, Route, Routes, useNavigate} from "react-router-dom";
import {TpScaffold} from "./components/tp_scaffold";
import {LocationPicker} from "./location_picker";
import MultiProvider from "./utils";
import {ThemeProvider} from "styled-components";
import {TpTheme} from "./theme";
import {SocketApi} from "proto_socket_typescript";
import {TpStore} from "./stores/tp_store";
import {observer} from "mobx-react-lite";
import {Presentation} from "./presentation";

const port = window.location.port === '3000' ? '8000' : window.location.port;
export const api = new SocketApi(`${window.location.protocol === 'https:' ? 'wss' : 'ws'}://${window.location.hostname}:${port}/ws/`);
const tpStore = new TpStore(api);
export const TpStoreContext = createContext<TpStore>(tpStore);
export const ApiContext = createContext<SocketApi>(api);

function App() {
    return (
        <MultiProvider providers={[
            <ThemeProvider theme={TpTheme}/>,
            <ApiContext.Provider value={api}/>,
            <TpStoreContext.Provider value={tpStore}/>,
        ]}>
            <BrowserRouter>
                <Routes>
                    <Route
                        path={'/'}
                        element={<TpScaffold>
                            <LocationPicker/>
                        </TpScaffold>}
                    />
                    <Route
                        path={'/sublocations/:locationId'}
                        element={<TpScaffold path={[tpStore.title]}>
                            <LocationPicker/>
                        </TpScaffold>}
                    />
                    <Route
                        path={'/presentations/:sublocationId'}
                        element={<TpScaffold path={[tpStore.title]}>
                            <LocationPicker/>
                        </TpScaffold>}
                    />
                    <Route
                        path={'/presentation/:locationId/:sublocationId/:presentationKey'}
                        element={<TpScaffold waitConnected={true}>
                            <Presentation/>
                        </TpScaffold>}
                    />
                    <Route
                        path={'/presentation/:locationId/:sublocationId/:presentationKey/:sequenceId/:slideId'}
                        element={<TpScaffold waitConnected={true}>
                            <Presentation/>
                        </TpScaffold>}
                    />
                </Routes>
            </BrowserRouter>
        </MultiProvider>
    );
}

export default observer(App);
