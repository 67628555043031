import {uploader, form_errors, org, socket_api, authentication, amzs_tp, sfiles, presentation} from "./compiled";
import {SocketRxMessage, SocketRxMessageData, SocketTxMessage} from "proto_socket_typescript";

export namespace proto {

export class TxGetMagicLink extends SocketTxMessage<amzs_tp.GetMagicLink> {
    static type: string = 'get-magic-link';
    proto: amzs_tp.GetMagicLink;
    protoClass = amzs_tp.GetMagicLink;
    

    constructor(proto: amzs_tp.GetMagicLink) {
        super(TxGetMagicLink.type, true);
        this.proto = proto;
    }

    static create(properties: amzs_tp.IGetMagicLink = {}) {
        return new TxGetMagicLink(amzs_tp.GetMagicLink.create(properties));
    }
}


export class TxLoginMagicLink extends SocketTxMessage<amzs_tp.LoginMagicLink> {
    static type: string = 'login-magic-link';
    proto: amzs_tp.LoginMagicLink;
    protoClass = amzs_tp.LoginMagicLink;
    

    constructor(proto: amzs_tp.LoginMagicLink) {
        super(TxLoginMagicLink.type, true);
        this.proto = proto;
    }

    static create(properties: amzs_tp.ILoginMagicLink = {}) {
        return new TxLoginMagicLink(amzs_tp.LoginMagicLink.create(properties));
    }
}


export class RxLoginMagicLinkStatus extends SocketRxMessage<amzs_tp.LoginMagicLinkStatus> {
    static type: string = 'login-magic-link-status';
    proto = amzs_tp.LoginMagicLinkStatus.create({});
    protoClass = amzs_tp.LoginMagicLinkStatus;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxLoginMagicLinkStatus.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxLoginMagicLinkStatus(message);
    };
}


export class RxUserProfileData extends SocketRxMessage<amzs_tp.UserProfileData> {
    static type: string = 'user-profile-data';
    proto = amzs_tp.UserProfileData.create({});
    protoClass = amzs_tp.UserProfileData;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUserProfileData.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUserProfileData(message);
    };
}


export class TxAppleLogin extends SocketTxMessage<authentication.AppleLogin> {
    static type: string = 'apple-login';
    proto: authentication.AppleLogin;
    protoClass = authentication.AppleLogin;
    

    constructor(proto: authentication.AppleLogin) {
        super(TxAppleLogin.type, true);
        this.proto = proto;
    }

    static create(properties: authentication.IAppleLogin = {}) {
        return new TxAppleLogin(authentication.AppleLogin.create(properties));
    }
}


export class TxLogin extends SocketTxMessage<authentication.Login> {
    static type: string = 'login';
    proto: authentication.Login;
    protoClass = authentication.Login;
    

    constructor(proto: authentication.Login) {
        super(TxLogin.type, true);
        this.proto = proto;
    }

    static create(properties: authentication.ILogin = {}) {
        return new TxLogin(authentication.Login.create(properties));
    }
}


export class RxLoginError extends SocketRxMessage<authentication.LoginError> {
    static type: string = 'login-error';
    proto = authentication.LoginError.create({});
    protoClass = authentication.LoginError;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxLoginError.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxLoginError(message);
    };
}


export class RxLoginToken extends SocketRxMessage<authentication.LoginToken> {
    static type: string = 'login-token';
    proto = authentication.LoginToken.create({});
    protoClass = authentication.LoginToken;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxLoginToken.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxLoginToken(message);
    };
}


export class RxTokenInvalid extends SocketRxMessage<authentication.TokenInvalid> {
    static type: string = 'token-invalid';
    proto = authentication.TokenInvalid.create({});
    protoClass = authentication.TokenInvalid;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxTokenInvalid.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxTokenInvalid(message);
    };
}


export class TxVerifyToken extends SocketTxMessage<authentication.VerifyToken> {
    static type: string = 'verify-token';
    proto: authentication.VerifyToken;
    protoClass = authentication.VerifyToken;
    

    constructor(proto: authentication.VerifyToken) {
        super(TxVerifyToken.type, true);
        this.proto = proto;
    }

    static create(properties: authentication.IVerifyToken = {}) {
        return new TxVerifyToken(authentication.VerifyToken.create(properties));
    }
}


export class RxFormErrors extends SocketRxMessage<form_errors.FormErrors> {
    static type: string = 'form-errors';
    proto = form_errors.FormErrors.create({});
    protoClass = form_errors.FormErrors;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxFormErrors.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxFormErrors(message);
    };
}


export class TxLoadLocations extends SocketTxMessage<org.LoadLocations> {
    static type: string = 'load-locations';
    proto: org.LoadLocations;
    protoClass = org.LoadLocations;
    

    constructor(proto: org.LoadLocations) {
        super(TxLoadLocations.type, true);
        this.proto = proto;
    }

    static create(properties: org.ILoadLocations = {}) {
        return new TxLoadLocations(org.LoadLocations.create(properties));
    }
}


export class TxLoadPresentations extends SocketTxMessage<org.LoadPresentations> {
    static type: string = 'load-presentations';
    proto: org.LoadPresentations;
    protoClass = org.LoadPresentations;
    

    constructor(proto: org.LoadPresentations) {
        super(TxLoadPresentations.type, true);
        this.proto = proto;
    }

    static create(properties: org.ILoadPresentations = {}) {
        return new TxLoadPresentations(org.LoadPresentations.create(properties));
    }
}


export class TxLoadSublocations extends SocketTxMessage<org.LoadSublocations> {
    static type: string = 'load-sublocations';
    proto: org.LoadSublocations;
    protoClass = org.LoadSublocations;
    

    constructor(proto: org.LoadSublocations) {
        super(TxLoadSublocations.type, true);
        this.proto = proto;
    }

    static create(properties: org.ILoadSublocations = {}) {
        return new TxLoadSublocations(org.LoadSublocations.create(properties));
    }
}


export class RxLocations extends SocketRxMessage<org.Locations> {
    static type: string = 'locations';
    proto = org.Locations.create({});
    protoClass = org.Locations;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxLocations.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxLocations(message);
    };
}


export class RxPresentations extends SocketRxMessage<org.Presentations> {
    static type: string = 'presentations';
    proto = org.Presentations.create({});
    protoClass = org.Presentations;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxPresentations.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxPresentations(message);
    };
}


export class RxSubLocations extends SocketRxMessage<org.SubLocations> {
    static type: string = 'sublocations';
    proto = org.SubLocations.create({});
    protoClass = org.SubLocations;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxSubLocations.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxSubLocations(message);
    };
}


export class TxLoadPresentation extends SocketTxMessage<presentation.LoadPresentation> {
    static type: string = 'load-presentation';
    proto: presentation.LoadPresentation;
    protoClass = presentation.LoadPresentation;
    

    constructor(proto: presentation.LoadPresentation) {
        super(TxLoadPresentation.type, true);
        this.proto = proto;
    }

    static create(properties: presentation.ILoadPresentation = {}) {
        return new TxLoadPresentation(presentation.LoadPresentation.create(properties));
    }
}


export class TxLoadPresentationSlice extends SocketTxMessage<presentation.LoadPresentationSlice> {
    static type: string = 'load-presentation-slice';
    proto: presentation.LoadPresentationSlice;
    protoClass = presentation.LoadPresentationSlice;
    

    constructor(proto: presentation.LoadPresentationSlice) {
        super(TxLoadPresentationSlice.type, true);
        this.proto = proto;
    }

    static create(properties: presentation.ILoadPresentationSlice = {}) {
        return new TxLoadPresentationSlice(presentation.LoadPresentationSlice.create(properties));
    }
}


export class RxPresentation extends SocketRxMessage<presentation.Presentation> {
    static type: string = 'presentation';
    proto = presentation.Presentation.create({});
    protoClass = presentation.Presentation;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxPresentation.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxPresentation(message);
    };
}


export class RxPresentationSlice extends SocketRxMessage<presentation.PresentationSlice> {
    static type: string = 'presentation-slice';
    proto = presentation.PresentationSlice.create({});
    protoClass = presentation.PresentationSlice;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxPresentationSlice.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxPresentationSlice(message);
    };
}


export class TxDeleteFile extends SocketTxMessage<sfiles.DeleteFile> {
    static type: string = 'delete-file';
    proto: sfiles.DeleteFile;
    protoClass = sfiles.DeleteFile;
    

    constructor(proto: sfiles.DeleteFile) {
        super(TxDeleteFile.type, true);
        this.proto = proto;
    }

    static create(properties: sfiles.IDeleteFile = {}) {
        return new TxDeleteFile(sfiles.DeleteFile.create(properties));
    }
}


export class RxUploadDone extends SocketRxMessage<sfiles.UploadDone> {
    static type: string = 'upload-done';
    proto = sfiles.UploadDone.create({});
    protoClass = sfiles.UploadDone;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUploadDone.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUploadDone(message);
    };
}


export class TxUploadEnd extends SocketTxMessage<sfiles.UploadEnd> {
    static type: string = 'upload-end';
    proto: sfiles.UploadEnd;
    protoClass = sfiles.UploadEnd;
    

    constructor(proto: sfiles.UploadEnd) {
        super(TxUploadEnd.type, true);
        this.proto = proto;
    }

    static create(properties: sfiles.IUploadEnd = {}) {
        return new TxUploadEnd(sfiles.UploadEnd.create(properties));
    }
}


export class RxUploadProgress extends SocketRxMessage<sfiles.UploadProgress> {
    static type: string = 'upload-progress';
    proto = sfiles.UploadProgress.create({});
    protoClass = sfiles.UploadProgress;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUploadProgress.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUploadProgress(message);
    };
}


export class TxUploadStart extends SocketTxMessage<sfiles.UploadStart> {
    static type: string = 'upload-start';
    proto: sfiles.UploadStart;
    protoClass = sfiles.UploadStart;
    

    constructor(proto: sfiles.UploadStart) {
        super(TxUploadStart.type, true);
        this.proto = proto;
    }

    static create(properties: sfiles.IUploadStart = {}) {
        return new TxUploadStart(sfiles.UploadStart.create(properties));
    }
}


export class RxUploadStartSlot extends SocketRxMessage<sfiles.UploadStartSlot> {
    static type: string = 'upload-start';
    proto = sfiles.UploadStartSlot.create({});
    protoClass = sfiles.UploadStartSlot;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUploadStartSlot.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUploadStartSlot(message);
    };
}


export class RxAck extends SocketRxMessage<socket_api.Ack> {
    static type: string = 'ack';
    proto = socket_api.Ack.create({});
    protoClass = socket_api.Ack;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxAck.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxAck(message);
    };
}


export class RxAsyncProgress extends SocketRxMessage<socket_api.AsyncProgress> {
    static type: string = 'async-progress';
    proto = socket_api.AsyncProgress.create({});
    protoClass = socket_api.AsyncProgress;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxAsyncProgress.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxAsyncProgress(message);
    };
}


export class RxUpgradeApiVersion extends SocketRxMessage<socket_api.UpgradeApiVersion> {
    static type: string = 'upgrade-api-version';
    proto = socket_api.UpgradeApiVersion.create({});
    protoClass = socket_api.UpgradeApiVersion;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUpgradeApiVersion.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUpgradeApiVersion(message);
    };
}


export class TxDeleteUFile extends SocketTxMessage<uploader.DeleteUFile> {
    static type: string = 'delete-ufile';
    proto: uploader.DeleteUFile;
    protoClass = uploader.DeleteUFile;
    

    constructor(proto: uploader.DeleteUFile) {
        super(TxDeleteUFile.type, true);
        this.proto = proto;
    }

    static create(properties: uploader.IDeleteUFile = {}) {
        return new TxDeleteUFile(uploader.DeleteUFile.create(properties));
    }
}


export class RxUploadSlot extends SocketRxMessage<uploader.UploadSlot> {
    static type: string = 'upload-slot';
    proto = uploader.UploadSlot.create({});
    protoClass = uploader.UploadSlot;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUploadSlot.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUploadSlot(message);
    };
}


export class RxUploadTask extends SocketRxMessage<uploader.UploadTask> {
    static type: string = 'upload-task';
    proto = uploader.UploadTask.create({});
    protoClass = uploader.UploadTask;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUploadTask.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUploadTask(message);
    };
}


export class TxUploadUFile extends SocketTxMessage<uploader.UploadUFile> {
    static type: string = 'upload-ufile';
    proto: uploader.UploadUFile;
    protoClass = uploader.UploadUFile;
    

    constructor(proto: uploader.UploadUFile) {
        super(TxUploadUFile.type, true);
        this.proto = proto;
    }

    static create(properties: uploader.IUploadUFile = {}) {
        return new TxUploadUFile(uploader.UploadUFile.create(properties));
    }
}


export const rxMessages: SocketRxMessage<any>[] = [
    new RxLoginMagicLinkStatus(),
    new RxUserProfileData(),
    new RxLoginError(),
    new RxLoginToken(),
    new RxTokenInvalid(),
    new RxFormErrors(),
    new RxLocations(),
    new RxPresentations(),
    new RxSubLocations(),
    new RxPresentation(),
    new RxPresentationSlice(),
    new RxUploadDone(),
    new RxUploadProgress(),
    new RxUploadStartSlot(),
    new RxAck(),
    new RxAsyncProgress(),
    new RxUpgradeApiVersion(),
    new RxUploadSlot(),
    new RxUploadTask()
];
}