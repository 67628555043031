import {observer} from "mobx-react-lite";
import styled, {useTheme} from "styled-components";
import {StyledProps, Theme} from "./theme";
import useWave from 'use-wave'
import React, {useContext, useEffect, useState} from "react";
import {ApiContext, TpStoreContext} from "./App";
import {useNavigate, useParams} from "react-router-dom";
import {HashLoader} from "react-spinners";


const Container = styled.div`
  width: 100%;
  margin: auto 0 auto 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  cursor: pointer;
  user-select: none;
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px;
  width: 200px;
  height: 40px;
  box-shadow: 0 0 0.25em rgba(0, 45, 98, .3);
  background-color: white;
  border-radius: 10px;
  padding: 8px;
  overflow: hidden;

  ${({theme}: StyledProps) => theme.textTheme.button.css}
  :hover {
    background-color: #EEEEEE;
  }
`;

const Item = ({children, onClick}: { children: any, onClick: () => any }) => {
    const wave = useWave();
    return <ItemContainer onClick={() => onClick()} ref={wave}>{children}</ItemContainer>;
};

export const LocationPicker = observer(() => {
    const store = useContext(TpStoreContext);
    const {sublocationId, locationId} = useParams();
    const navigate = useNavigate();
    const api = useContext(ApiContext);
    const [loading, setLoading] = useState(false);
    const [connected, setConnected] = useState(api.connection.connected);
    const theme = useTheme() as Theme;

    useEffect(() => {
        api.connection.whenConnected.then(() => setConnected(true));
    }, [api]);

    useEffect(() => {
        if (!connected) return;
        setLoading(true);
        let future;
        if (sublocationId) {
            future = store.loadPresentations(sublocationId);
        } else if (locationId) {
            future = store.loadSublocations(locationId);
        } else {
            future = store.loadLocations();
        }
        future.then(() => setLoading(false));
    }, [sublocationId, locationId, store, connected]);

    return <Container>
        {loading && <HashLoader color={theme.content}/>}
        {!loading && store.items?.map((i) => <Item
            key={i.text}
            onClick={() => navigate(store.route(i))}>
            {i.label}
        </Item>)}
    </Container>
        ;
});