import styled from "styled-components";
import JsxParser from "react-jsx-parser";
import {JsxContentTest} from "./content_jsx/jsx_content_test";
import {AmzsVprasanje} from "./content_jsx/jsx_vprasanje";

const Container = styled.div`
  position: absolute;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  * {
    pointer-events: auto;
  }
`;

interface PresentationContentJsxProps {
    content: string
}

export const PresentationContentJsx = (props: PresentationContentJsxProps) => {
    return <Container>
        <JsxParser
            bindings={{
                alertMe: (c: string) => {
                    alert(c);
                },
            }}
            components={{JsxContentTest, AmzsVprasanje}}
            jsx={props.content}/>
    </Container>;
}