export const STATIC_BASE = window.location.hostname === 'localhost' ? 'http://localhost:8000' : '';

export const TpRoutes = {
    kSublocation: 'sublocation',
    kPresentation: 'presentation',
    pickLocation: `/`,
    pickSublocation: (location: string) => `/sublocations/${location}`,
    pickPresentation: (sublocation: string) => `/presentations/${sublocation}`,
    presentation: (locationKey: string, sublocationKey: string, presentationKey: string) => `/presentation/${locationKey}/${sublocationKey}/${presentationKey}`,
    presentationSlide: (base: string, sequenceId: string, slideId: string) => `${base}/${sequenceId}/${slideId}`,
};