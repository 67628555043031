import styled from "styled-components";
import {presentation} from "../proto/compiled";
import {STATIC_BASE} from "../strings";

const Container = styled.div`
  position: absolute;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;

  * {
    pointer-events: auto;
  }
`;

const ContentImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

interface PresentationContentImageProps {
    content: presentation.IContentImage
}

export const PresentationContentImage = (props: PresentationContentImageProps) => {
    return <Container>
        <ContentImg src={STATIC_BASE + props.content.src} style={props.content.style ?? {}}/>
    </Container>;
}