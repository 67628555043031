import useWave from "use-wave";

export const JsxContentTest: any = ({text, handler}: { text?: string, handler: any }) => {
    const wave = useWave();

    return <div
        ref={wave}
        style={{
            width: '200px',
            height: '200px',
            backgroundColor: 'black',
            color: 'white',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '10px',
            textAlign: 'center',
            padding: '0 8px 0 8px',
            boxShadow: '0 0 0.2em 0.2em rgba(255, 255, 255, 0.4)',
        }}>
        {text}
    </div>
};