/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const amzs_tp = $root.amzs_tp = (() => {

    /**
     * Namespace amzs_tp.
     * @exports amzs_tp
     * @namespace
     */
    const amzs_tp = {};

    amzs_tp.GetMagicLink = (function() {

        /**
         * Properties of a GetMagicLink.
         * @memberof amzs_tp
         * @interface IGetMagicLink
         * @property {string|null} [mail] GetMagicLink mail
         */

        /**
         * Constructs a new GetMagicLink.
         * @memberof amzs_tp
         * @classdesc Represents a GetMagicLink.
         * @implements IGetMagicLink
         * @constructor
         * @param {amzs_tp.IGetMagicLink=} [properties] Properties to set
         */
        function GetMagicLink(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetMagicLink mail.
         * @member {string} mail
         * @memberof amzs_tp.GetMagicLink
         * @instance
         */
        GetMagicLink.prototype.mail = "";

        /**
         * Creates a new GetMagicLink instance using the specified properties.
         * @function create
         * @memberof amzs_tp.GetMagicLink
         * @static
         * @param {amzs_tp.IGetMagicLink=} [properties] Properties to set
         * @returns {amzs_tp.GetMagicLink} GetMagicLink instance
         */
        GetMagicLink.create = function create(properties) {
            return new GetMagicLink(properties);
        };

        /**
         * Encodes the specified GetMagicLink message. Does not implicitly {@link amzs_tp.GetMagicLink.verify|verify} messages.
         * @function encode
         * @memberof amzs_tp.GetMagicLink
         * @static
         * @param {amzs_tp.IGetMagicLink} message GetMagicLink message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMagicLink.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.mail != null && Object.hasOwnProperty.call(message, "mail"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.mail);
            return writer;
        };

        /**
         * Encodes the specified GetMagicLink message, length delimited. Does not implicitly {@link amzs_tp.GetMagicLink.verify|verify} messages.
         * @function encodeDelimited
         * @memberof amzs_tp.GetMagicLink
         * @static
         * @param {amzs_tp.IGetMagicLink} message GetMagicLink message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMagicLink.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetMagicLink message from the specified reader or buffer.
         * @function decode
         * @memberof amzs_tp.GetMagicLink
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {amzs_tp.GetMagicLink} GetMagicLink
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMagicLink.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.amzs_tp.GetMagicLink();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.mail = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetMagicLink message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof amzs_tp.GetMagicLink
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {amzs_tp.GetMagicLink} GetMagicLink
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMagicLink.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetMagicLink message.
         * @function verify
         * @memberof amzs_tp.GetMagicLink
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetMagicLink.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.mail != null && message.hasOwnProperty("mail"))
                if (!$util.isString(message.mail))
                    return "mail: string expected";
            return null;
        };

        /**
         * Creates a GetMagicLink message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof amzs_tp.GetMagicLink
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {amzs_tp.GetMagicLink} GetMagicLink
         */
        GetMagicLink.fromObject = function fromObject(object) {
            if (object instanceof $root.amzs_tp.GetMagicLink)
                return object;
            let message = new $root.amzs_tp.GetMagicLink();
            if (object.mail != null)
                message.mail = String(object.mail);
            return message;
        };

        /**
         * Creates a plain object from a GetMagicLink message. Also converts values to other types if specified.
         * @function toObject
         * @memberof amzs_tp.GetMagicLink
         * @static
         * @param {amzs_tp.GetMagicLink} message GetMagicLink
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetMagicLink.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.mail = "";
            if (message.mail != null && message.hasOwnProperty("mail"))
                object.mail = message.mail;
            return object;
        };

        /**
         * Converts this GetMagicLink to JSON.
         * @function toJSON
         * @memberof amzs_tp.GetMagicLink
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetMagicLink.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetMagicLink;
    })();

    amzs_tp.LoginMagicLink = (function() {

        /**
         * Properties of a LoginMagicLink.
         * @memberof amzs_tp
         * @interface ILoginMagicLink
         * @property {string|null} [token] LoginMagicLink token
         */

        /**
         * Constructs a new LoginMagicLink.
         * @memberof amzs_tp
         * @classdesc Represents a LoginMagicLink.
         * @implements ILoginMagicLink
         * @constructor
         * @param {amzs_tp.ILoginMagicLink=} [properties] Properties to set
         */
        function LoginMagicLink(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoginMagicLink token.
         * @member {string} token
         * @memberof amzs_tp.LoginMagicLink
         * @instance
         */
        LoginMagicLink.prototype.token = "";

        /**
         * Creates a new LoginMagicLink instance using the specified properties.
         * @function create
         * @memberof amzs_tp.LoginMagicLink
         * @static
         * @param {amzs_tp.ILoginMagicLink=} [properties] Properties to set
         * @returns {amzs_tp.LoginMagicLink} LoginMagicLink instance
         */
        LoginMagicLink.create = function create(properties) {
            return new LoginMagicLink(properties);
        };

        /**
         * Encodes the specified LoginMagicLink message. Does not implicitly {@link amzs_tp.LoginMagicLink.verify|verify} messages.
         * @function encode
         * @memberof amzs_tp.LoginMagicLink
         * @static
         * @param {amzs_tp.ILoginMagicLink} message LoginMagicLink message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginMagicLink.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
            return writer;
        };

        /**
         * Encodes the specified LoginMagicLink message, length delimited. Does not implicitly {@link amzs_tp.LoginMagicLink.verify|verify} messages.
         * @function encodeDelimited
         * @memberof amzs_tp.LoginMagicLink
         * @static
         * @param {amzs_tp.ILoginMagicLink} message LoginMagicLink message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginMagicLink.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoginMagicLink message from the specified reader or buffer.
         * @function decode
         * @memberof amzs_tp.LoginMagicLink
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {amzs_tp.LoginMagicLink} LoginMagicLink
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginMagicLink.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.amzs_tp.LoginMagicLink();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.token = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoginMagicLink message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof amzs_tp.LoginMagicLink
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {amzs_tp.LoginMagicLink} LoginMagicLink
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginMagicLink.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoginMagicLink message.
         * @function verify
         * @memberof amzs_tp.LoginMagicLink
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoginMagicLink.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.token != null && message.hasOwnProperty("token"))
                if (!$util.isString(message.token))
                    return "token: string expected";
            return null;
        };

        /**
         * Creates a LoginMagicLink message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof amzs_tp.LoginMagicLink
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {amzs_tp.LoginMagicLink} LoginMagicLink
         */
        LoginMagicLink.fromObject = function fromObject(object) {
            if (object instanceof $root.amzs_tp.LoginMagicLink)
                return object;
            let message = new $root.amzs_tp.LoginMagicLink();
            if (object.token != null)
                message.token = String(object.token);
            return message;
        };

        /**
         * Creates a plain object from a LoginMagicLink message. Also converts values to other types if specified.
         * @function toObject
         * @memberof amzs_tp.LoginMagicLink
         * @static
         * @param {amzs_tp.LoginMagicLink} message LoginMagicLink
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoginMagicLink.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.token = "";
            if (message.token != null && message.hasOwnProperty("token"))
                object.token = message.token;
            return object;
        };

        /**
         * Converts this LoginMagicLink to JSON.
         * @function toJSON
         * @memberof amzs_tp.LoginMagicLink
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoginMagicLink.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LoginMagicLink;
    })();

    amzs_tp.LoginMagicLinkStatus = (function() {

        /**
         * Properties of a LoginMagicLinkStatus.
         * @memberof amzs_tp
         * @interface ILoginMagicLinkStatus
         * @property {string|null} [status] LoginMagicLinkStatus status
         * @property {boolean|null} [error] LoginMagicLinkStatus error
         */

        /**
         * Constructs a new LoginMagicLinkStatus.
         * @memberof amzs_tp
         * @classdesc Represents a LoginMagicLinkStatus.
         * @implements ILoginMagicLinkStatus
         * @constructor
         * @param {amzs_tp.ILoginMagicLinkStatus=} [properties] Properties to set
         */
        function LoginMagicLinkStatus(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoginMagicLinkStatus status.
         * @member {string} status
         * @memberof amzs_tp.LoginMagicLinkStatus
         * @instance
         */
        LoginMagicLinkStatus.prototype.status = "";

        /**
         * LoginMagicLinkStatus error.
         * @member {boolean} error
         * @memberof amzs_tp.LoginMagicLinkStatus
         * @instance
         */
        LoginMagicLinkStatus.prototype.error = false;

        /**
         * Creates a new LoginMagicLinkStatus instance using the specified properties.
         * @function create
         * @memberof amzs_tp.LoginMagicLinkStatus
         * @static
         * @param {amzs_tp.ILoginMagicLinkStatus=} [properties] Properties to set
         * @returns {amzs_tp.LoginMagicLinkStatus} LoginMagicLinkStatus instance
         */
        LoginMagicLinkStatus.create = function create(properties) {
            return new LoginMagicLinkStatus(properties);
        };

        /**
         * Encodes the specified LoginMagicLinkStatus message. Does not implicitly {@link amzs_tp.LoginMagicLinkStatus.verify|verify} messages.
         * @function encode
         * @memberof amzs_tp.LoginMagicLinkStatus
         * @static
         * @param {amzs_tp.ILoginMagicLinkStatus} message LoginMagicLinkStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginMagicLinkStatus.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.status);
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.error);
            return writer;
        };

        /**
         * Encodes the specified LoginMagicLinkStatus message, length delimited. Does not implicitly {@link amzs_tp.LoginMagicLinkStatus.verify|verify} messages.
         * @function encodeDelimited
         * @memberof amzs_tp.LoginMagicLinkStatus
         * @static
         * @param {amzs_tp.ILoginMagicLinkStatus} message LoginMagicLinkStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginMagicLinkStatus.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoginMagicLinkStatus message from the specified reader or buffer.
         * @function decode
         * @memberof amzs_tp.LoginMagicLinkStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {amzs_tp.LoginMagicLinkStatus} LoginMagicLinkStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginMagicLinkStatus.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.amzs_tp.LoginMagicLinkStatus();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.status = reader.string();
                    break;
                case 2:
                    message.error = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoginMagicLinkStatus message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof amzs_tp.LoginMagicLinkStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {amzs_tp.LoginMagicLinkStatus} LoginMagicLinkStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginMagicLinkStatus.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoginMagicLinkStatus message.
         * @function verify
         * @memberof amzs_tp.LoginMagicLinkStatus
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoginMagicLinkStatus.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                if (!$util.isString(message.status))
                    return "status: string expected";
            if (message.error != null && message.hasOwnProperty("error"))
                if (typeof message.error !== "boolean")
                    return "error: boolean expected";
            return null;
        };

        /**
         * Creates a LoginMagicLinkStatus message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof amzs_tp.LoginMagicLinkStatus
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {amzs_tp.LoginMagicLinkStatus} LoginMagicLinkStatus
         */
        LoginMagicLinkStatus.fromObject = function fromObject(object) {
            if (object instanceof $root.amzs_tp.LoginMagicLinkStatus)
                return object;
            let message = new $root.amzs_tp.LoginMagicLinkStatus();
            if (object.status != null)
                message.status = String(object.status);
            if (object.error != null)
                message.error = Boolean(object.error);
            return message;
        };

        /**
         * Creates a plain object from a LoginMagicLinkStatus message. Also converts values to other types if specified.
         * @function toObject
         * @memberof amzs_tp.LoginMagicLinkStatus
         * @static
         * @param {amzs_tp.LoginMagicLinkStatus} message LoginMagicLinkStatus
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoginMagicLinkStatus.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.status = "";
                object.error = false;
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = message.status;
            if (message.error != null && message.hasOwnProperty("error"))
                object.error = message.error;
            return object;
        };

        /**
         * Converts this LoginMagicLinkStatus to JSON.
         * @function toJSON
         * @memberof amzs_tp.LoginMagicLinkStatus
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoginMagicLinkStatus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LoginMagicLinkStatus;
    })();

    amzs_tp.UserProfileData = (function() {

        /**
         * Properties of a UserProfileData.
         * @memberof amzs_tp
         * @interface IUserProfileData
         * @property {string|null} [name] UserProfileData name
         */

        /**
         * Constructs a new UserProfileData.
         * @memberof amzs_tp
         * @classdesc Represents a UserProfileData.
         * @implements IUserProfileData
         * @constructor
         * @param {amzs_tp.IUserProfileData=} [properties] Properties to set
         */
        function UserProfileData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserProfileData name.
         * @member {string} name
         * @memberof amzs_tp.UserProfileData
         * @instance
         */
        UserProfileData.prototype.name = "";

        /**
         * Creates a new UserProfileData instance using the specified properties.
         * @function create
         * @memberof amzs_tp.UserProfileData
         * @static
         * @param {amzs_tp.IUserProfileData=} [properties] Properties to set
         * @returns {amzs_tp.UserProfileData} UserProfileData instance
         */
        UserProfileData.create = function create(properties) {
            return new UserProfileData(properties);
        };

        /**
         * Encodes the specified UserProfileData message. Does not implicitly {@link amzs_tp.UserProfileData.verify|verify} messages.
         * @function encode
         * @memberof amzs_tp.UserProfileData
         * @static
         * @param {amzs_tp.IUserProfileData} message UserProfileData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserProfileData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified UserProfileData message, length delimited. Does not implicitly {@link amzs_tp.UserProfileData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof amzs_tp.UserProfileData
         * @static
         * @param {amzs_tp.IUserProfileData} message UserProfileData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserProfileData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UserProfileData message from the specified reader or buffer.
         * @function decode
         * @memberof amzs_tp.UserProfileData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {amzs_tp.UserProfileData} UserProfileData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserProfileData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.amzs_tp.UserProfileData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UserProfileData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof amzs_tp.UserProfileData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {amzs_tp.UserProfileData} UserProfileData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserProfileData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UserProfileData message.
         * @function verify
         * @memberof amzs_tp.UserProfileData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UserProfileData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        /**
         * Creates a UserProfileData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof amzs_tp.UserProfileData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {amzs_tp.UserProfileData} UserProfileData
         */
        UserProfileData.fromObject = function fromObject(object) {
            if (object instanceof $root.amzs_tp.UserProfileData)
                return object;
            let message = new $root.amzs_tp.UserProfileData();
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from a UserProfileData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof amzs_tp.UserProfileData
         * @static
         * @param {amzs_tp.UserProfileData} message UserProfileData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserProfileData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.name = "";
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this UserProfileData to JSON.
         * @function toJSON
         * @memberof amzs_tp.UserProfileData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserProfileData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UserProfileData;
    })();

    return amzs_tp;
})();

export const org = $root.org = (() => {

    /**
     * Namespace org.
     * @exports org
     * @namespace
     */
    const org = {};

    org.LoadLocations = (function() {

        /**
         * Properties of a LoadLocations.
         * @memberof org
         * @interface ILoadLocations
         */

        /**
         * Constructs a new LoadLocations.
         * @memberof org
         * @classdesc Represents a LoadLocations.
         * @implements ILoadLocations
         * @constructor
         * @param {org.ILoadLocations=} [properties] Properties to set
         */
        function LoadLocations(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new LoadLocations instance using the specified properties.
         * @function create
         * @memberof org.LoadLocations
         * @static
         * @param {org.ILoadLocations=} [properties] Properties to set
         * @returns {org.LoadLocations} LoadLocations instance
         */
        LoadLocations.create = function create(properties) {
            return new LoadLocations(properties);
        };

        /**
         * Encodes the specified LoadLocations message. Does not implicitly {@link org.LoadLocations.verify|verify} messages.
         * @function encode
         * @memberof org.LoadLocations
         * @static
         * @param {org.ILoadLocations} message LoadLocations message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadLocations.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified LoadLocations message, length delimited. Does not implicitly {@link org.LoadLocations.verify|verify} messages.
         * @function encodeDelimited
         * @memberof org.LoadLocations
         * @static
         * @param {org.ILoadLocations} message LoadLocations message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadLocations.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoadLocations message from the specified reader or buffer.
         * @function decode
         * @memberof org.LoadLocations
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {org.LoadLocations} LoadLocations
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadLocations.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.org.LoadLocations();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoadLocations message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof org.LoadLocations
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {org.LoadLocations} LoadLocations
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadLocations.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoadLocations message.
         * @function verify
         * @memberof org.LoadLocations
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoadLocations.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a LoadLocations message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof org.LoadLocations
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {org.LoadLocations} LoadLocations
         */
        LoadLocations.fromObject = function fromObject(object) {
            if (object instanceof $root.org.LoadLocations)
                return object;
            return new $root.org.LoadLocations();
        };

        /**
         * Creates a plain object from a LoadLocations message. Also converts values to other types if specified.
         * @function toObject
         * @memberof org.LoadLocations
         * @static
         * @param {org.LoadLocations} message LoadLocations
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoadLocations.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this LoadLocations to JSON.
         * @function toJSON
         * @memberof org.LoadLocations
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoadLocations.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LoadLocations;
    })();

    org.Locations = (function() {

        /**
         * Properties of a Locations.
         * @memberof org
         * @interface ILocations
         * @property {Array.<tp_types.ITpItem>|null} [items] Locations items
         */

        /**
         * Constructs a new Locations.
         * @memberof org
         * @classdesc Represents a Locations.
         * @implements ILocations
         * @constructor
         * @param {org.ILocations=} [properties] Properties to set
         */
        function Locations(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Locations items.
         * @member {Array.<tp_types.ITpItem>} items
         * @memberof org.Locations
         * @instance
         */
        Locations.prototype.items = $util.emptyArray;

        /**
         * Creates a new Locations instance using the specified properties.
         * @function create
         * @memberof org.Locations
         * @static
         * @param {org.ILocations=} [properties] Properties to set
         * @returns {org.Locations} Locations instance
         */
        Locations.create = function create(properties) {
            return new Locations(properties);
        };

        /**
         * Encodes the specified Locations message. Does not implicitly {@link org.Locations.verify|verify} messages.
         * @function encode
         * @memberof org.Locations
         * @static
         * @param {org.ILocations} message Locations message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Locations.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.tp_types.TpItem.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Locations message, length delimited. Does not implicitly {@link org.Locations.verify|verify} messages.
         * @function encodeDelimited
         * @memberof org.Locations
         * @static
         * @param {org.ILocations} message Locations message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Locations.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Locations message from the specified reader or buffer.
         * @function decode
         * @memberof org.Locations
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {org.Locations} Locations
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Locations.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.org.Locations();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.tp_types.TpItem.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Locations message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof org.Locations
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {org.Locations} Locations
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Locations.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Locations message.
         * @function verify
         * @memberof org.Locations
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Locations.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.items != null && message.hasOwnProperty("items")) {
                if (!Array.isArray(message.items))
                    return "items: array expected";
                for (let i = 0; i < message.items.length; ++i) {
                    let error = $root.tp_types.TpItem.verify(message.items[i]);
                    if (error)
                        return "items." + error;
                }
            }
            return null;
        };

        /**
         * Creates a Locations message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof org.Locations
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {org.Locations} Locations
         */
        Locations.fromObject = function fromObject(object) {
            if (object instanceof $root.org.Locations)
                return object;
            let message = new $root.org.Locations();
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".org.Locations.items: array expected");
                message.items = [];
                for (let i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".org.Locations.items: object expected");
                    message.items[i] = $root.tp_types.TpItem.fromObject(object.items[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a Locations message. Also converts values to other types if specified.
         * @function toObject
         * @memberof org.Locations
         * @static
         * @param {org.Locations} message Locations
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Locations.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (message.items && message.items.length) {
                object.items = [];
                for (let j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.tp_types.TpItem.toObject(message.items[j], options);
            }
            return object;
        };

        /**
         * Converts this Locations to JSON.
         * @function toJSON
         * @memberof org.Locations
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Locations.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Locations;
    })();

    org.LoadSublocations = (function() {

        /**
         * Properties of a LoadSublocations.
         * @memberof org
         * @interface ILoadSublocations
         * @property {string|null} [location] LoadSublocations location
         */

        /**
         * Constructs a new LoadSublocations.
         * @memberof org
         * @classdesc Represents a LoadSublocations.
         * @implements ILoadSublocations
         * @constructor
         * @param {org.ILoadSublocations=} [properties] Properties to set
         */
        function LoadSublocations(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoadSublocations location.
         * @member {string} location
         * @memberof org.LoadSublocations
         * @instance
         */
        LoadSublocations.prototype.location = "";

        /**
         * Creates a new LoadSublocations instance using the specified properties.
         * @function create
         * @memberof org.LoadSublocations
         * @static
         * @param {org.ILoadSublocations=} [properties] Properties to set
         * @returns {org.LoadSublocations} LoadSublocations instance
         */
        LoadSublocations.create = function create(properties) {
            return new LoadSublocations(properties);
        };

        /**
         * Encodes the specified LoadSublocations message. Does not implicitly {@link org.LoadSublocations.verify|verify} messages.
         * @function encode
         * @memberof org.LoadSublocations
         * @static
         * @param {org.ILoadSublocations} message LoadSublocations message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadSublocations.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.location != null && Object.hasOwnProperty.call(message, "location"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.location);
            return writer;
        };

        /**
         * Encodes the specified LoadSublocations message, length delimited. Does not implicitly {@link org.LoadSublocations.verify|verify} messages.
         * @function encodeDelimited
         * @memberof org.LoadSublocations
         * @static
         * @param {org.ILoadSublocations} message LoadSublocations message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadSublocations.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoadSublocations message from the specified reader or buffer.
         * @function decode
         * @memberof org.LoadSublocations
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {org.LoadSublocations} LoadSublocations
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadSublocations.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.org.LoadSublocations();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.location = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoadSublocations message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof org.LoadSublocations
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {org.LoadSublocations} LoadSublocations
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadSublocations.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoadSublocations message.
         * @function verify
         * @memberof org.LoadSublocations
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoadSublocations.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.location != null && message.hasOwnProperty("location"))
                if (!$util.isString(message.location))
                    return "location: string expected";
            return null;
        };

        /**
         * Creates a LoadSublocations message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof org.LoadSublocations
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {org.LoadSublocations} LoadSublocations
         */
        LoadSublocations.fromObject = function fromObject(object) {
            if (object instanceof $root.org.LoadSublocations)
                return object;
            let message = new $root.org.LoadSublocations();
            if (object.location != null)
                message.location = String(object.location);
            return message;
        };

        /**
         * Creates a plain object from a LoadSublocations message. Also converts values to other types if specified.
         * @function toObject
         * @memberof org.LoadSublocations
         * @static
         * @param {org.LoadSublocations} message LoadSublocations
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoadSublocations.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.location = "";
            if (message.location != null && message.hasOwnProperty("location"))
                object.location = message.location;
            return object;
        };

        /**
         * Converts this LoadSublocations to JSON.
         * @function toJSON
         * @memberof org.LoadSublocations
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoadSublocations.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LoadSublocations;
    })();

    org.SubLocations = (function() {

        /**
         * Properties of a SubLocations.
         * @memberof org
         * @interface ISubLocations
         * @property {string|null} [title] SubLocations title
         * @property {Array.<tp_types.ITpItem>|null} [items] SubLocations items
         */

        /**
         * Constructs a new SubLocations.
         * @memberof org
         * @classdesc Represents a SubLocations.
         * @implements ISubLocations
         * @constructor
         * @param {org.ISubLocations=} [properties] Properties to set
         */
        function SubLocations(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SubLocations title.
         * @member {string} title
         * @memberof org.SubLocations
         * @instance
         */
        SubLocations.prototype.title = "";

        /**
         * SubLocations items.
         * @member {Array.<tp_types.ITpItem>} items
         * @memberof org.SubLocations
         * @instance
         */
        SubLocations.prototype.items = $util.emptyArray;

        /**
         * Creates a new SubLocations instance using the specified properties.
         * @function create
         * @memberof org.SubLocations
         * @static
         * @param {org.ISubLocations=} [properties] Properties to set
         * @returns {org.SubLocations} SubLocations instance
         */
        SubLocations.create = function create(properties) {
            return new SubLocations(properties);
        };

        /**
         * Encodes the specified SubLocations message. Does not implicitly {@link org.SubLocations.verify|verify} messages.
         * @function encode
         * @memberof org.SubLocations
         * @static
         * @param {org.ISubLocations} message SubLocations message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SubLocations.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.title);
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.tp_types.TpItem.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SubLocations message, length delimited. Does not implicitly {@link org.SubLocations.verify|verify} messages.
         * @function encodeDelimited
         * @memberof org.SubLocations
         * @static
         * @param {org.ISubLocations} message SubLocations message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SubLocations.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SubLocations message from the specified reader or buffer.
         * @function decode
         * @memberof org.SubLocations
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {org.SubLocations} SubLocations
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SubLocations.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.org.SubLocations();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.tp_types.TpItem.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SubLocations message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof org.SubLocations
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {org.SubLocations} SubLocations
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SubLocations.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SubLocations message.
         * @function verify
         * @memberof org.SubLocations
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SubLocations.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.title != null && message.hasOwnProperty("title"))
                if (!$util.isString(message.title))
                    return "title: string expected";
            if (message.items != null && message.hasOwnProperty("items")) {
                if (!Array.isArray(message.items))
                    return "items: array expected";
                for (let i = 0; i < message.items.length; ++i) {
                    let error = $root.tp_types.TpItem.verify(message.items[i]);
                    if (error)
                        return "items." + error;
                }
            }
            return null;
        };

        /**
         * Creates a SubLocations message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof org.SubLocations
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {org.SubLocations} SubLocations
         */
        SubLocations.fromObject = function fromObject(object) {
            if (object instanceof $root.org.SubLocations)
                return object;
            let message = new $root.org.SubLocations();
            if (object.title != null)
                message.title = String(object.title);
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".org.SubLocations.items: array expected");
                message.items = [];
                for (let i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".org.SubLocations.items: object expected");
                    message.items[i] = $root.tp_types.TpItem.fromObject(object.items[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a SubLocations message. Also converts values to other types if specified.
         * @function toObject
         * @memberof org.SubLocations
         * @static
         * @param {org.SubLocations} message SubLocations
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SubLocations.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (options.defaults)
                object.title = "";
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = message.title;
            if (message.items && message.items.length) {
                object.items = [];
                for (let j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.tp_types.TpItem.toObject(message.items[j], options);
            }
            return object;
        };

        /**
         * Converts this SubLocations to JSON.
         * @function toJSON
         * @memberof org.SubLocations
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SubLocations.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SubLocations;
    })();

    org.LoadPresentations = (function() {

        /**
         * Properties of a LoadPresentations.
         * @memberof org
         * @interface ILoadPresentations
         * @property {string|null} [sublocation] LoadPresentations sublocation
         */

        /**
         * Constructs a new LoadPresentations.
         * @memberof org
         * @classdesc Represents a LoadPresentations.
         * @implements ILoadPresentations
         * @constructor
         * @param {org.ILoadPresentations=} [properties] Properties to set
         */
        function LoadPresentations(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoadPresentations sublocation.
         * @member {string} sublocation
         * @memberof org.LoadPresentations
         * @instance
         */
        LoadPresentations.prototype.sublocation = "";

        /**
         * Creates a new LoadPresentations instance using the specified properties.
         * @function create
         * @memberof org.LoadPresentations
         * @static
         * @param {org.ILoadPresentations=} [properties] Properties to set
         * @returns {org.LoadPresentations} LoadPresentations instance
         */
        LoadPresentations.create = function create(properties) {
            return new LoadPresentations(properties);
        };

        /**
         * Encodes the specified LoadPresentations message. Does not implicitly {@link org.LoadPresentations.verify|verify} messages.
         * @function encode
         * @memberof org.LoadPresentations
         * @static
         * @param {org.ILoadPresentations} message LoadPresentations message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadPresentations.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sublocation != null && Object.hasOwnProperty.call(message, "sublocation"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sublocation);
            return writer;
        };

        /**
         * Encodes the specified LoadPresentations message, length delimited. Does not implicitly {@link org.LoadPresentations.verify|verify} messages.
         * @function encodeDelimited
         * @memberof org.LoadPresentations
         * @static
         * @param {org.ILoadPresentations} message LoadPresentations message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadPresentations.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoadPresentations message from the specified reader or buffer.
         * @function decode
         * @memberof org.LoadPresentations
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {org.LoadPresentations} LoadPresentations
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadPresentations.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.org.LoadPresentations();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.sublocation = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoadPresentations message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof org.LoadPresentations
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {org.LoadPresentations} LoadPresentations
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadPresentations.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoadPresentations message.
         * @function verify
         * @memberof org.LoadPresentations
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoadPresentations.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sublocation != null && message.hasOwnProperty("sublocation"))
                if (!$util.isString(message.sublocation))
                    return "sublocation: string expected";
            return null;
        };

        /**
         * Creates a LoadPresentations message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof org.LoadPresentations
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {org.LoadPresentations} LoadPresentations
         */
        LoadPresentations.fromObject = function fromObject(object) {
            if (object instanceof $root.org.LoadPresentations)
                return object;
            let message = new $root.org.LoadPresentations();
            if (object.sublocation != null)
                message.sublocation = String(object.sublocation);
            return message;
        };

        /**
         * Creates a plain object from a LoadPresentations message. Also converts values to other types if specified.
         * @function toObject
         * @memberof org.LoadPresentations
         * @static
         * @param {org.LoadPresentations} message LoadPresentations
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoadPresentations.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.sublocation = "";
            if (message.sublocation != null && message.hasOwnProperty("sublocation"))
                object.sublocation = message.sublocation;
            return object;
        };

        /**
         * Converts this LoadPresentations to JSON.
         * @function toJSON
         * @memberof org.LoadPresentations
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoadPresentations.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LoadPresentations;
    })();

    org.Presentations = (function() {

        /**
         * Properties of a Presentations.
         * @memberof org
         * @interface IPresentations
         * @property {string|null} [title] Presentations title
         * @property {Array.<tp_types.ITpItem>|null} [items] Presentations items
         * @property {tp_types.ITpItem|null} [location] Presentations location
         * @property {tp_types.ITpItem|null} [sublocation] Presentations sublocation
         */

        /**
         * Constructs a new Presentations.
         * @memberof org
         * @classdesc Represents a Presentations.
         * @implements IPresentations
         * @constructor
         * @param {org.IPresentations=} [properties] Properties to set
         */
        function Presentations(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Presentations title.
         * @member {string} title
         * @memberof org.Presentations
         * @instance
         */
        Presentations.prototype.title = "";

        /**
         * Presentations items.
         * @member {Array.<tp_types.ITpItem>} items
         * @memberof org.Presentations
         * @instance
         */
        Presentations.prototype.items = $util.emptyArray;

        /**
         * Presentations location.
         * @member {tp_types.ITpItem|null|undefined} location
         * @memberof org.Presentations
         * @instance
         */
        Presentations.prototype.location = null;

        /**
         * Presentations sublocation.
         * @member {tp_types.ITpItem|null|undefined} sublocation
         * @memberof org.Presentations
         * @instance
         */
        Presentations.prototype.sublocation = null;

        /**
         * Creates a new Presentations instance using the specified properties.
         * @function create
         * @memberof org.Presentations
         * @static
         * @param {org.IPresentations=} [properties] Properties to set
         * @returns {org.Presentations} Presentations instance
         */
        Presentations.create = function create(properties) {
            return new Presentations(properties);
        };

        /**
         * Encodes the specified Presentations message. Does not implicitly {@link org.Presentations.verify|verify} messages.
         * @function encode
         * @memberof org.Presentations
         * @static
         * @param {org.IPresentations} message Presentations message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Presentations.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.title);
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.tp_types.TpItem.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.location != null && Object.hasOwnProperty.call(message, "location"))
                $root.tp_types.TpItem.encode(message.location, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.sublocation != null && Object.hasOwnProperty.call(message, "sublocation"))
                $root.tp_types.TpItem.encode(message.sublocation, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Presentations message, length delimited. Does not implicitly {@link org.Presentations.verify|verify} messages.
         * @function encodeDelimited
         * @memberof org.Presentations
         * @static
         * @param {org.IPresentations} message Presentations message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Presentations.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Presentations message from the specified reader or buffer.
         * @function decode
         * @memberof org.Presentations
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {org.Presentations} Presentations
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Presentations.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.org.Presentations();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.tp_types.TpItem.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.location = $root.tp_types.TpItem.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.sublocation = $root.tp_types.TpItem.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Presentations message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof org.Presentations
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {org.Presentations} Presentations
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Presentations.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Presentations message.
         * @function verify
         * @memberof org.Presentations
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Presentations.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.title != null && message.hasOwnProperty("title"))
                if (!$util.isString(message.title))
                    return "title: string expected";
            if (message.items != null && message.hasOwnProperty("items")) {
                if (!Array.isArray(message.items))
                    return "items: array expected";
                for (let i = 0; i < message.items.length; ++i) {
                    let error = $root.tp_types.TpItem.verify(message.items[i]);
                    if (error)
                        return "items." + error;
                }
            }
            if (message.location != null && message.hasOwnProperty("location")) {
                let error = $root.tp_types.TpItem.verify(message.location);
                if (error)
                    return "location." + error;
            }
            if (message.sublocation != null && message.hasOwnProperty("sublocation")) {
                let error = $root.tp_types.TpItem.verify(message.sublocation);
                if (error)
                    return "sublocation." + error;
            }
            return null;
        };

        /**
         * Creates a Presentations message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof org.Presentations
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {org.Presentations} Presentations
         */
        Presentations.fromObject = function fromObject(object) {
            if (object instanceof $root.org.Presentations)
                return object;
            let message = new $root.org.Presentations();
            if (object.title != null)
                message.title = String(object.title);
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".org.Presentations.items: array expected");
                message.items = [];
                for (let i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".org.Presentations.items: object expected");
                    message.items[i] = $root.tp_types.TpItem.fromObject(object.items[i]);
                }
            }
            if (object.location != null) {
                if (typeof object.location !== "object")
                    throw TypeError(".org.Presentations.location: object expected");
                message.location = $root.tp_types.TpItem.fromObject(object.location);
            }
            if (object.sublocation != null) {
                if (typeof object.sublocation !== "object")
                    throw TypeError(".org.Presentations.sublocation: object expected");
                message.sublocation = $root.tp_types.TpItem.fromObject(object.sublocation);
            }
            return message;
        };

        /**
         * Creates a plain object from a Presentations message. Also converts values to other types if specified.
         * @function toObject
         * @memberof org.Presentations
         * @static
         * @param {org.Presentations} message Presentations
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Presentations.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (options.defaults) {
                object.title = "";
                object.location = null;
                object.sublocation = null;
            }
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = message.title;
            if (message.items && message.items.length) {
                object.items = [];
                for (let j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.tp_types.TpItem.toObject(message.items[j], options);
            }
            if (message.location != null && message.hasOwnProperty("location"))
                object.location = $root.tp_types.TpItem.toObject(message.location, options);
            if (message.sublocation != null && message.hasOwnProperty("sublocation"))
                object.sublocation = $root.tp_types.TpItem.toObject(message.sublocation, options);
            return object;
        };

        /**
         * Converts this Presentations to JSON.
         * @function toJSON
         * @memberof org.Presentations
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Presentations.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Presentations;
    })();

    return org;
})();

export const tp_types = $root.tp_types = (() => {

    /**
     * Namespace tp_types.
     * @exports tp_types
     * @namespace
     */
    const tp_types = {};

    tp_types.TpItem = (function() {

        /**
         * Properties of a TpItem.
         * @memberof tp_types
         * @interface ITpItem
         * @property {string|null} [label] TpItem label
         * @property {string|null} [text] TpItem text
         * @property {string|null} [number] TpItem number
         */

        /**
         * Constructs a new TpItem.
         * @memberof tp_types
         * @classdesc Represents a TpItem.
         * @implements ITpItem
         * @constructor
         * @param {tp_types.ITpItem=} [properties] Properties to set
         */
        function TpItem(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TpItem label.
         * @member {string} label
         * @memberof tp_types.TpItem
         * @instance
         */
        TpItem.prototype.label = "";

        /**
         * TpItem text.
         * @member {string} text
         * @memberof tp_types.TpItem
         * @instance
         */
        TpItem.prototype.text = "";

        /**
         * TpItem number.
         * @member {string} number
         * @memberof tp_types.TpItem
         * @instance
         */
        TpItem.prototype.number = "";

        /**
         * Creates a new TpItem instance using the specified properties.
         * @function create
         * @memberof tp_types.TpItem
         * @static
         * @param {tp_types.ITpItem=} [properties] Properties to set
         * @returns {tp_types.TpItem} TpItem instance
         */
        TpItem.create = function create(properties) {
            return new TpItem(properties);
        };

        /**
         * Encodes the specified TpItem message. Does not implicitly {@link tp_types.TpItem.verify|verify} messages.
         * @function encode
         * @memberof tp_types.TpItem
         * @static
         * @param {tp_types.ITpItem} message TpItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TpItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.label);
            if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.text);
            if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.number);
            return writer;
        };

        /**
         * Encodes the specified TpItem message, length delimited. Does not implicitly {@link tp_types.TpItem.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tp_types.TpItem
         * @static
         * @param {tp_types.ITpItem} message TpItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TpItem.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TpItem message from the specified reader or buffer.
         * @function decode
         * @memberof tp_types.TpItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tp_types.TpItem} TpItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TpItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.tp_types.TpItem();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.label = reader.string();
                    break;
                case 2:
                    message.text = reader.string();
                    break;
                case 3:
                    message.number = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TpItem message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tp_types.TpItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tp_types.TpItem} TpItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TpItem.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TpItem message.
         * @function verify
         * @memberof tp_types.TpItem
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TpItem.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.label != null && message.hasOwnProperty("label"))
                if (!$util.isString(message.label))
                    return "label: string expected";
            if (message.text != null && message.hasOwnProperty("text"))
                if (!$util.isString(message.text))
                    return "text: string expected";
            if (message.number != null && message.hasOwnProperty("number"))
                if (!$util.isString(message.number))
                    return "number: string expected";
            return null;
        };

        /**
         * Creates a TpItem message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tp_types.TpItem
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tp_types.TpItem} TpItem
         */
        TpItem.fromObject = function fromObject(object) {
            if (object instanceof $root.tp_types.TpItem)
                return object;
            let message = new $root.tp_types.TpItem();
            if (object.label != null)
                message.label = String(object.label);
            if (object.text != null)
                message.text = String(object.text);
            if (object.number != null)
                message.number = String(object.number);
            return message;
        };

        /**
         * Creates a plain object from a TpItem message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tp_types.TpItem
         * @static
         * @param {tp_types.TpItem} message TpItem
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TpItem.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.label = "";
                object.text = "";
                object.number = "";
            }
            if (message.label != null && message.hasOwnProperty("label"))
                object.label = message.label;
            if (message.text != null && message.hasOwnProperty("text"))
                object.text = message.text;
            if (message.number != null && message.hasOwnProperty("number"))
                object.number = message.number;
            return object;
        };

        /**
         * Converts this TpItem to JSON.
         * @function toJSON
         * @memberof tp_types.TpItem
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TpItem.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TpItem;
    })();

    return tp_types;
})();

export const presentation = $root.presentation = (() => {

    /**
     * Namespace presentation.
     * @exports presentation
     * @namespace
     */
    const presentation = {};

    presentation.LoadPresentation = (function() {

        /**
         * Properties of a LoadPresentation.
         * @memberof presentation
         * @interface ILoadPresentation
         * @property {string|null} [presentationKey] LoadPresentation presentationKey
         * @property {string|null} [locationKey] LoadPresentation locationKey
         * @property {string|null} [sublocationKey] LoadPresentation sublocationKey
         * @property {string|null} [sequenceId] LoadPresentation sequenceId
         * @property {string|null} [slideId] LoadPresentation slideId
         */

        /**
         * Constructs a new LoadPresentation.
         * @memberof presentation
         * @classdesc Represents a LoadPresentation.
         * @implements ILoadPresentation
         * @constructor
         * @param {presentation.ILoadPresentation=} [properties] Properties to set
         */
        function LoadPresentation(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoadPresentation presentationKey.
         * @member {string} presentationKey
         * @memberof presentation.LoadPresentation
         * @instance
         */
        LoadPresentation.prototype.presentationKey = "";

        /**
         * LoadPresentation locationKey.
         * @member {string} locationKey
         * @memberof presentation.LoadPresentation
         * @instance
         */
        LoadPresentation.prototype.locationKey = "";

        /**
         * LoadPresentation sublocationKey.
         * @member {string} sublocationKey
         * @memberof presentation.LoadPresentation
         * @instance
         */
        LoadPresentation.prototype.sublocationKey = "";

        /**
         * LoadPresentation sequenceId.
         * @member {string} sequenceId
         * @memberof presentation.LoadPresentation
         * @instance
         */
        LoadPresentation.prototype.sequenceId = "";

        /**
         * LoadPresentation slideId.
         * @member {string} slideId
         * @memberof presentation.LoadPresentation
         * @instance
         */
        LoadPresentation.prototype.slideId = "";

        /**
         * Creates a new LoadPresentation instance using the specified properties.
         * @function create
         * @memberof presentation.LoadPresentation
         * @static
         * @param {presentation.ILoadPresentation=} [properties] Properties to set
         * @returns {presentation.LoadPresentation} LoadPresentation instance
         */
        LoadPresentation.create = function create(properties) {
            return new LoadPresentation(properties);
        };

        /**
         * Encodes the specified LoadPresentation message. Does not implicitly {@link presentation.LoadPresentation.verify|verify} messages.
         * @function encode
         * @memberof presentation.LoadPresentation
         * @static
         * @param {presentation.ILoadPresentation} message LoadPresentation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadPresentation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.presentationKey != null && Object.hasOwnProperty.call(message, "presentationKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.presentationKey);
            if (message.locationKey != null && Object.hasOwnProperty.call(message, "locationKey"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.locationKey);
            if (message.sublocationKey != null && Object.hasOwnProperty.call(message, "sublocationKey"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.sublocationKey);
            if (message.sequenceId != null && Object.hasOwnProperty.call(message, "sequenceId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.sequenceId);
            if (message.slideId != null && Object.hasOwnProperty.call(message, "slideId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.slideId);
            return writer;
        };

        /**
         * Encodes the specified LoadPresentation message, length delimited. Does not implicitly {@link presentation.LoadPresentation.verify|verify} messages.
         * @function encodeDelimited
         * @memberof presentation.LoadPresentation
         * @static
         * @param {presentation.ILoadPresentation} message LoadPresentation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadPresentation.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoadPresentation message from the specified reader or buffer.
         * @function decode
         * @memberof presentation.LoadPresentation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {presentation.LoadPresentation} LoadPresentation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadPresentation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.presentation.LoadPresentation();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.presentationKey = reader.string();
                    break;
                case 2:
                    message.locationKey = reader.string();
                    break;
                case 3:
                    message.sublocationKey = reader.string();
                    break;
                case 4:
                    message.sequenceId = reader.string();
                    break;
                case 5:
                    message.slideId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoadPresentation message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof presentation.LoadPresentation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {presentation.LoadPresentation} LoadPresentation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadPresentation.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoadPresentation message.
         * @function verify
         * @memberof presentation.LoadPresentation
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoadPresentation.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.presentationKey != null && message.hasOwnProperty("presentationKey"))
                if (!$util.isString(message.presentationKey))
                    return "presentationKey: string expected";
            if (message.locationKey != null && message.hasOwnProperty("locationKey"))
                if (!$util.isString(message.locationKey))
                    return "locationKey: string expected";
            if (message.sublocationKey != null && message.hasOwnProperty("sublocationKey"))
                if (!$util.isString(message.sublocationKey))
                    return "sublocationKey: string expected";
            if (message.sequenceId != null && message.hasOwnProperty("sequenceId"))
                if (!$util.isString(message.sequenceId))
                    return "sequenceId: string expected";
            if (message.slideId != null && message.hasOwnProperty("slideId"))
                if (!$util.isString(message.slideId))
                    return "slideId: string expected";
            return null;
        };

        /**
         * Creates a LoadPresentation message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof presentation.LoadPresentation
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {presentation.LoadPresentation} LoadPresentation
         */
        LoadPresentation.fromObject = function fromObject(object) {
            if (object instanceof $root.presentation.LoadPresentation)
                return object;
            let message = new $root.presentation.LoadPresentation();
            if (object.presentationKey != null)
                message.presentationKey = String(object.presentationKey);
            if (object.locationKey != null)
                message.locationKey = String(object.locationKey);
            if (object.sublocationKey != null)
                message.sublocationKey = String(object.sublocationKey);
            if (object.sequenceId != null)
                message.sequenceId = String(object.sequenceId);
            if (object.slideId != null)
                message.slideId = String(object.slideId);
            return message;
        };

        /**
         * Creates a plain object from a LoadPresentation message. Also converts values to other types if specified.
         * @function toObject
         * @memberof presentation.LoadPresentation
         * @static
         * @param {presentation.LoadPresentation} message LoadPresentation
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoadPresentation.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.presentationKey = "";
                object.locationKey = "";
                object.sublocationKey = "";
                object.sequenceId = "";
                object.slideId = "";
            }
            if (message.presentationKey != null && message.hasOwnProperty("presentationKey"))
                object.presentationKey = message.presentationKey;
            if (message.locationKey != null && message.hasOwnProperty("locationKey"))
                object.locationKey = message.locationKey;
            if (message.sublocationKey != null && message.hasOwnProperty("sublocationKey"))
                object.sublocationKey = message.sublocationKey;
            if (message.sequenceId != null && message.hasOwnProperty("sequenceId"))
                object.sequenceId = message.sequenceId;
            if (message.slideId != null && message.hasOwnProperty("slideId"))
                object.slideId = message.slideId;
            return object;
        };

        /**
         * Converts this LoadPresentation to JSON.
         * @function toJSON
         * @memberof presentation.LoadPresentation
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoadPresentation.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LoadPresentation;
    })();

    presentation.Presentation = (function() {

        /**
         * Properties of a Presentation.
         * @memberof presentation
         * @interface IPresentation
         * @property {tp_types.ITpItem|null} [location] Presentation location
         * @property {tp_types.ITpItem|null} [sublocation] Presentation sublocation
         * @property {string|null} [name] Presentation name
         * @property {string|null} [key] Presentation key
         */

        /**
         * Constructs a new Presentation.
         * @memberof presentation
         * @classdesc Represents a Presentation.
         * @implements IPresentation
         * @constructor
         * @param {presentation.IPresentation=} [properties] Properties to set
         */
        function Presentation(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Presentation location.
         * @member {tp_types.ITpItem|null|undefined} location
         * @memberof presentation.Presentation
         * @instance
         */
        Presentation.prototype.location = null;

        /**
         * Presentation sublocation.
         * @member {tp_types.ITpItem|null|undefined} sublocation
         * @memberof presentation.Presentation
         * @instance
         */
        Presentation.prototype.sublocation = null;

        /**
         * Presentation name.
         * @member {string} name
         * @memberof presentation.Presentation
         * @instance
         */
        Presentation.prototype.name = "";

        /**
         * Presentation key.
         * @member {string} key
         * @memberof presentation.Presentation
         * @instance
         */
        Presentation.prototype.key = "";

        /**
         * Creates a new Presentation instance using the specified properties.
         * @function create
         * @memberof presentation.Presentation
         * @static
         * @param {presentation.IPresentation=} [properties] Properties to set
         * @returns {presentation.Presentation} Presentation instance
         */
        Presentation.create = function create(properties) {
            return new Presentation(properties);
        };

        /**
         * Encodes the specified Presentation message. Does not implicitly {@link presentation.Presentation.verify|verify} messages.
         * @function encode
         * @memberof presentation.Presentation
         * @static
         * @param {presentation.IPresentation} message Presentation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Presentation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.location != null && Object.hasOwnProperty.call(message, "location"))
                $root.tp_types.TpItem.encode(message.location, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.sublocation != null && Object.hasOwnProperty.call(message, "sublocation"))
                $root.tp_types.TpItem.encode(message.sublocation, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.key);
            return writer;
        };

        /**
         * Encodes the specified Presentation message, length delimited. Does not implicitly {@link presentation.Presentation.verify|verify} messages.
         * @function encodeDelimited
         * @memberof presentation.Presentation
         * @static
         * @param {presentation.IPresentation} message Presentation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Presentation.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Presentation message from the specified reader or buffer.
         * @function decode
         * @memberof presentation.Presentation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {presentation.Presentation} Presentation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Presentation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.presentation.Presentation();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.location = $root.tp_types.TpItem.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.sublocation = $root.tp_types.TpItem.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                case 4:
                    message.key = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Presentation message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof presentation.Presentation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {presentation.Presentation} Presentation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Presentation.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Presentation message.
         * @function verify
         * @memberof presentation.Presentation
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Presentation.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.location != null && message.hasOwnProperty("location")) {
                let error = $root.tp_types.TpItem.verify(message.location);
                if (error)
                    return "location." + error;
            }
            if (message.sublocation != null && message.hasOwnProperty("sublocation")) {
                let error = $root.tp_types.TpItem.verify(message.sublocation);
                if (error)
                    return "sublocation." + error;
            }
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            return null;
        };

        /**
         * Creates a Presentation message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof presentation.Presentation
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {presentation.Presentation} Presentation
         */
        Presentation.fromObject = function fromObject(object) {
            if (object instanceof $root.presentation.Presentation)
                return object;
            let message = new $root.presentation.Presentation();
            if (object.location != null) {
                if (typeof object.location !== "object")
                    throw TypeError(".presentation.Presentation.location: object expected");
                message.location = $root.tp_types.TpItem.fromObject(object.location);
            }
            if (object.sublocation != null) {
                if (typeof object.sublocation !== "object")
                    throw TypeError(".presentation.Presentation.sublocation: object expected");
                message.sublocation = $root.tp_types.TpItem.fromObject(object.sublocation);
            }
            if (object.name != null)
                message.name = String(object.name);
            if (object.key != null)
                message.key = String(object.key);
            return message;
        };

        /**
         * Creates a plain object from a Presentation message. Also converts values to other types if specified.
         * @function toObject
         * @memberof presentation.Presentation
         * @static
         * @param {presentation.Presentation} message Presentation
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Presentation.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.location = null;
                object.sublocation = null;
                object.name = "";
                object.key = "";
            }
            if (message.location != null && message.hasOwnProperty("location"))
                object.location = $root.tp_types.TpItem.toObject(message.location, options);
            if (message.sublocation != null && message.hasOwnProperty("sublocation"))
                object.sublocation = $root.tp_types.TpItem.toObject(message.sublocation, options);
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            return object;
        };

        /**
         * Converts this Presentation to JSON.
         * @function toJSON
         * @memberof presentation.Presentation
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Presentation.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Presentation;
    })();

    presentation.LoadPresentationSlice = (function() {

        /**
         * Properties of a LoadPresentationSlice.
         * @memberof presentation
         * @interface ILoadPresentationSlice
         * @property {string|null} [presentationKey] LoadPresentationSlice presentationKey
         * @property {string|null} [sequenceId] LoadPresentationSlice sequenceId
         * @property {string|null} [slideId] LoadPresentationSlice slideId
         */

        /**
         * Constructs a new LoadPresentationSlice.
         * @memberof presentation
         * @classdesc Represents a LoadPresentationSlice.
         * @implements ILoadPresentationSlice
         * @constructor
         * @param {presentation.ILoadPresentationSlice=} [properties] Properties to set
         */
        function LoadPresentationSlice(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoadPresentationSlice presentationKey.
         * @member {string} presentationKey
         * @memberof presentation.LoadPresentationSlice
         * @instance
         */
        LoadPresentationSlice.prototype.presentationKey = "";

        /**
         * LoadPresentationSlice sequenceId.
         * @member {string} sequenceId
         * @memberof presentation.LoadPresentationSlice
         * @instance
         */
        LoadPresentationSlice.prototype.sequenceId = "";

        /**
         * LoadPresentationSlice slideId.
         * @member {string} slideId
         * @memberof presentation.LoadPresentationSlice
         * @instance
         */
        LoadPresentationSlice.prototype.slideId = "";

        /**
         * Creates a new LoadPresentationSlice instance using the specified properties.
         * @function create
         * @memberof presentation.LoadPresentationSlice
         * @static
         * @param {presentation.ILoadPresentationSlice=} [properties] Properties to set
         * @returns {presentation.LoadPresentationSlice} LoadPresentationSlice instance
         */
        LoadPresentationSlice.create = function create(properties) {
            return new LoadPresentationSlice(properties);
        };

        /**
         * Encodes the specified LoadPresentationSlice message. Does not implicitly {@link presentation.LoadPresentationSlice.verify|verify} messages.
         * @function encode
         * @memberof presentation.LoadPresentationSlice
         * @static
         * @param {presentation.ILoadPresentationSlice} message LoadPresentationSlice message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadPresentationSlice.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.presentationKey != null && Object.hasOwnProperty.call(message, "presentationKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.presentationKey);
            if (message.sequenceId != null && Object.hasOwnProperty.call(message, "sequenceId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.sequenceId);
            if (message.slideId != null && Object.hasOwnProperty.call(message, "slideId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.slideId);
            return writer;
        };

        /**
         * Encodes the specified LoadPresentationSlice message, length delimited. Does not implicitly {@link presentation.LoadPresentationSlice.verify|verify} messages.
         * @function encodeDelimited
         * @memberof presentation.LoadPresentationSlice
         * @static
         * @param {presentation.ILoadPresentationSlice} message LoadPresentationSlice message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadPresentationSlice.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoadPresentationSlice message from the specified reader or buffer.
         * @function decode
         * @memberof presentation.LoadPresentationSlice
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {presentation.LoadPresentationSlice} LoadPresentationSlice
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadPresentationSlice.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.presentation.LoadPresentationSlice();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.presentationKey = reader.string();
                    break;
                case 2:
                    message.sequenceId = reader.string();
                    break;
                case 3:
                    message.slideId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoadPresentationSlice message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof presentation.LoadPresentationSlice
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {presentation.LoadPresentationSlice} LoadPresentationSlice
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadPresentationSlice.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoadPresentationSlice message.
         * @function verify
         * @memberof presentation.LoadPresentationSlice
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoadPresentationSlice.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.presentationKey != null && message.hasOwnProperty("presentationKey"))
                if (!$util.isString(message.presentationKey))
                    return "presentationKey: string expected";
            if (message.sequenceId != null && message.hasOwnProperty("sequenceId"))
                if (!$util.isString(message.sequenceId))
                    return "sequenceId: string expected";
            if (message.slideId != null && message.hasOwnProperty("slideId"))
                if (!$util.isString(message.slideId))
                    return "slideId: string expected";
            return null;
        };

        /**
         * Creates a LoadPresentationSlice message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof presentation.LoadPresentationSlice
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {presentation.LoadPresentationSlice} LoadPresentationSlice
         */
        LoadPresentationSlice.fromObject = function fromObject(object) {
            if (object instanceof $root.presentation.LoadPresentationSlice)
                return object;
            let message = new $root.presentation.LoadPresentationSlice();
            if (object.presentationKey != null)
                message.presentationKey = String(object.presentationKey);
            if (object.sequenceId != null)
                message.sequenceId = String(object.sequenceId);
            if (object.slideId != null)
                message.slideId = String(object.slideId);
            return message;
        };

        /**
         * Creates a plain object from a LoadPresentationSlice message. Also converts values to other types if specified.
         * @function toObject
         * @memberof presentation.LoadPresentationSlice
         * @static
         * @param {presentation.LoadPresentationSlice} message LoadPresentationSlice
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoadPresentationSlice.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.presentationKey = "";
                object.sequenceId = "";
                object.slideId = "";
            }
            if (message.presentationKey != null && message.hasOwnProperty("presentationKey"))
                object.presentationKey = message.presentationKey;
            if (message.sequenceId != null && message.hasOwnProperty("sequenceId"))
                object.sequenceId = message.sequenceId;
            if (message.slideId != null && message.hasOwnProperty("slideId"))
                object.slideId = message.slideId;
            return object;
        };

        /**
         * Converts this LoadPresentationSlice to JSON.
         * @function toJSON
         * @memberof presentation.LoadPresentationSlice
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoadPresentationSlice.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LoadPresentationSlice;
    })();

    presentation.PresentationSlice = (function() {

        /**
         * Properties of a PresentationSlice.
         * @memberof presentation
         * @interface IPresentationSlice
         * @property {string|null} [nextSlideId] PresentationSlice nextSlideId
         * @property {string|null} [prevSlideId] PresentationSlice prevSlideId
         * @property {string|null} [slideId] PresentationSlice slideId
         * @property {presentation.IPresentationContent|null} [content] PresentationSlice content
         * @property {presentation.IPresentationContent|null} [overlay] PresentationSlice overlay
         * @property {number|null} [durationS] PresentationSlice durationS
         * @property {boolean|null} [autoplay] PresentationSlice autoplay
         * @property {boolean|null} [defaultControls] PresentationSlice defaultControls
         * @property {string|null} [sequenceId] PresentationSlice sequenceId
         * @property {string|null} [nextSequenceId] PresentationSlice nextSequenceId
         * @property {string|null} [prevSequenceId] PresentationSlice prevSequenceId
         */

        /**
         * Constructs a new PresentationSlice.
         * @memberof presentation
         * @classdesc Represents a PresentationSlice.
         * @implements IPresentationSlice
         * @constructor
         * @param {presentation.IPresentationSlice=} [properties] Properties to set
         */
        function PresentationSlice(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PresentationSlice nextSlideId.
         * @member {string} nextSlideId
         * @memberof presentation.PresentationSlice
         * @instance
         */
        PresentationSlice.prototype.nextSlideId = "";

        /**
         * PresentationSlice prevSlideId.
         * @member {string} prevSlideId
         * @memberof presentation.PresentationSlice
         * @instance
         */
        PresentationSlice.prototype.prevSlideId = "";

        /**
         * PresentationSlice slideId.
         * @member {string} slideId
         * @memberof presentation.PresentationSlice
         * @instance
         */
        PresentationSlice.prototype.slideId = "";

        /**
         * PresentationSlice content.
         * @member {presentation.IPresentationContent|null|undefined} content
         * @memberof presentation.PresentationSlice
         * @instance
         */
        PresentationSlice.prototype.content = null;

        /**
         * PresentationSlice overlay.
         * @member {presentation.IPresentationContent|null|undefined} overlay
         * @memberof presentation.PresentationSlice
         * @instance
         */
        PresentationSlice.prototype.overlay = null;

        /**
         * PresentationSlice durationS.
         * @member {number} durationS
         * @memberof presentation.PresentationSlice
         * @instance
         */
        PresentationSlice.prototype.durationS = 0;

        /**
         * PresentationSlice autoplay.
         * @member {boolean} autoplay
         * @memberof presentation.PresentationSlice
         * @instance
         */
        PresentationSlice.prototype.autoplay = false;

        /**
         * PresentationSlice defaultControls.
         * @member {boolean} defaultControls
         * @memberof presentation.PresentationSlice
         * @instance
         */
        PresentationSlice.prototype.defaultControls = false;

        /**
         * PresentationSlice sequenceId.
         * @member {string} sequenceId
         * @memberof presentation.PresentationSlice
         * @instance
         */
        PresentationSlice.prototype.sequenceId = "";

        /**
         * PresentationSlice nextSequenceId.
         * @member {string} nextSequenceId
         * @memberof presentation.PresentationSlice
         * @instance
         */
        PresentationSlice.prototype.nextSequenceId = "";

        /**
         * PresentationSlice prevSequenceId.
         * @member {string} prevSequenceId
         * @memberof presentation.PresentationSlice
         * @instance
         */
        PresentationSlice.prototype.prevSequenceId = "";

        /**
         * Creates a new PresentationSlice instance using the specified properties.
         * @function create
         * @memberof presentation.PresentationSlice
         * @static
         * @param {presentation.IPresentationSlice=} [properties] Properties to set
         * @returns {presentation.PresentationSlice} PresentationSlice instance
         */
        PresentationSlice.create = function create(properties) {
            return new PresentationSlice(properties);
        };

        /**
         * Encodes the specified PresentationSlice message. Does not implicitly {@link presentation.PresentationSlice.verify|verify} messages.
         * @function encode
         * @memberof presentation.PresentationSlice
         * @static
         * @param {presentation.IPresentationSlice} message PresentationSlice message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PresentationSlice.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.nextSlideId != null && Object.hasOwnProperty.call(message, "nextSlideId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.nextSlideId);
            if (message.prevSlideId != null && Object.hasOwnProperty.call(message, "prevSlideId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.prevSlideId);
            if (message.content != null && Object.hasOwnProperty.call(message, "content"))
                $root.presentation.PresentationContent.encode(message.content, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.overlay != null && Object.hasOwnProperty.call(message, "overlay"))
                $root.presentation.PresentationContent.encode(message.overlay, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.durationS != null && Object.hasOwnProperty.call(message, "durationS"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.durationS);
            if (message.autoplay != null && Object.hasOwnProperty.call(message, "autoplay"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.autoplay);
            if (message.defaultControls != null && Object.hasOwnProperty.call(message, "defaultControls"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.defaultControls);
            if (message.sequenceId != null && Object.hasOwnProperty.call(message, "sequenceId"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.sequenceId);
            if (message.nextSequenceId != null && Object.hasOwnProperty.call(message, "nextSequenceId"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.nextSequenceId);
            if (message.prevSequenceId != null && Object.hasOwnProperty.call(message, "prevSequenceId"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.prevSequenceId);
            if (message.slideId != null && Object.hasOwnProperty.call(message, "slideId"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.slideId);
            return writer;
        };

        /**
         * Encodes the specified PresentationSlice message, length delimited. Does not implicitly {@link presentation.PresentationSlice.verify|verify} messages.
         * @function encodeDelimited
         * @memberof presentation.PresentationSlice
         * @static
         * @param {presentation.IPresentationSlice} message PresentationSlice message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PresentationSlice.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PresentationSlice message from the specified reader or buffer.
         * @function decode
         * @memberof presentation.PresentationSlice
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {presentation.PresentationSlice} PresentationSlice
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PresentationSlice.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.presentation.PresentationSlice();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.nextSlideId = reader.string();
                    break;
                case 2:
                    message.prevSlideId = reader.string();
                    break;
                case 11:
                    message.slideId = reader.string();
                    break;
                case 3:
                    message.content = $root.presentation.PresentationContent.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.overlay = $root.presentation.PresentationContent.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.durationS = reader.uint32();
                    break;
                case 6:
                    message.autoplay = reader.bool();
                    break;
                case 7:
                    message.defaultControls = reader.bool();
                    break;
                case 8:
                    message.sequenceId = reader.string();
                    break;
                case 9:
                    message.nextSequenceId = reader.string();
                    break;
                case 10:
                    message.prevSequenceId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PresentationSlice message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof presentation.PresentationSlice
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {presentation.PresentationSlice} PresentationSlice
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PresentationSlice.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PresentationSlice message.
         * @function verify
         * @memberof presentation.PresentationSlice
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PresentationSlice.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.nextSlideId != null && message.hasOwnProperty("nextSlideId"))
                if (!$util.isString(message.nextSlideId))
                    return "nextSlideId: string expected";
            if (message.prevSlideId != null && message.hasOwnProperty("prevSlideId"))
                if (!$util.isString(message.prevSlideId))
                    return "prevSlideId: string expected";
            if (message.slideId != null && message.hasOwnProperty("slideId"))
                if (!$util.isString(message.slideId))
                    return "slideId: string expected";
            if (message.content != null && message.hasOwnProperty("content")) {
                let error = $root.presentation.PresentationContent.verify(message.content);
                if (error)
                    return "content." + error;
            }
            if (message.overlay != null && message.hasOwnProperty("overlay")) {
                let error = $root.presentation.PresentationContent.verify(message.overlay);
                if (error)
                    return "overlay." + error;
            }
            if (message.durationS != null && message.hasOwnProperty("durationS"))
                if (!$util.isInteger(message.durationS))
                    return "durationS: integer expected";
            if (message.autoplay != null && message.hasOwnProperty("autoplay"))
                if (typeof message.autoplay !== "boolean")
                    return "autoplay: boolean expected";
            if (message.defaultControls != null && message.hasOwnProperty("defaultControls"))
                if (typeof message.defaultControls !== "boolean")
                    return "defaultControls: boolean expected";
            if (message.sequenceId != null && message.hasOwnProperty("sequenceId"))
                if (!$util.isString(message.sequenceId))
                    return "sequenceId: string expected";
            if (message.nextSequenceId != null && message.hasOwnProperty("nextSequenceId"))
                if (!$util.isString(message.nextSequenceId))
                    return "nextSequenceId: string expected";
            if (message.prevSequenceId != null && message.hasOwnProperty("prevSequenceId"))
                if (!$util.isString(message.prevSequenceId))
                    return "prevSequenceId: string expected";
            return null;
        };

        /**
         * Creates a PresentationSlice message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof presentation.PresentationSlice
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {presentation.PresentationSlice} PresentationSlice
         */
        PresentationSlice.fromObject = function fromObject(object) {
            if (object instanceof $root.presentation.PresentationSlice)
                return object;
            let message = new $root.presentation.PresentationSlice();
            if (object.nextSlideId != null)
                message.nextSlideId = String(object.nextSlideId);
            if (object.prevSlideId != null)
                message.prevSlideId = String(object.prevSlideId);
            if (object.slideId != null)
                message.slideId = String(object.slideId);
            if (object.content != null) {
                if (typeof object.content !== "object")
                    throw TypeError(".presentation.PresentationSlice.content: object expected");
                message.content = $root.presentation.PresentationContent.fromObject(object.content);
            }
            if (object.overlay != null) {
                if (typeof object.overlay !== "object")
                    throw TypeError(".presentation.PresentationSlice.overlay: object expected");
                message.overlay = $root.presentation.PresentationContent.fromObject(object.overlay);
            }
            if (object.durationS != null)
                message.durationS = object.durationS >>> 0;
            if (object.autoplay != null)
                message.autoplay = Boolean(object.autoplay);
            if (object.defaultControls != null)
                message.defaultControls = Boolean(object.defaultControls);
            if (object.sequenceId != null)
                message.sequenceId = String(object.sequenceId);
            if (object.nextSequenceId != null)
                message.nextSequenceId = String(object.nextSequenceId);
            if (object.prevSequenceId != null)
                message.prevSequenceId = String(object.prevSequenceId);
            return message;
        };

        /**
         * Creates a plain object from a PresentationSlice message. Also converts values to other types if specified.
         * @function toObject
         * @memberof presentation.PresentationSlice
         * @static
         * @param {presentation.PresentationSlice} message PresentationSlice
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PresentationSlice.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.nextSlideId = "";
                object.prevSlideId = "";
                object.content = null;
                object.overlay = null;
                object.durationS = 0;
                object.autoplay = false;
                object.defaultControls = false;
                object.sequenceId = "";
                object.nextSequenceId = "";
                object.prevSequenceId = "";
                object.slideId = "";
            }
            if (message.nextSlideId != null && message.hasOwnProperty("nextSlideId"))
                object.nextSlideId = message.nextSlideId;
            if (message.prevSlideId != null && message.hasOwnProperty("prevSlideId"))
                object.prevSlideId = message.prevSlideId;
            if (message.content != null && message.hasOwnProperty("content"))
                object.content = $root.presentation.PresentationContent.toObject(message.content, options);
            if (message.overlay != null && message.hasOwnProperty("overlay"))
                object.overlay = $root.presentation.PresentationContent.toObject(message.overlay, options);
            if (message.durationS != null && message.hasOwnProperty("durationS"))
                object.durationS = message.durationS;
            if (message.autoplay != null && message.hasOwnProperty("autoplay"))
                object.autoplay = message.autoplay;
            if (message.defaultControls != null && message.hasOwnProperty("defaultControls"))
                object.defaultControls = message.defaultControls;
            if (message.sequenceId != null && message.hasOwnProperty("sequenceId"))
                object.sequenceId = message.sequenceId;
            if (message.nextSequenceId != null && message.hasOwnProperty("nextSequenceId"))
                object.nextSequenceId = message.nextSequenceId;
            if (message.prevSequenceId != null && message.hasOwnProperty("prevSequenceId"))
                object.prevSequenceId = message.prevSequenceId;
            if (message.slideId != null && message.hasOwnProperty("slideId"))
                object.slideId = message.slideId;
            return object;
        };

        /**
         * Converts this PresentationSlice to JSON.
         * @function toJSON
         * @memberof presentation.PresentationSlice
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PresentationSlice.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PresentationSlice;
    })();

    presentation.PresentationContent = (function() {

        /**
         * Properties of a PresentationContent.
         * @memberof presentation
         * @interface IPresentationContent
         * @property {presentation.IContentImage|null} [image] PresentationContent image
         * @property {presentation.IContentVideo|null} [video] PresentationContent video
         * @property {string|null} [jsx] PresentationContent jsx
         */

        /**
         * Constructs a new PresentationContent.
         * @memberof presentation
         * @classdesc Represents a PresentationContent.
         * @implements IPresentationContent
         * @constructor
         * @param {presentation.IPresentationContent=} [properties] Properties to set
         */
        function PresentationContent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PresentationContent image.
         * @member {presentation.IContentImage|null|undefined} image
         * @memberof presentation.PresentationContent
         * @instance
         */
        PresentationContent.prototype.image = null;

        /**
         * PresentationContent video.
         * @member {presentation.IContentVideo|null|undefined} video
         * @memberof presentation.PresentationContent
         * @instance
         */
        PresentationContent.prototype.video = null;

        /**
         * PresentationContent jsx.
         * @member {string} jsx
         * @memberof presentation.PresentationContent
         * @instance
         */
        PresentationContent.prototype.jsx = "";

        /**
         * Creates a new PresentationContent instance using the specified properties.
         * @function create
         * @memberof presentation.PresentationContent
         * @static
         * @param {presentation.IPresentationContent=} [properties] Properties to set
         * @returns {presentation.PresentationContent} PresentationContent instance
         */
        PresentationContent.create = function create(properties) {
            return new PresentationContent(properties);
        };

        /**
         * Encodes the specified PresentationContent message. Does not implicitly {@link presentation.PresentationContent.verify|verify} messages.
         * @function encode
         * @memberof presentation.PresentationContent
         * @static
         * @param {presentation.IPresentationContent} message PresentationContent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PresentationContent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.image != null && Object.hasOwnProperty.call(message, "image"))
                $root.presentation.ContentImage.encode(message.image, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.video != null && Object.hasOwnProperty.call(message, "video"))
                $root.presentation.ContentVideo.encode(message.video, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.jsx != null && Object.hasOwnProperty.call(message, "jsx"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.jsx);
            return writer;
        };

        /**
         * Encodes the specified PresentationContent message, length delimited. Does not implicitly {@link presentation.PresentationContent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof presentation.PresentationContent
         * @static
         * @param {presentation.IPresentationContent} message PresentationContent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PresentationContent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PresentationContent message from the specified reader or buffer.
         * @function decode
         * @memberof presentation.PresentationContent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {presentation.PresentationContent} PresentationContent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PresentationContent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.presentation.PresentationContent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 3:
                    message.image = $root.presentation.ContentImage.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.video = $root.presentation.ContentVideo.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.jsx = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PresentationContent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof presentation.PresentationContent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {presentation.PresentationContent} PresentationContent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PresentationContent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PresentationContent message.
         * @function verify
         * @memberof presentation.PresentationContent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PresentationContent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.image != null && message.hasOwnProperty("image")) {
                let error = $root.presentation.ContentImage.verify(message.image);
                if (error)
                    return "image." + error;
            }
            if (message.video != null && message.hasOwnProperty("video")) {
                let error = $root.presentation.ContentVideo.verify(message.video);
                if (error)
                    return "video." + error;
            }
            if (message.jsx != null && message.hasOwnProperty("jsx"))
                if (!$util.isString(message.jsx))
                    return "jsx: string expected";
            return null;
        };

        /**
         * Creates a PresentationContent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof presentation.PresentationContent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {presentation.PresentationContent} PresentationContent
         */
        PresentationContent.fromObject = function fromObject(object) {
            if (object instanceof $root.presentation.PresentationContent)
                return object;
            let message = new $root.presentation.PresentationContent();
            if (object.image != null) {
                if (typeof object.image !== "object")
                    throw TypeError(".presentation.PresentationContent.image: object expected");
                message.image = $root.presentation.ContentImage.fromObject(object.image);
            }
            if (object.video != null) {
                if (typeof object.video !== "object")
                    throw TypeError(".presentation.PresentationContent.video: object expected");
                message.video = $root.presentation.ContentVideo.fromObject(object.video);
            }
            if (object.jsx != null)
                message.jsx = String(object.jsx);
            return message;
        };

        /**
         * Creates a plain object from a PresentationContent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof presentation.PresentationContent
         * @static
         * @param {presentation.PresentationContent} message PresentationContent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PresentationContent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.image = null;
                object.video = null;
                object.jsx = "";
            }
            if (message.image != null && message.hasOwnProperty("image"))
                object.image = $root.presentation.ContentImage.toObject(message.image, options);
            if (message.video != null && message.hasOwnProperty("video"))
                object.video = $root.presentation.ContentVideo.toObject(message.video, options);
            if (message.jsx != null && message.hasOwnProperty("jsx"))
                object.jsx = message.jsx;
            return object;
        };

        /**
         * Converts this PresentationContent to JSON.
         * @function toJSON
         * @memberof presentation.PresentationContent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PresentationContent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PresentationContent;
    })();

    presentation.ContentVideo = (function() {

        /**
         * Properties of a ContentVideo.
         * @memberof presentation
         * @interface IContentVideo
         * @property {string|null} [src] ContentVideo src
         */

        /**
         * Constructs a new ContentVideo.
         * @memberof presentation
         * @classdesc Represents a ContentVideo.
         * @implements IContentVideo
         * @constructor
         * @param {presentation.IContentVideo=} [properties] Properties to set
         */
        function ContentVideo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContentVideo src.
         * @member {string} src
         * @memberof presentation.ContentVideo
         * @instance
         */
        ContentVideo.prototype.src = "";

        /**
         * Creates a new ContentVideo instance using the specified properties.
         * @function create
         * @memberof presentation.ContentVideo
         * @static
         * @param {presentation.IContentVideo=} [properties] Properties to set
         * @returns {presentation.ContentVideo} ContentVideo instance
         */
        ContentVideo.create = function create(properties) {
            return new ContentVideo(properties);
        };

        /**
         * Encodes the specified ContentVideo message. Does not implicitly {@link presentation.ContentVideo.verify|verify} messages.
         * @function encode
         * @memberof presentation.ContentVideo
         * @static
         * @param {presentation.IContentVideo} message ContentVideo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentVideo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.src != null && Object.hasOwnProperty.call(message, "src"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.src);
            return writer;
        };

        /**
         * Encodes the specified ContentVideo message, length delimited. Does not implicitly {@link presentation.ContentVideo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof presentation.ContentVideo
         * @static
         * @param {presentation.IContentVideo} message ContentVideo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentVideo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ContentVideo message from the specified reader or buffer.
         * @function decode
         * @memberof presentation.ContentVideo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {presentation.ContentVideo} ContentVideo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentVideo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.presentation.ContentVideo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.src = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ContentVideo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof presentation.ContentVideo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {presentation.ContentVideo} ContentVideo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentVideo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ContentVideo message.
         * @function verify
         * @memberof presentation.ContentVideo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ContentVideo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.src != null && message.hasOwnProperty("src"))
                if (!$util.isString(message.src))
                    return "src: string expected";
            return null;
        };

        /**
         * Creates a ContentVideo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof presentation.ContentVideo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {presentation.ContentVideo} ContentVideo
         */
        ContentVideo.fromObject = function fromObject(object) {
            if (object instanceof $root.presentation.ContentVideo)
                return object;
            let message = new $root.presentation.ContentVideo();
            if (object.src != null)
                message.src = String(object.src);
            return message;
        };

        /**
         * Creates a plain object from a ContentVideo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof presentation.ContentVideo
         * @static
         * @param {presentation.ContentVideo} message ContentVideo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentVideo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.src = "";
            if (message.src != null && message.hasOwnProperty("src"))
                object.src = message.src;
            return object;
        };

        /**
         * Converts this ContentVideo to JSON.
         * @function toJSON
         * @memberof presentation.ContentVideo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentVideo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentVideo;
    })();

    presentation.ContentImage = (function() {

        /**
         * Properties of a ContentImage.
         * @memberof presentation
         * @interface IContentImage
         * @property {string|null} [src] ContentImage src
         * @property {Object.<string,string>|null} [style] ContentImage style
         */

        /**
         * Constructs a new ContentImage.
         * @memberof presentation
         * @classdesc Represents a ContentImage.
         * @implements IContentImage
         * @constructor
         * @param {presentation.IContentImage=} [properties] Properties to set
         */
        function ContentImage(properties) {
            this.style = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContentImage src.
         * @member {string} src
         * @memberof presentation.ContentImage
         * @instance
         */
        ContentImage.prototype.src = "";

        /**
         * ContentImage style.
         * @member {Object.<string,string>} style
         * @memberof presentation.ContentImage
         * @instance
         */
        ContentImage.prototype.style = $util.emptyObject;

        /**
         * Creates a new ContentImage instance using the specified properties.
         * @function create
         * @memberof presentation.ContentImage
         * @static
         * @param {presentation.IContentImage=} [properties] Properties to set
         * @returns {presentation.ContentImage} ContentImage instance
         */
        ContentImage.create = function create(properties) {
            return new ContentImage(properties);
        };

        /**
         * Encodes the specified ContentImage message. Does not implicitly {@link presentation.ContentImage.verify|verify} messages.
         * @function encode
         * @memberof presentation.ContentImage
         * @static
         * @param {presentation.IContentImage} message ContentImage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentImage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.src != null && Object.hasOwnProperty.call(message, "src"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.src);
            if (message.style != null && Object.hasOwnProperty.call(message, "style"))
                for (let keys = Object.keys(message.style), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.style[keys[i]]).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ContentImage message, length delimited. Does not implicitly {@link presentation.ContentImage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof presentation.ContentImage
         * @static
         * @param {presentation.IContentImage} message ContentImage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentImage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ContentImage message from the specified reader or buffer.
         * @function decode
         * @memberof presentation.ContentImage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {presentation.ContentImage} ContentImage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentImage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.presentation.ContentImage(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.src = reader.string();
                    break;
                case 2:
                    if (message.style === $util.emptyObject)
                        message.style = {};
                    let end2 = reader.uint32() + reader.pos;
                    key = "";
                    value = "";
                    while (reader.pos < end2) {
                        let tag2 = reader.uint32();
                        switch (tag2 >>> 3) {
                        case 1:
                            key = reader.string();
                            break;
                        case 2:
                            value = reader.string();
                            break;
                        default:
                            reader.skipType(tag2 & 7);
                            break;
                        }
                    }
                    message.style[key] = value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ContentImage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof presentation.ContentImage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {presentation.ContentImage} ContentImage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentImage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ContentImage message.
         * @function verify
         * @memberof presentation.ContentImage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ContentImage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.src != null && message.hasOwnProperty("src"))
                if (!$util.isString(message.src))
                    return "src: string expected";
            if (message.style != null && message.hasOwnProperty("style")) {
                if (!$util.isObject(message.style))
                    return "style: object expected";
                let key = Object.keys(message.style);
                for (let i = 0; i < key.length; ++i)
                    if (!$util.isString(message.style[key[i]]))
                        return "style: string{k:string} expected";
            }
            return null;
        };

        /**
         * Creates a ContentImage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof presentation.ContentImage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {presentation.ContentImage} ContentImage
         */
        ContentImage.fromObject = function fromObject(object) {
            if (object instanceof $root.presentation.ContentImage)
                return object;
            let message = new $root.presentation.ContentImage();
            if (object.src != null)
                message.src = String(object.src);
            if (object.style) {
                if (typeof object.style !== "object")
                    throw TypeError(".presentation.ContentImage.style: object expected");
                message.style = {};
                for (let keys = Object.keys(object.style), i = 0; i < keys.length; ++i)
                    message.style[keys[i]] = String(object.style[keys[i]]);
            }
            return message;
        };

        /**
         * Creates a plain object from a ContentImage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof presentation.ContentImage
         * @static
         * @param {presentation.ContentImage} message ContentImage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentImage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.style = {};
            if (options.defaults)
                object.src = "";
            if (message.src != null && message.hasOwnProperty("src"))
                object.src = message.src;
            let keys2;
            if (message.style && (keys2 = Object.keys(message.style)).length) {
                object.style = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.style[keys2[j]] = message.style[keys2[j]];
            }
            return object;
        };

        /**
         * Converts this ContentImage to JSON.
         * @function toJSON
         * @memberof presentation.ContentImage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentImage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentImage;
    })();

    presentation.ContentJsx = (function() {

        /**
         * Properties of a ContentJsx.
         * @memberof presentation
         * @interface IContentJsx
         * @property {string|null} [src] ContentJsx src
         */

        /**
         * Constructs a new ContentJsx.
         * @memberof presentation
         * @classdesc Represents a ContentJsx.
         * @implements IContentJsx
         * @constructor
         * @param {presentation.IContentJsx=} [properties] Properties to set
         */
        function ContentJsx(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContentJsx src.
         * @member {string} src
         * @memberof presentation.ContentJsx
         * @instance
         */
        ContentJsx.prototype.src = "";

        /**
         * Creates a new ContentJsx instance using the specified properties.
         * @function create
         * @memberof presentation.ContentJsx
         * @static
         * @param {presentation.IContentJsx=} [properties] Properties to set
         * @returns {presentation.ContentJsx} ContentJsx instance
         */
        ContentJsx.create = function create(properties) {
            return new ContentJsx(properties);
        };

        /**
         * Encodes the specified ContentJsx message. Does not implicitly {@link presentation.ContentJsx.verify|verify} messages.
         * @function encode
         * @memberof presentation.ContentJsx
         * @static
         * @param {presentation.IContentJsx} message ContentJsx message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentJsx.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.src != null && Object.hasOwnProperty.call(message, "src"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.src);
            return writer;
        };

        /**
         * Encodes the specified ContentJsx message, length delimited. Does not implicitly {@link presentation.ContentJsx.verify|verify} messages.
         * @function encodeDelimited
         * @memberof presentation.ContentJsx
         * @static
         * @param {presentation.IContentJsx} message ContentJsx message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentJsx.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ContentJsx message from the specified reader or buffer.
         * @function decode
         * @memberof presentation.ContentJsx
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {presentation.ContentJsx} ContentJsx
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentJsx.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.presentation.ContentJsx();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.src = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ContentJsx message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof presentation.ContentJsx
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {presentation.ContentJsx} ContentJsx
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentJsx.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ContentJsx message.
         * @function verify
         * @memberof presentation.ContentJsx
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ContentJsx.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.src != null && message.hasOwnProperty("src"))
                if (!$util.isString(message.src))
                    return "src: string expected";
            return null;
        };

        /**
         * Creates a ContentJsx message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof presentation.ContentJsx
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {presentation.ContentJsx} ContentJsx
         */
        ContentJsx.fromObject = function fromObject(object) {
            if (object instanceof $root.presentation.ContentJsx)
                return object;
            let message = new $root.presentation.ContentJsx();
            if (object.src != null)
                message.src = String(object.src);
            return message;
        };

        /**
         * Creates a plain object from a ContentJsx message. Also converts values to other types if specified.
         * @function toObject
         * @memberof presentation.ContentJsx
         * @static
         * @param {presentation.ContentJsx} message ContentJsx
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentJsx.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.src = "";
            if (message.src != null && message.hasOwnProperty("src"))
                object.src = message.src;
            return object;
        };

        /**
         * Converts this ContentJsx to JSON.
         * @function toJSON
         * @memberof presentation.ContentJsx
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentJsx.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentJsx;
    })();

    return presentation;
})();

export const authentication = $root.authentication = (() => {

    /**
     * Namespace authentication.
     * @exports authentication
     * @namespace
     */
    const authentication = {};

    authentication.LoginToken = (function() {

        /**
         * Properties of a LoginToken.
         * @memberof authentication
         * @interface ILoginToken
         * @property {string|null} [token] LoginToken token
         */

        /**
         * Constructs a new LoginToken.
         * @memberof authentication
         * @classdesc Represents a LoginToken.
         * @implements ILoginToken
         * @constructor
         * @param {authentication.ILoginToken=} [properties] Properties to set
         */
        function LoginToken(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoginToken token.
         * @member {string} token
         * @memberof authentication.LoginToken
         * @instance
         */
        LoginToken.prototype.token = "";

        /**
         * Creates a new LoginToken instance using the specified properties.
         * @function create
         * @memberof authentication.LoginToken
         * @static
         * @param {authentication.ILoginToken=} [properties] Properties to set
         * @returns {authentication.LoginToken} LoginToken instance
         */
        LoginToken.create = function create(properties) {
            return new LoginToken(properties);
        };

        /**
         * Encodes the specified LoginToken message. Does not implicitly {@link authentication.LoginToken.verify|verify} messages.
         * @function encode
         * @memberof authentication.LoginToken
         * @static
         * @param {authentication.ILoginToken} message LoginToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginToken.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
            return writer;
        };

        /**
         * Encodes the specified LoginToken message, length delimited. Does not implicitly {@link authentication.LoginToken.verify|verify} messages.
         * @function encodeDelimited
         * @memberof authentication.LoginToken
         * @static
         * @param {authentication.ILoginToken} message LoginToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginToken.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoginToken message from the specified reader or buffer.
         * @function decode
         * @memberof authentication.LoginToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {authentication.LoginToken} LoginToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginToken.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.authentication.LoginToken();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.token = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoginToken message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof authentication.LoginToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {authentication.LoginToken} LoginToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginToken.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoginToken message.
         * @function verify
         * @memberof authentication.LoginToken
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoginToken.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.token != null && message.hasOwnProperty("token"))
                if (!$util.isString(message.token))
                    return "token: string expected";
            return null;
        };

        /**
         * Creates a LoginToken message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof authentication.LoginToken
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {authentication.LoginToken} LoginToken
         */
        LoginToken.fromObject = function fromObject(object) {
            if (object instanceof $root.authentication.LoginToken)
                return object;
            let message = new $root.authentication.LoginToken();
            if (object.token != null)
                message.token = String(object.token);
            return message;
        };

        /**
         * Creates a plain object from a LoginToken message. Also converts values to other types if specified.
         * @function toObject
         * @memberof authentication.LoginToken
         * @static
         * @param {authentication.LoginToken} message LoginToken
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoginToken.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.token = "";
            if (message.token != null && message.hasOwnProperty("token"))
                object.token = message.token;
            return object;
        };

        /**
         * Converts this LoginToken to JSON.
         * @function toJSON
         * @memberof authentication.LoginToken
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoginToken.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LoginToken;
    })();

    authentication.LoginError = (function() {

        /**
         * Properties of a LoginError.
         * @memberof authentication
         * @interface ILoginError
         * @property {string|null} [errorText] LoginError errorText
         * @property {string|null} [errorCode] LoginError errorCode
         */

        /**
         * Constructs a new LoginError.
         * @memberof authentication
         * @classdesc Represents a LoginError.
         * @implements ILoginError
         * @constructor
         * @param {authentication.ILoginError=} [properties] Properties to set
         */
        function LoginError(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoginError errorText.
         * @member {string} errorText
         * @memberof authentication.LoginError
         * @instance
         */
        LoginError.prototype.errorText = "";

        /**
         * LoginError errorCode.
         * @member {string} errorCode
         * @memberof authentication.LoginError
         * @instance
         */
        LoginError.prototype.errorCode = "";

        /**
         * Creates a new LoginError instance using the specified properties.
         * @function create
         * @memberof authentication.LoginError
         * @static
         * @param {authentication.ILoginError=} [properties] Properties to set
         * @returns {authentication.LoginError} LoginError instance
         */
        LoginError.create = function create(properties) {
            return new LoginError(properties);
        };

        /**
         * Encodes the specified LoginError message. Does not implicitly {@link authentication.LoginError.verify|verify} messages.
         * @function encode
         * @memberof authentication.LoginError
         * @static
         * @param {authentication.ILoginError} message LoginError message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginError.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.errorText != null && Object.hasOwnProperty.call(message, "errorText"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.errorText);
            if (message.errorCode != null && Object.hasOwnProperty.call(message, "errorCode"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.errorCode);
            return writer;
        };

        /**
         * Encodes the specified LoginError message, length delimited. Does not implicitly {@link authentication.LoginError.verify|verify} messages.
         * @function encodeDelimited
         * @memberof authentication.LoginError
         * @static
         * @param {authentication.ILoginError} message LoginError message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginError.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoginError message from the specified reader or buffer.
         * @function decode
         * @memberof authentication.LoginError
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {authentication.LoginError} LoginError
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginError.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.authentication.LoginError();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.errorText = reader.string();
                    break;
                case 2:
                    message.errorCode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoginError message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof authentication.LoginError
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {authentication.LoginError} LoginError
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginError.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoginError message.
         * @function verify
         * @memberof authentication.LoginError
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoginError.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.errorText != null && message.hasOwnProperty("errorText"))
                if (!$util.isString(message.errorText))
                    return "errorText: string expected";
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                if (!$util.isString(message.errorCode))
                    return "errorCode: string expected";
            return null;
        };

        /**
         * Creates a LoginError message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof authentication.LoginError
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {authentication.LoginError} LoginError
         */
        LoginError.fromObject = function fromObject(object) {
            if (object instanceof $root.authentication.LoginError)
                return object;
            let message = new $root.authentication.LoginError();
            if (object.errorText != null)
                message.errorText = String(object.errorText);
            if (object.errorCode != null)
                message.errorCode = String(object.errorCode);
            return message;
        };

        /**
         * Creates a plain object from a LoginError message. Also converts values to other types if specified.
         * @function toObject
         * @memberof authentication.LoginError
         * @static
         * @param {authentication.LoginError} message LoginError
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoginError.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.errorText = "";
                object.errorCode = "";
            }
            if (message.errorText != null && message.hasOwnProperty("errorText"))
                object.errorText = message.errorText;
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                object.errorCode = message.errorCode;
            return object;
        };

        /**
         * Converts this LoginError to JSON.
         * @function toJSON
         * @memberof authentication.LoginError
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoginError.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LoginError;
    })();

    authentication.Login = (function() {

        /**
         * Properties of a Login.
         * @memberof authentication
         * @interface ILogin
         * @property {string|null} [username] Login username
         * @property {string|null} [password] Login password
         */

        /**
         * Constructs a new Login.
         * @memberof authentication
         * @classdesc Represents a Login.
         * @implements ILogin
         * @constructor
         * @param {authentication.ILogin=} [properties] Properties to set
         */
        function Login(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Login username.
         * @member {string} username
         * @memberof authentication.Login
         * @instance
         */
        Login.prototype.username = "";

        /**
         * Login password.
         * @member {string} password
         * @memberof authentication.Login
         * @instance
         */
        Login.prototype.password = "";

        /**
         * Creates a new Login instance using the specified properties.
         * @function create
         * @memberof authentication.Login
         * @static
         * @param {authentication.ILogin=} [properties] Properties to set
         * @returns {authentication.Login} Login instance
         */
        Login.create = function create(properties) {
            return new Login(properties);
        };

        /**
         * Encodes the specified Login message. Does not implicitly {@link authentication.Login.verify|verify} messages.
         * @function encode
         * @memberof authentication.Login
         * @static
         * @param {authentication.ILogin} message Login message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Login.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.username != null && Object.hasOwnProperty.call(message, "username"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.username);
            if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
            return writer;
        };

        /**
         * Encodes the specified Login message, length delimited. Does not implicitly {@link authentication.Login.verify|verify} messages.
         * @function encodeDelimited
         * @memberof authentication.Login
         * @static
         * @param {authentication.ILogin} message Login message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Login.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Login message from the specified reader or buffer.
         * @function decode
         * @memberof authentication.Login
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {authentication.Login} Login
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Login.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.authentication.Login();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.username = reader.string();
                    break;
                case 2:
                    message.password = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Login message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof authentication.Login
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {authentication.Login} Login
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Login.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Login message.
         * @function verify
         * @memberof authentication.Login
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Login.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.username != null && message.hasOwnProperty("username"))
                if (!$util.isString(message.username))
                    return "username: string expected";
            if (message.password != null && message.hasOwnProperty("password"))
                if (!$util.isString(message.password))
                    return "password: string expected";
            return null;
        };

        /**
         * Creates a Login message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof authentication.Login
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {authentication.Login} Login
         */
        Login.fromObject = function fromObject(object) {
            if (object instanceof $root.authentication.Login)
                return object;
            let message = new $root.authentication.Login();
            if (object.username != null)
                message.username = String(object.username);
            if (object.password != null)
                message.password = String(object.password);
            return message;
        };

        /**
         * Creates a plain object from a Login message. Also converts values to other types if specified.
         * @function toObject
         * @memberof authentication.Login
         * @static
         * @param {authentication.Login} message Login
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Login.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.username = "";
                object.password = "";
            }
            if (message.username != null && message.hasOwnProperty("username"))
                object.username = message.username;
            if (message.password != null && message.hasOwnProperty("password"))
                object.password = message.password;
            return object;
        };

        /**
         * Converts this Login to JSON.
         * @function toJSON
         * @memberof authentication.Login
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Login.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Login;
    })();

    authentication.AppleLogin = (function() {

        /**
         * Properties of an AppleLogin.
         * @memberof authentication
         * @interface IAppleLogin
         * @property {string|null} [code] AppleLogin code
         * @property {string|null} [firstName] AppleLogin firstName
         * @property {string|null} [lastName] AppleLogin lastName
         * @property {boolean|null} [useBundleId] AppleLogin useBundleId
         * @property {string|null} [state] AppleLogin state
         */

        /**
         * Constructs a new AppleLogin.
         * @memberof authentication
         * @classdesc Represents an AppleLogin.
         * @implements IAppleLogin
         * @constructor
         * @param {authentication.IAppleLogin=} [properties] Properties to set
         */
        function AppleLogin(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AppleLogin code.
         * @member {string} code
         * @memberof authentication.AppleLogin
         * @instance
         */
        AppleLogin.prototype.code = "";

        /**
         * AppleLogin firstName.
         * @member {string} firstName
         * @memberof authentication.AppleLogin
         * @instance
         */
        AppleLogin.prototype.firstName = "";

        /**
         * AppleLogin lastName.
         * @member {string} lastName
         * @memberof authentication.AppleLogin
         * @instance
         */
        AppleLogin.prototype.lastName = "";

        /**
         * AppleLogin useBundleId.
         * @member {boolean} useBundleId
         * @memberof authentication.AppleLogin
         * @instance
         */
        AppleLogin.prototype.useBundleId = false;

        /**
         * AppleLogin state.
         * @member {string} state
         * @memberof authentication.AppleLogin
         * @instance
         */
        AppleLogin.prototype.state = "";

        /**
         * Creates a new AppleLogin instance using the specified properties.
         * @function create
         * @memberof authentication.AppleLogin
         * @static
         * @param {authentication.IAppleLogin=} [properties] Properties to set
         * @returns {authentication.AppleLogin} AppleLogin instance
         */
        AppleLogin.create = function create(properties) {
            return new AppleLogin(properties);
        };

        /**
         * Encodes the specified AppleLogin message. Does not implicitly {@link authentication.AppleLogin.verify|verify} messages.
         * @function encode
         * @memberof authentication.AppleLogin
         * @static
         * @param {authentication.IAppleLogin} message AppleLogin message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppleLogin.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.code);
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.firstName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.lastName);
            if (message.useBundleId != null && Object.hasOwnProperty.call(message, "useBundleId"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.useBundleId);
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.state);
            return writer;
        };

        /**
         * Encodes the specified AppleLogin message, length delimited. Does not implicitly {@link authentication.AppleLogin.verify|verify} messages.
         * @function encodeDelimited
         * @memberof authentication.AppleLogin
         * @static
         * @param {authentication.IAppleLogin} message AppleLogin message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppleLogin.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AppleLogin message from the specified reader or buffer.
         * @function decode
         * @memberof authentication.AppleLogin
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {authentication.AppleLogin} AppleLogin
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppleLogin.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.authentication.AppleLogin();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.code = reader.string();
                    break;
                case 2:
                    message.firstName = reader.string();
                    break;
                case 3:
                    message.lastName = reader.string();
                    break;
                case 4:
                    message.useBundleId = reader.bool();
                    break;
                case 5:
                    message.state = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AppleLogin message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof authentication.AppleLogin
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {authentication.AppleLogin} AppleLogin
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppleLogin.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AppleLogin message.
         * @function verify
         * @memberof authentication.AppleLogin
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AppleLogin.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isString(message.code))
                    return "code: string expected";
            if (message.firstName != null && message.hasOwnProperty("firstName"))
                if (!$util.isString(message.firstName))
                    return "firstName: string expected";
            if (message.lastName != null && message.hasOwnProperty("lastName"))
                if (!$util.isString(message.lastName))
                    return "lastName: string expected";
            if (message.useBundleId != null && message.hasOwnProperty("useBundleId"))
                if (typeof message.useBundleId !== "boolean")
                    return "useBundleId: boolean expected";
            if (message.state != null && message.hasOwnProperty("state"))
                if (!$util.isString(message.state))
                    return "state: string expected";
            return null;
        };

        /**
         * Creates an AppleLogin message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof authentication.AppleLogin
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {authentication.AppleLogin} AppleLogin
         */
        AppleLogin.fromObject = function fromObject(object) {
            if (object instanceof $root.authentication.AppleLogin)
                return object;
            let message = new $root.authentication.AppleLogin();
            if (object.code != null)
                message.code = String(object.code);
            if (object.firstName != null)
                message.firstName = String(object.firstName);
            if (object.lastName != null)
                message.lastName = String(object.lastName);
            if (object.useBundleId != null)
                message.useBundleId = Boolean(object.useBundleId);
            if (object.state != null)
                message.state = String(object.state);
            return message;
        };

        /**
         * Creates a plain object from an AppleLogin message. Also converts values to other types if specified.
         * @function toObject
         * @memberof authentication.AppleLogin
         * @static
         * @param {authentication.AppleLogin} message AppleLogin
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AppleLogin.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.code = "";
                object.firstName = "";
                object.lastName = "";
                object.useBundleId = false;
                object.state = "";
            }
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            if (message.firstName != null && message.hasOwnProperty("firstName"))
                object.firstName = message.firstName;
            if (message.lastName != null && message.hasOwnProperty("lastName"))
                object.lastName = message.lastName;
            if (message.useBundleId != null && message.hasOwnProperty("useBundleId"))
                object.useBundleId = message.useBundleId;
            if (message.state != null && message.hasOwnProperty("state"))
                object.state = message.state;
            return object;
        };

        /**
         * Converts this AppleLogin to JSON.
         * @function toJSON
         * @memberof authentication.AppleLogin
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AppleLogin.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AppleLogin;
    })();

    authentication.VerifyToken = (function() {

        /**
         * Properties of a VerifyToken.
         * @memberof authentication
         * @interface IVerifyToken
         */

        /**
         * Constructs a new VerifyToken.
         * @memberof authentication
         * @classdesc Represents a VerifyToken.
         * @implements IVerifyToken
         * @constructor
         * @param {authentication.IVerifyToken=} [properties] Properties to set
         */
        function VerifyToken(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new VerifyToken instance using the specified properties.
         * @function create
         * @memberof authentication.VerifyToken
         * @static
         * @param {authentication.IVerifyToken=} [properties] Properties to set
         * @returns {authentication.VerifyToken} VerifyToken instance
         */
        VerifyToken.create = function create(properties) {
            return new VerifyToken(properties);
        };

        /**
         * Encodes the specified VerifyToken message. Does not implicitly {@link authentication.VerifyToken.verify|verify} messages.
         * @function encode
         * @memberof authentication.VerifyToken
         * @static
         * @param {authentication.IVerifyToken} message VerifyToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VerifyToken.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified VerifyToken message, length delimited. Does not implicitly {@link authentication.VerifyToken.verify|verify} messages.
         * @function encodeDelimited
         * @memberof authentication.VerifyToken
         * @static
         * @param {authentication.IVerifyToken} message VerifyToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VerifyToken.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a VerifyToken message from the specified reader or buffer.
         * @function decode
         * @memberof authentication.VerifyToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {authentication.VerifyToken} VerifyToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VerifyToken.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.authentication.VerifyToken();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a VerifyToken message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof authentication.VerifyToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {authentication.VerifyToken} VerifyToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VerifyToken.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a VerifyToken message.
         * @function verify
         * @memberof authentication.VerifyToken
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        VerifyToken.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a VerifyToken message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof authentication.VerifyToken
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {authentication.VerifyToken} VerifyToken
         */
        VerifyToken.fromObject = function fromObject(object) {
            if (object instanceof $root.authentication.VerifyToken)
                return object;
            return new $root.authentication.VerifyToken();
        };

        /**
         * Creates a plain object from a VerifyToken message. Also converts values to other types if specified.
         * @function toObject
         * @memberof authentication.VerifyToken
         * @static
         * @param {authentication.VerifyToken} message VerifyToken
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VerifyToken.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this VerifyToken to JSON.
         * @function toJSON
         * @memberof authentication.VerifyToken
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VerifyToken.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return VerifyToken;
    })();

    authentication.TokenInvalid = (function() {

        /**
         * Properties of a TokenInvalid.
         * @memberof authentication
         * @interface ITokenInvalid
         */

        /**
         * Constructs a new TokenInvalid.
         * @memberof authentication
         * @classdesc Represents a TokenInvalid.
         * @implements ITokenInvalid
         * @constructor
         * @param {authentication.ITokenInvalid=} [properties] Properties to set
         */
        function TokenInvalid(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new TokenInvalid instance using the specified properties.
         * @function create
         * @memberof authentication.TokenInvalid
         * @static
         * @param {authentication.ITokenInvalid=} [properties] Properties to set
         * @returns {authentication.TokenInvalid} TokenInvalid instance
         */
        TokenInvalid.create = function create(properties) {
            return new TokenInvalid(properties);
        };

        /**
         * Encodes the specified TokenInvalid message. Does not implicitly {@link authentication.TokenInvalid.verify|verify} messages.
         * @function encode
         * @memberof authentication.TokenInvalid
         * @static
         * @param {authentication.ITokenInvalid} message TokenInvalid message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TokenInvalid.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified TokenInvalid message, length delimited. Does not implicitly {@link authentication.TokenInvalid.verify|verify} messages.
         * @function encodeDelimited
         * @memberof authentication.TokenInvalid
         * @static
         * @param {authentication.ITokenInvalid} message TokenInvalid message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TokenInvalid.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TokenInvalid message from the specified reader or buffer.
         * @function decode
         * @memberof authentication.TokenInvalid
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {authentication.TokenInvalid} TokenInvalid
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TokenInvalid.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.authentication.TokenInvalid();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TokenInvalid message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof authentication.TokenInvalid
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {authentication.TokenInvalid} TokenInvalid
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TokenInvalid.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TokenInvalid message.
         * @function verify
         * @memberof authentication.TokenInvalid
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TokenInvalid.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a TokenInvalid message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof authentication.TokenInvalid
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {authentication.TokenInvalid} TokenInvalid
         */
        TokenInvalid.fromObject = function fromObject(object) {
            if (object instanceof $root.authentication.TokenInvalid)
                return object;
            return new $root.authentication.TokenInvalid();
        };

        /**
         * Creates a plain object from a TokenInvalid message. Also converts values to other types if specified.
         * @function toObject
         * @memberof authentication.TokenInvalid
         * @static
         * @param {authentication.TokenInvalid} message TokenInvalid
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TokenInvalid.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this TokenInvalid to JSON.
         * @function toJSON
         * @memberof authentication.TokenInvalid
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TokenInvalid.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TokenInvalid;
    })();

    return authentication;
})();

export const form_errors = $root.form_errors = (() => {

    /**
     * Namespace form_errors.
     * @exports form_errors
     * @namespace
     */
    const form_errors = {};

    form_errors.FormErrors = (function() {

        /**
         * Properties of a FormErrors.
         * @memberof form_errors
         * @interface IFormErrors
         * @property {string|null} [relatedMessageType] FormErrors relatedMessageType
         * @property {Object.<string,form_errors.ISList>|null} [errors] FormErrors errors
         */

        /**
         * Constructs a new FormErrors.
         * @memberof form_errors
         * @classdesc Represents a FormErrors.
         * @implements IFormErrors
         * @constructor
         * @param {form_errors.IFormErrors=} [properties] Properties to set
         */
        function FormErrors(properties) {
            this.errors = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FormErrors relatedMessageType.
         * @member {string} relatedMessageType
         * @memberof form_errors.FormErrors
         * @instance
         */
        FormErrors.prototype.relatedMessageType = "";

        /**
         * FormErrors errors.
         * @member {Object.<string,form_errors.ISList>} errors
         * @memberof form_errors.FormErrors
         * @instance
         */
        FormErrors.prototype.errors = $util.emptyObject;

        /**
         * Creates a new FormErrors instance using the specified properties.
         * @function create
         * @memberof form_errors.FormErrors
         * @static
         * @param {form_errors.IFormErrors=} [properties] Properties to set
         * @returns {form_errors.FormErrors} FormErrors instance
         */
        FormErrors.create = function create(properties) {
            return new FormErrors(properties);
        };

        /**
         * Encodes the specified FormErrors message. Does not implicitly {@link form_errors.FormErrors.verify|verify} messages.
         * @function encode
         * @memberof form_errors.FormErrors
         * @static
         * @param {form_errors.IFormErrors} message FormErrors message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FormErrors.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.relatedMessageType != null && Object.hasOwnProperty.call(message, "relatedMessageType"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.relatedMessageType);
            if (message.errors != null && Object.hasOwnProperty.call(message, "errors"))
                for (let keys = Object.keys(message.errors), i = 0; i < keys.length; ++i) {
                    writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                    $root.form_errors.SList.encode(message.errors[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                }
            return writer;
        };

        /**
         * Encodes the specified FormErrors message, length delimited. Does not implicitly {@link form_errors.FormErrors.verify|verify} messages.
         * @function encodeDelimited
         * @memberof form_errors.FormErrors
         * @static
         * @param {form_errors.IFormErrors} message FormErrors message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FormErrors.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FormErrors message from the specified reader or buffer.
         * @function decode
         * @memberof form_errors.FormErrors
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {form_errors.FormErrors} FormErrors
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FormErrors.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.form_errors.FormErrors(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.relatedMessageType = reader.string();
                    break;
                case 2:
                    if (message.errors === $util.emptyObject)
                        message.errors = {};
                    let end2 = reader.uint32() + reader.pos;
                    key = "";
                    value = null;
                    while (reader.pos < end2) {
                        let tag2 = reader.uint32();
                        switch (tag2 >>> 3) {
                        case 1:
                            key = reader.string();
                            break;
                        case 2:
                            value = $root.form_errors.SList.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag2 & 7);
                            break;
                        }
                    }
                    message.errors[key] = value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FormErrors message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof form_errors.FormErrors
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {form_errors.FormErrors} FormErrors
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FormErrors.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FormErrors message.
         * @function verify
         * @memberof form_errors.FormErrors
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FormErrors.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.relatedMessageType != null && message.hasOwnProperty("relatedMessageType"))
                if (!$util.isString(message.relatedMessageType))
                    return "relatedMessageType: string expected";
            if (message.errors != null && message.hasOwnProperty("errors")) {
                if (!$util.isObject(message.errors))
                    return "errors: object expected";
                let key = Object.keys(message.errors);
                for (let i = 0; i < key.length; ++i) {
                    let error = $root.form_errors.SList.verify(message.errors[key[i]]);
                    if (error)
                        return "errors." + error;
                }
            }
            return null;
        };

        /**
         * Creates a FormErrors message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof form_errors.FormErrors
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {form_errors.FormErrors} FormErrors
         */
        FormErrors.fromObject = function fromObject(object) {
            if (object instanceof $root.form_errors.FormErrors)
                return object;
            let message = new $root.form_errors.FormErrors();
            if (object.relatedMessageType != null)
                message.relatedMessageType = String(object.relatedMessageType);
            if (object.errors) {
                if (typeof object.errors !== "object")
                    throw TypeError(".form_errors.FormErrors.errors: object expected");
                message.errors = {};
                for (let keys = Object.keys(object.errors), i = 0; i < keys.length; ++i) {
                    if (typeof object.errors[keys[i]] !== "object")
                        throw TypeError(".form_errors.FormErrors.errors: object expected");
                    message.errors[keys[i]] = $root.form_errors.SList.fromObject(object.errors[keys[i]]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a FormErrors message. Also converts values to other types if specified.
         * @function toObject
         * @memberof form_errors.FormErrors
         * @static
         * @param {form_errors.FormErrors} message FormErrors
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FormErrors.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.errors = {};
            if (options.defaults)
                object.relatedMessageType = "";
            if (message.relatedMessageType != null && message.hasOwnProperty("relatedMessageType"))
                object.relatedMessageType = message.relatedMessageType;
            let keys2;
            if (message.errors && (keys2 = Object.keys(message.errors)).length) {
                object.errors = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.errors[keys2[j]] = $root.form_errors.SList.toObject(message.errors[keys2[j]], options);
            }
            return object;
        };

        /**
         * Converts this FormErrors to JSON.
         * @function toJSON
         * @memberof form_errors.FormErrors
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FormErrors.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FormErrors;
    })();

    form_errors.SList = (function() {

        /**
         * Properties of a SList.
         * @memberof form_errors
         * @interface ISList
         * @property {Array.<string>|null} [list] SList list
         */

        /**
         * Constructs a new SList.
         * @memberof form_errors
         * @classdesc Represents a SList.
         * @implements ISList
         * @constructor
         * @param {form_errors.ISList=} [properties] Properties to set
         */
        function SList(properties) {
            this.list = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SList list.
         * @member {Array.<string>} list
         * @memberof form_errors.SList
         * @instance
         */
        SList.prototype.list = $util.emptyArray;

        /**
         * Creates a new SList instance using the specified properties.
         * @function create
         * @memberof form_errors.SList
         * @static
         * @param {form_errors.ISList=} [properties] Properties to set
         * @returns {form_errors.SList} SList instance
         */
        SList.create = function create(properties) {
            return new SList(properties);
        };

        /**
         * Encodes the specified SList message. Does not implicitly {@link form_errors.SList.verify|verify} messages.
         * @function encode
         * @memberof form_errors.SList
         * @static
         * @param {form_errors.ISList} message SList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.list != null && message.list.length)
                for (let i = 0; i < message.list.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.list[i]);
            return writer;
        };

        /**
         * Encodes the specified SList message, length delimited. Does not implicitly {@link form_errors.SList.verify|verify} messages.
         * @function encodeDelimited
         * @memberof form_errors.SList
         * @static
         * @param {form_errors.ISList} message SList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SList.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SList message from the specified reader or buffer.
         * @function decode
         * @memberof form_errors.SList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {form_errors.SList} SList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.form_errors.SList();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.list && message.list.length))
                        message.list = [];
                    message.list.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SList message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof form_errors.SList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {form_errors.SList} SList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SList.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SList message.
         * @function verify
         * @memberof form_errors.SList
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SList.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.list != null && message.hasOwnProperty("list")) {
                if (!Array.isArray(message.list))
                    return "list: array expected";
                for (let i = 0; i < message.list.length; ++i)
                    if (!$util.isString(message.list[i]))
                        return "list: string[] expected";
            }
            return null;
        };

        /**
         * Creates a SList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof form_errors.SList
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {form_errors.SList} SList
         */
        SList.fromObject = function fromObject(object) {
            if (object instanceof $root.form_errors.SList)
                return object;
            let message = new $root.form_errors.SList();
            if (object.list) {
                if (!Array.isArray(object.list))
                    throw TypeError(".form_errors.SList.list: array expected");
                message.list = [];
                for (let i = 0; i < object.list.length; ++i)
                    message.list[i] = String(object.list[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a SList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof form_errors.SList
         * @static
         * @param {form_errors.SList} message SList
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SList.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.list = [];
            if (message.list && message.list.length) {
                object.list = [];
                for (let j = 0; j < message.list.length; ++j)
                    object.list[j] = message.list[j];
            }
            return object;
        };

        /**
         * Converts this SList to JSON.
         * @function toJSON
         * @memberof form_errors.SList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SList;
    })();

    return form_errors;
})();

export const sfiles = $root.sfiles = (() => {

    /**
     * Namespace sfiles.
     * @exports sfiles
     * @namespace
     */
    const sfiles = {};

    sfiles.UploadStartSlot = (function() {

        /**
         * Properties of an UploadStartSlot.
         * @memberof sfiles
         * @interface IUploadStartSlot
         * @property {string|null} [key] UploadStartSlot key
         * @property {string|null} [localKey] UploadStartSlot localKey
         */

        /**
         * Constructs a new UploadStartSlot.
         * @memberof sfiles
         * @classdesc Represents an UploadStartSlot.
         * @implements IUploadStartSlot
         * @constructor
         * @param {sfiles.IUploadStartSlot=} [properties] Properties to set
         */
        function UploadStartSlot(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadStartSlot key.
         * @member {string} key
         * @memberof sfiles.UploadStartSlot
         * @instance
         */
        UploadStartSlot.prototype.key = "";

        /**
         * UploadStartSlot localKey.
         * @member {string} localKey
         * @memberof sfiles.UploadStartSlot
         * @instance
         */
        UploadStartSlot.prototype.localKey = "";

        /**
         * Creates a new UploadStartSlot instance using the specified properties.
         * @function create
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {sfiles.IUploadStartSlot=} [properties] Properties to set
         * @returns {sfiles.UploadStartSlot} UploadStartSlot instance
         */
        UploadStartSlot.create = function create(properties) {
            return new UploadStartSlot(properties);
        };

        /**
         * Encodes the specified UploadStartSlot message. Does not implicitly {@link sfiles.UploadStartSlot.verify|verify} messages.
         * @function encode
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {sfiles.IUploadStartSlot} message UploadStartSlot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadStartSlot.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            if (message.localKey != null && Object.hasOwnProperty.call(message, "localKey"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.localKey);
            return writer;
        };

        /**
         * Encodes the specified UploadStartSlot message, length delimited. Does not implicitly {@link sfiles.UploadStartSlot.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {sfiles.IUploadStartSlot} message UploadStartSlot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadStartSlot.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadStartSlot message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.UploadStartSlot} UploadStartSlot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadStartSlot.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.UploadStartSlot();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.localKey = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadStartSlot message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.UploadStartSlot} UploadStartSlot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadStartSlot.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadStartSlot message.
         * @function verify
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadStartSlot.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                if (!$util.isString(message.localKey))
                    return "localKey: string expected";
            return null;
        };

        /**
         * Creates an UploadStartSlot message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.UploadStartSlot} UploadStartSlot
         */
        UploadStartSlot.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.UploadStartSlot)
                return object;
            let message = new $root.sfiles.UploadStartSlot();
            if (object.key != null)
                message.key = String(object.key);
            if (object.localKey != null)
                message.localKey = String(object.localKey);
            return message;
        };

        /**
         * Creates a plain object from an UploadStartSlot message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {sfiles.UploadStartSlot} message UploadStartSlot
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadStartSlot.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.key = "";
                object.localKey = "";
            }
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                object.localKey = message.localKey;
            return object;
        };

        /**
         * Converts this UploadStartSlot to JSON.
         * @function toJSON
         * @memberof sfiles.UploadStartSlot
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadStartSlot.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UploadStartSlot;
    })();

    sfiles.UploadProgress = (function() {

        /**
         * Properties of an UploadProgress.
         * @memberof sfiles
         * @interface IUploadProgress
         * @property {number|Long|null} [nBytes] UploadProgress nBytes
         * @property {string|null} [key] UploadProgress key
         * @property {string|null} [localKey] UploadProgress localKey
         */

        /**
         * Constructs a new UploadProgress.
         * @memberof sfiles
         * @classdesc Represents an UploadProgress.
         * @implements IUploadProgress
         * @constructor
         * @param {sfiles.IUploadProgress=} [properties] Properties to set
         */
        function UploadProgress(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadProgress nBytes.
         * @member {number|Long} nBytes
         * @memberof sfiles.UploadProgress
         * @instance
         */
        UploadProgress.prototype.nBytes = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * UploadProgress key.
         * @member {string} key
         * @memberof sfiles.UploadProgress
         * @instance
         */
        UploadProgress.prototype.key = "";

        /**
         * UploadProgress localKey.
         * @member {string} localKey
         * @memberof sfiles.UploadProgress
         * @instance
         */
        UploadProgress.prototype.localKey = "";

        /**
         * Creates a new UploadProgress instance using the specified properties.
         * @function create
         * @memberof sfiles.UploadProgress
         * @static
         * @param {sfiles.IUploadProgress=} [properties] Properties to set
         * @returns {sfiles.UploadProgress} UploadProgress instance
         */
        UploadProgress.create = function create(properties) {
            return new UploadProgress(properties);
        };

        /**
         * Encodes the specified UploadProgress message. Does not implicitly {@link sfiles.UploadProgress.verify|verify} messages.
         * @function encode
         * @memberof sfiles.UploadProgress
         * @static
         * @param {sfiles.IUploadProgress} message UploadProgress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadProgress.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.nBytes != null && Object.hasOwnProperty.call(message, "nBytes"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.nBytes);
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.key);
            if (message.localKey != null && Object.hasOwnProperty.call(message, "localKey"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.localKey);
            return writer;
        };

        /**
         * Encodes the specified UploadProgress message, length delimited. Does not implicitly {@link sfiles.UploadProgress.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.UploadProgress
         * @static
         * @param {sfiles.IUploadProgress} message UploadProgress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadProgress.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadProgress message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.UploadProgress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.UploadProgress} UploadProgress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadProgress.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.UploadProgress();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.nBytes = reader.int64();
                    break;
                case 2:
                    message.key = reader.string();
                    break;
                case 3:
                    message.localKey = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadProgress message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.UploadProgress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.UploadProgress} UploadProgress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadProgress.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadProgress message.
         * @function verify
         * @memberof sfiles.UploadProgress
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadProgress.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.nBytes != null && message.hasOwnProperty("nBytes"))
                if (!$util.isInteger(message.nBytes) && !(message.nBytes && $util.isInteger(message.nBytes.low) && $util.isInteger(message.nBytes.high)))
                    return "nBytes: integer|Long expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                if (!$util.isString(message.localKey))
                    return "localKey: string expected";
            return null;
        };

        /**
         * Creates an UploadProgress message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.UploadProgress
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.UploadProgress} UploadProgress
         */
        UploadProgress.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.UploadProgress)
                return object;
            let message = new $root.sfiles.UploadProgress();
            if (object.nBytes != null)
                if ($util.Long)
                    (message.nBytes = $util.Long.fromValue(object.nBytes)).unsigned = false;
                else if (typeof object.nBytes === "string")
                    message.nBytes = parseInt(object.nBytes, 10);
                else if (typeof object.nBytes === "number")
                    message.nBytes = object.nBytes;
                else if (typeof object.nBytes === "object")
                    message.nBytes = new $util.LongBits(object.nBytes.low >>> 0, object.nBytes.high >>> 0).toNumber();
            if (object.key != null)
                message.key = String(object.key);
            if (object.localKey != null)
                message.localKey = String(object.localKey);
            return message;
        };

        /**
         * Creates a plain object from an UploadProgress message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.UploadProgress
         * @static
         * @param {sfiles.UploadProgress} message UploadProgress
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadProgress.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.nBytes = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.nBytes = options.longs === String ? "0" : 0;
                object.key = "";
                object.localKey = "";
            }
            if (message.nBytes != null && message.hasOwnProperty("nBytes"))
                if (typeof message.nBytes === "number")
                    object.nBytes = options.longs === String ? String(message.nBytes) : message.nBytes;
                else
                    object.nBytes = options.longs === String ? $util.Long.prototype.toString.call(message.nBytes) : options.longs === Number ? new $util.LongBits(message.nBytes.low >>> 0, message.nBytes.high >>> 0).toNumber() : message.nBytes;
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                object.localKey = message.localKey;
            return object;
        };

        /**
         * Converts this UploadProgress to JSON.
         * @function toJSON
         * @memberof sfiles.UploadProgress
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadProgress.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UploadProgress;
    })();

    sfiles.UploadDone = (function() {

        /**
         * Properties of an UploadDone.
         * @memberof sfiles
         * @interface IUploadDone
         * @property {string|null} [key] UploadDone key
         * @property {sfiles.IUploadedFile|null} [file] UploadDone file
         */

        /**
         * Constructs a new UploadDone.
         * @memberof sfiles
         * @classdesc Represents an UploadDone.
         * @implements IUploadDone
         * @constructor
         * @param {sfiles.IUploadDone=} [properties] Properties to set
         */
        function UploadDone(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadDone key.
         * @member {string} key
         * @memberof sfiles.UploadDone
         * @instance
         */
        UploadDone.prototype.key = "";

        /**
         * UploadDone file.
         * @member {sfiles.IUploadedFile|null|undefined} file
         * @memberof sfiles.UploadDone
         * @instance
         */
        UploadDone.prototype.file = null;

        /**
         * Creates a new UploadDone instance using the specified properties.
         * @function create
         * @memberof sfiles.UploadDone
         * @static
         * @param {sfiles.IUploadDone=} [properties] Properties to set
         * @returns {sfiles.UploadDone} UploadDone instance
         */
        UploadDone.create = function create(properties) {
            return new UploadDone(properties);
        };

        /**
         * Encodes the specified UploadDone message. Does not implicitly {@link sfiles.UploadDone.verify|verify} messages.
         * @function encode
         * @memberof sfiles.UploadDone
         * @static
         * @param {sfiles.IUploadDone} message UploadDone message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadDone.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            if (message.file != null && Object.hasOwnProperty.call(message, "file"))
                $root.sfiles.UploadedFile.encode(message.file, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UploadDone message, length delimited. Does not implicitly {@link sfiles.UploadDone.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.UploadDone
         * @static
         * @param {sfiles.IUploadDone} message UploadDone message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadDone.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadDone message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.UploadDone
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.UploadDone} UploadDone
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadDone.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.UploadDone();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.file = $root.sfiles.UploadedFile.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadDone message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.UploadDone
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.UploadDone} UploadDone
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadDone.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadDone message.
         * @function verify
         * @memberof sfiles.UploadDone
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadDone.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.file != null && message.hasOwnProperty("file")) {
                let error = $root.sfiles.UploadedFile.verify(message.file);
                if (error)
                    return "file." + error;
            }
            return null;
        };

        /**
         * Creates an UploadDone message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.UploadDone
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.UploadDone} UploadDone
         */
        UploadDone.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.UploadDone)
                return object;
            let message = new $root.sfiles.UploadDone();
            if (object.key != null)
                message.key = String(object.key);
            if (object.file != null) {
                if (typeof object.file !== "object")
                    throw TypeError(".sfiles.UploadDone.file: object expected");
                message.file = $root.sfiles.UploadedFile.fromObject(object.file);
            }
            return message;
        };

        /**
         * Creates a plain object from an UploadDone message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.UploadDone
         * @static
         * @param {sfiles.UploadDone} message UploadDone
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadDone.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.key = "";
                object.file = null;
            }
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.file != null && message.hasOwnProperty("file"))
                object.file = $root.sfiles.UploadedFile.toObject(message.file, options);
            return object;
        };

        /**
         * Converts this UploadDone to JSON.
         * @function toJSON
         * @memberof sfiles.UploadDone
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadDone.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UploadDone;
    })();

    sfiles.UploadedFile = (function() {

        /**
         * Properties of an UploadedFile.
         * @memberof sfiles
         * @interface IUploadedFile
         * @property {string|null} [localKey] UploadedFile localKey
         * @property {string|null} [url] UploadedFile url
         * @property {string|null} [id] UploadedFile id
         * @property {string|null} [mime] UploadedFile mime
         * @property {string|null} [thumbUrl] UploadedFile thumbUrl
         * @property {string|null} [name] UploadedFile name
         * @property {boolean|null} [downloadable] UploadedFile downloadable
         */

        /**
         * Constructs a new UploadedFile.
         * @memberof sfiles
         * @classdesc Represents an UploadedFile.
         * @implements IUploadedFile
         * @constructor
         * @param {sfiles.IUploadedFile=} [properties] Properties to set
         */
        function UploadedFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadedFile localKey.
         * @member {string} localKey
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.localKey = "";

        /**
         * UploadedFile url.
         * @member {string} url
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.url = "";

        /**
         * UploadedFile id.
         * @member {string} id
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.id = "";

        /**
         * UploadedFile mime.
         * @member {string} mime
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.mime = "";

        /**
         * UploadedFile thumbUrl.
         * @member {string} thumbUrl
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.thumbUrl = "";

        /**
         * UploadedFile name.
         * @member {string} name
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.name = "";

        /**
         * UploadedFile downloadable.
         * @member {boolean} downloadable
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.downloadable = false;

        /**
         * Creates a new UploadedFile instance using the specified properties.
         * @function create
         * @memberof sfiles.UploadedFile
         * @static
         * @param {sfiles.IUploadedFile=} [properties] Properties to set
         * @returns {sfiles.UploadedFile} UploadedFile instance
         */
        UploadedFile.create = function create(properties) {
            return new UploadedFile(properties);
        };

        /**
         * Encodes the specified UploadedFile message. Does not implicitly {@link sfiles.UploadedFile.verify|verify} messages.
         * @function encode
         * @memberof sfiles.UploadedFile
         * @static
         * @param {sfiles.IUploadedFile} message UploadedFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadedFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.localKey != null && Object.hasOwnProperty.call(message, "localKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.localKey);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.url);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.id);
            if (message.mime != null && Object.hasOwnProperty.call(message, "mime"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.mime);
            if (message.thumbUrl != null && Object.hasOwnProperty.call(message, "thumbUrl"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.thumbUrl);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.name);
            if (message.downloadable != null && Object.hasOwnProperty.call(message, "downloadable"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.downloadable);
            return writer;
        };

        /**
         * Encodes the specified UploadedFile message, length delimited. Does not implicitly {@link sfiles.UploadedFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.UploadedFile
         * @static
         * @param {sfiles.IUploadedFile} message UploadedFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadedFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadedFile message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.UploadedFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.UploadedFile} UploadedFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadedFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.UploadedFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.localKey = reader.string();
                    break;
                case 2:
                    message.url = reader.string();
                    break;
                case 3:
                    message.id = reader.string();
                    break;
                case 4:
                    message.mime = reader.string();
                    break;
                case 5:
                    message.thumbUrl = reader.string();
                    break;
                case 6:
                    message.name = reader.string();
                    break;
                case 7:
                    message.downloadable = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadedFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.UploadedFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.UploadedFile} UploadedFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadedFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadedFile message.
         * @function verify
         * @memberof sfiles.UploadedFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadedFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                if (!$util.isString(message.localKey))
                    return "localKey: string expected";
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.mime != null && message.hasOwnProperty("mime"))
                if (!$util.isString(message.mime))
                    return "mime: string expected";
            if (message.thumbUrl != null && message.hasOwnProperty("thumbUrl"))
                if (!$util.isString(message.thumbUrl))
                    return "thumbUrl: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.downloadable != null && message.hasOwnProperty("downloadable"))
                if (typeof message.downloadable !== "boolean")
                    return "downloadable: boolean expected";
            return null;
        };

        /**
         * Creates an UploadedFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.UploadedFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.UploadedFile} UploadedFile
         */
        UploadedFile.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.UploadedFile)
                return object;
            let message = new $root.sfiles.UploadedFile();
            if (object.localKey != null)
                message.localKey = String(object.localKey);
            if (object.url != null)
                message.url = String(object.url);
            if (object.id != null)
                message.id = String(object.id);
            if (object.mime != null)
                message.mime = String(object.mime);
            if (object.thumbUrl != null)
                message.thumbUrl = String(object.thumbUrl);
            if (object.name != null)
                message.name = String(object.name);
            if (object.downloadable != null)
                message.downloadable = Boolean(object.downloadable);
            return message;
        };

        /**
         * Creates a plain object from an UploadedFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.UploadedFile
         * @static
         * @param {sfiles.UploadedFile} message UploadedFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadedFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.localKey = "";
                object.url = "";
                object.id = "";
                object.mime = "";
                object.thumbUrl = "";
                object.name = "";
                object.downloadable = false;
            }
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                object.localKey = message.localKey;
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.mime != null && message.hasOwnProperty("mime"))
                object.mime = message.mime;
            if (message.thumbUrl != null && message.hasOwnProperty("thumbUrl"))
                object.thumbUrl = message.thumbUrl;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.downloadable != null && message.hasOwnProperty("downloadable"))
                object.downloadable = message.downloadable;
            return object;
        };

        /**
         * Converts this UploadedFile to JSON.
         * @function toJSON
         * @memberof sfiles.UploadedFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadedFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UploadedFile;
    })();

    sfiles.UploadStart = (function() {

        /**
         * Properties of an UploadStart.
         * @memberof sfiles
         * @interface IUploadStart
         * @property {string|null} [localKey] UploadStart localKey
         * @property {string|null} [extension] UploadStart extension
         * @property {string|null} [name] UploadStart name
         * @property {string|null} [mime] UploadStart mime
         */

        /**
         * Constructs a new UploadStart.
         * @memberof sfiles
         * @classdesc Represents an UploadStart.
         * @implements IUploadStart
         * @constructor
         * @param {sfiles.IUploadStart=} [properties] Properties to set
         */
        function UploadStart(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadStart localKey.
         * @member {string} localKey
         * @memberof sfiles.UploadStart
         * @instance
         */
        UploadStart.prototype.localKey = "";

        /**
         * UploadStart extension.
         * @member {string} extension
         * @memberof sfiles.UploadStart
         * @instance
         */
        UploadStart.prototype.extension = "";

        /**
         * UploadStart name.
         * @member {string} name
         * @memberof sfiles.UploadStart
         * @instance
         */
        UploadStart.prototype.name = "";

        /**
         * UploadStart mime.
         * @member {string} mime
         * @memberof sfiles.UploadStart
         * @instance
         */
        UploadStart.prototype.mime = "";

        /**
         * Creates a new UploadStart instance using the specified properties.
         * @function create
         * @memberof sfiles.UploadStart
         * @static
         * @param {sfiles.IUploadStart=} [properties] Properties to set
         * @returns {sfiles.UploadStart} UploadStart instance
         */
        UploadStart.create = function create(properties) {
            return new UploadStart(properties);
        };

        /**
         * Encodes the specified UploadStart message. Does not implicitly {@link sfiles.UploadStart.verify|verify} messages.
         * @function encode
         * @memberof sfiles.UploadStart
         * @static
         * @param {sfiles.IUploadStart} message UploadStart message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadStart.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.localKey != null && Object.hasOwnProperty.call(message, "localKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.localKey);
            if (message.extension != null && Object.hasOwnProperty.call(message, "extension"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.extension);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.mime != null && Object.hasOwnProperty.call(message, "mime"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.mime);
            return writer;
        };

        /**
         * Encodes the specified UploadStart message, length delimited. Does not implicitly {@link sfiles.UploadStart.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.UploadStart
         * @static
         * @param {sfiles.IUploadStart} message UploadStart message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadStart.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadStart message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.UploadStart
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.UploadStart} UploadStart
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadStart.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.UploadStart();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.localKey = reader.string();
                    break;
                case 2:
                    message.extension = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                case 4:
                    message.mime = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadStart message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.UploadStart
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.UploadStart} UploadStart
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadStart.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadStart message.
         * @function verify
         * @memberof sfiles.UploadStart
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadStart.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                if (!$util.isString(message.localKey))
                    return "localKey: string expected";
            if (message.extension != null && message.hasOwnProperty("extension"))
                if (!$util.isString(message.extension))
                    return "extension: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.mime != null && message.hasOwnProperty("mime"))
                if (!$util.isString(message.mime))
                    return "mime: string expected";
            return null;
        };

        /**
         * Creates an UploadStart message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.UploadStart
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.UploadStart} UploadStart
         */
        UploadStart.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.UploadStart)
                return object;
            let message = new $root.sfiles.UploadStart();
            if (object.localKey != null)
                message.localKey = String(object.localKey);
            if (object.extension != null)
                message.extension = String(object.extension);
            if (object.name != null)
                message.name = String(object.name);
            if (object.mime != null)
                message.mime = String(object.mime);
            return message;
        };

        /**
         * Creates a plain object from an UploadStart message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.UploadStart
         * @static
         * @param {sfiles.UploadStart} message UploadStart
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadStart.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.localKey = "";
                object.extension = "";
                object.name = "";
                object.mime = "";
            }
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                object.localKey = message.localKey;
            if (message.extension != null && message.hasOwnProperty("extension"))
                object.extension = message.extension;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.mime != null && message.hasOwnProperty("mime"))
                object.mime = message.mime;
            return object;
        };

        /**
         * Converts this UploadStart to JSON.
         * @function toJSON
         * @memberof sfiles.UploadStart
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadStart.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UploadStart;
    })();

    sfiles.DeleteFile = (function() {

        /**
         * Properties of a DeleteFile.
         * @memberof sfiles
         * @interface IDeleteFile
         * @property {sfiles.IUploadedFile|null} [file] DeleteFile file
         */

        /**
         * Constructs a new DeleteFile.
         * @memberof sfiles
         * @classdesc Represents a DeleteFile.
         * @implements IDeleteFile
         * @constructor
         * @param {sfiles.IDeleteFile=} [properties] Properties to set
         */
        function DeleteFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteFile file.
         * @member {sfiles.IUploadedFile|null|undefined} file
         * @memberof sfiles.DeleteFile
         * @instance
         */
        DeleteFile.prototype.file = null;

        /**
         * Creates a new DeleteFile instance using the specified properties.
         * @function create
         * @memberof sfiles.DeleteFile
         * @static
         * @param {sfiles.IDeleteFile=} [properties] Properties to set
         * @returns {sfiles.DeleteFile} DeleteFile instance
         */
        DeleteFile.create = function create(properties) {
            return new DeleteFile(properties);
        };

        /**
         * Encodes the specified DeleteFile message. Does not implicitly {@link sfiles.DeleteFile.verify|verify} messages.
         * @function encode
         * @memberof sfiles.DeleteFile
         * @static
         * @param {sfiles.IDeleteFile} message DeleteFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.file != null && Object.hasOwnProperty.call(message, "file"))
                $root.sfiles.UploadedFile.encode(message.file, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified DeleteFile message, length delimited. Does not implicitly {@link sfiles.DeleteFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.DeleteFile
         * @static
         * @param {sfiles.IDeleteFile} message DeleteFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteFile message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.DeleteFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.DeleteFile} DeleteFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.DeleteFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.file = $root.sfiles.UploadedFile.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.DeleteFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.DeleteFile} DeleteFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteFile message.
         * @function verify
         * @memberof sfiles.DeleteFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.file != null && message.hasOwnProperty("file")) {
                let error = $root.sfiles.UploadedFile.verify(message.file);
                if (error)
                    return "file." + error;
            }
            return null;
        };

        /**
         * Creates a DeleteFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.DeleteFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.DeleteFile} DeleteFile
         */
        DeleteFile.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.DeleteFile)
                return object;
            let message = new $root.sfiles.DeleteFile();
            if (object.file != null) {
                if (typeof object.file !== "object")
                    throw TypeError(".sfiles.DeleteFile.file: object expected");
                message.file = $root.sfiles.UploadedFile.fromObject(object.file);
            }
            return message;
        };

        /**
         * Creates a plain object from a DeleteFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.DeleteFile
         * @static
         * @param {sfiles.DeleteFile} message DeleteFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.file = null;
            if (message.file != null && message.hasOwnProperty("file"))
                object.file = $root.sfiles.UploadedFile.toObject(message.file, options);
            return object;
        };

        /**
         * Converts this DeleteFile to JSON.
         * @function toJSON
         * @memberof sfiles.DeleteFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteFile;
    })();

    sfiles.UploadEnd = (function() {

        /**
         * Properties of an UploadEnd.
         * @memberof sfiles
         * @interface IUploadEnd
         */

        /**
         * Constructs a new UploadEnd.
         * @memberof sfiles
         * @classdesc Represents an UploadEnd.
         * @implements IUploadEnd
         * @constructor
         * @param {sfiles.IUploadEnd=} [properties] Properties to set
         */
        function UploadEnd(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new UploadEnd instance using the specified properties.
         * @function create
         * @memberof sfiles.UploadEnd
         * @static
         * @param {sfiles.IUploadEnd=} [properties] Properties to set
         * @returns {sfiles.UploadEnd} UploadEnd instance
         */
        UploadEnd.create = function create(properties) {
            return new UploadEnd(properties);
        };

        /**
         * Encodes the specified UploadEnd message. Does not implicitly {@link sfiles.UploadEnd.verify|verify} messages.
         * @function encode
         * @memberof sfiles.UploadEnd
         * @static
         * @param {sfiles.IUploadEnd} message UploadEnd message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadEnd.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified UploadEnd message, length delimited. Does not implicitly {@link sfiles.UploadEnd.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.UploadEnd
         * @static
         * @param {sfiles.IUploadEnd} message UploadEnd message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadEnd.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadEnd message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.UploadEnd
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.UploadEnd} UploadEnd
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadEnd.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.UploadEnd();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadEnd message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.UploadEnd
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.UploadEnd} UploadEnd
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadEnd.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadEnd message.
         * @function verify
         * @memberof sfiles.UploadEnd
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadEnd.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an UploadEnd message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.UploadEnd
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.UploadEnd} UploadEnd
         */
        UploadEnd.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.UploadEnd)
                return object;
            return new $root.sfiles.UploadEnd();
        };

        /**
         * Creates a plain object from an UploadEnd message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.UploadEnd
         * @static
         * @param {sfiles.UploadEnd} message UploadEnd
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadEnd.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this UploadEnd to JSON.
         * @function toJSON
         * @memberof sfiles.UploadEnd
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadEnd.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UploadEnd;
    })();

    return sfiles;
})();

export const socket_api = $root.socket_api = (() => {

    /**
     * Namespace socket_api.
     * @exports socket_api
     * @namespace
     */
    const socket_api = {};

    socket_api.Ack = (function() {

        /**
         * Properties of an Ack.
         * @memberof socket_api
         * @interface IAck
         * @property {string|null} [uuid] Ack uuid
         * @property {string|null} [errorMessage] Ack errorMessage
         * @property {string|null} [asyncProgressKey] Ack asyncProgressKey
         * @property {number|null} [errorCode] Ack errorCode
         */

        /**
         * Constructs a new Ack.
         * @memberof socket_api
         * @classdesc Represents an Ack.
         * @implements IAck
         * @constructor
         * @param {socket_api.IAck=} [properties] Properties to set
         */
        function Ack(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Ack uuid.
         * @member {string} uuid
         * @memberof socket_api.Ack
         * @instance
         */
        Ack.prototype.uuid = "";

        /**
         * Ack errorMessage.
         * @member {string} errorMessage
         * @memberof socket_api.Ack
         * @instance
         */
        Ack.prototype.errorMessage = "";

        /**
         * Ack asyncProgressKey.
         * @member {string} asyncProgressKey
         * @memberof socket_api.Ack
         * @instance
         */
        Ack.prototype.asyncProgressKey = "";

        /**
         * Ack errorCode.
         * @member {number} errorCode
         * @memberof socket_api.Ack
         * @instance
         */
        Ack.prototype.errorCode = 0;

        /**
         * Creates a new Ack instance using the specified properties.
         * @function create
         * @memberof socket_api.Ack
         * @static
         * @param {socket_api.IAck=} [properties] Properties to set
         * @returns {socket_api.Ack} Ack instance
         */
        Ack.create = function create(properties) {
            return new Ack(properties);
        };

        /**
         * Encodes the specified Ack message. Does not implicitly {@link socket_api.Ack.verify|verify} messages.
         * @function encode
         * @memberof socket_api.Ack
         * @static
         * @param {socket_api.IAck} message Ack message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Ack.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.uuid);
            if (message.errorMessage != null && Object.hasOwnProperty.call(message, "errorMessage"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.errorMessage);
            if (message.asyncProgressKey != null && Object.hasOwnProperty.call(message, "asyncProgressKey"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.asyncProgressKey);
            if (message.errorCode != null && Object.hasOwnProperty.call(message, "errorCode"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.errorCode);
            return writer;
        };

        /**
         * Encodes the specified Ack message, length delimited. Does not implicitly {@link socket_api.Ack.verify|verify} messages.
         * @function encodeDelimited
         * @memberof socket_api.Ack
         * @static
         * @param {socket_api.IAck} message Ack message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Ack.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Ack message from the specified reader or buffer.
         * @function decode
         * @memberof socket_api.Ack
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {socket_api.Ack} Ack
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Ack.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.socket_api.Ack();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.uuid = reader.string();
                    break;
                case 2:
                    message.errorMessage = reader.string();
                    break;
                case 3:
                    message.asyncProgressKey = reader.string();
                    break;
                case 4:
                    message.errorCode = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Ack message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof socket_api.Ack
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {socket_api.Ack} Ack
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Ack.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Ack message.
         * @function verify
         * @memberof socket_api.Ack
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Ack.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.uuid != null && message.hasOwnProperty("uuid"))
                if (!$util.isString(message.uuid))
                    return "uuid: string expected";
            if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                if (!$util.isString(message.errorMessage))
                    return "errorMessage: string expected";
            if (message.asyncProgressKey != null && message.hasOwnProperty("asyncProgressKey"))
                if (!$util.isString(message.asyncProgressKey))
                    return "asyncProgressKey: string expected";
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                if (!$util.isInteger(message.errorCode))
                    return "errorCode: integer expected";
            return null;
        };

        /**
         * Creates an Ack message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof socket_api.Ack
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {socket_api.Ack} Ack
         */
        Ack.fromObject = function fromObject(object) {
            if (object instanceof $root.socket_api.Ack)
                return object;
            let message = new $root.socket_api.Ack();
            if (object.uuid != null)
                message.uuid = String(object.uuid);
            if (object.errorMessage != null)
                message.errorMessage = String(object.errorMessage);
            if (object.asyncProgressKey != null)
                message.asyncProgressKey = String(object.asyncProgressKey);
            if (object.errorCode != null)
                message.errorCode = object.errorCode | 0;
            return message;
        };

        /**
         * Creates a plain object from an Ack message. Also converts values to other types if specified.
         * @function toObject
         * @memberof socket_api.Ack
         * @static
         * @param {socket_api.Ack} message Ack
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Ack.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.uuid = "";
                object.errorMessage = "";
                object.asyncProgressKey = "";
                object.errorCode = 0;
            }
            if (message.uuid != null && message.hasOwnProperty("uuid"))
                object.uuid = message.uuid;
            if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                object.errorMessage = message.errorMessage;
            if (message.asyncProgressKey != null && message.hasOwnProperty("asyncProgressKey"))
                object.asyncProgressKey = message.asyncProgressKey;
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                object.errorCode = message.errorCode;
            return object;
        };

        /**
         * Converts this Ack to JSON.
         * @function toJSON
         * @memberof socket_api.Ack
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Ack.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Ack;
    })();

    socket_api.AsyncProgress = (function() {

        /**
         * Properties of an AsyncProgress.
         * @memberof socket_api
         * @interface IAsyncProgress
         * @property {string|null} [key] AsyncProgress key
         * @property {number|null} [progress] AsyncProgress progress
         * @property {string|null} [info] AsyncProgress info
         * @property {boolean|null} [done] AsyncProgress done
         * @property {string|null} [errorMessage] AsyncProgress errorMessage
         */

        /**
         * Constructs a new AsyncProgress.
         * @memberof socket_api
         * @classdesc Represents an AsyncProgress.
         * @implements IAsyncProgress
         * @constructor
         * @param {socket_api.IAsyncProgress=} [properties] Properties to set
         */
        function AsyncProgress(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsyncProgress key.
         * @member {string} key
         * @memberof socket_api.AsyncProgress
         * @instance
         */
        AsyncProgress.prototype.key = "";

        /**
         * AsyncProgress progress.
         * @member {number} progress
         * @memberof socket_api.AsyncProgress
         * @instance
         */
        AsyncProgress.prototype.progress = 0;

        /**
         * AsyncProgress info.
         * @member {string} info
         * @memberof socket_api.AsyncProgress
         * @instance
         */
        AsyncProgress.prototype.info = "";

        /**
         * AsyncProgress done.
         * @member {boolean} done
         * @memberof socket_api.AsyncProgress
         * @instance
         */
        AsyncProgress.prototype.done = false;

        /**
         * AsyncProgress errorMessage.
         * @member {string} errorMessage
         * @memberof socket_api.AsyncProgress
         * @instance
         */
        AsyncProgress.prototype.errorMessage = "";

        /**
         * Creates a new AsyncProgress instance using the specified properties.
         * @function create
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {socket_api.IAsyncProgress=} [properties] Properties to set
         * @returns {socket_api.AsyncProgress} AsyncProgress instance
         */
        AsyncProgress.create = function create(properties) {
            return new AsyncProgress(properties);
        };

        /**
         * Encodes the specified AsyncProgress message. Does not implicitly {@link socket_api.AsyncProgress.verify|verify} messages.
         * @function encode
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {socket_api.IAsyncProgress} message AsyncProgress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsyncProgress.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            if (message.progress != null && Object.hasOwnProperty.call(message, "progress"))
                writer.uint32(/* id 2, wireType 1 =*/17).double(message.progress);
            if (message.info != null && Object.hasOwnProperty.call(message, "info"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.info);
            if (message.done != null && Object.hasOwnProperty.call(message, "done"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.done);
            if (message.errorMessage != null && Object.hasOwnProperty.call(message, "errorMessage"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.errorMessage);
            return writer;
        };

        /**
         * Encodes the specified AsyncProgress message, length delimited. Does not implicitly {@link socket_api.AsyncProgress.verify|verify} messages.
         * @function encodeDelimited
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {socket_api.IAsyncProgress} message AsyncProgress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsyncProgress.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsyncProgress message from the specified reader or buffer.
         * @function decode
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {socket_api.AsyncProgress} AsyncProgress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsyncProgress.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.socket_api.AsyncProgress();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.progress = reader.double();
                    break;
                case 3:
                    message.info = reader.string();
                    break;
                case 4:
                    message.done = reader.bool();
                    break;
                case 5:
                    message.errorMessage = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsyncProgress message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {socket_api.AsyncProgress} AsyncProgress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsyncProgress.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsyncProgress message.
         * @function verify
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsyncProgress.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.progress != null && message.hasOwnProperty("progress"))
                if (typeof message.progress !== "number")
                    return "progress: number expected";
            if (message.info != null && message.hasOwnProperty("info"))
                if (!$util.isString(message.info))
                    return "info: string expected";
            if (message.done != null && message.hasOwnProperty("done"))
                if (typeof message.done !== "boolean")
                    return "done: boolean expected";
            if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                if (!$util.isString(message.errorMessage))
                    return "errorMessage: string expected";
            return null;
        };

        /**
         * Creates an AsyncProgress message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {socket_api.AsyncProgress} AsyncProgress
         */
        AsyncProgress.fromObject = function fromObject(object) {
            if (object instanceof $root.socket_api.AsyncProgress)
                return object;
            let message = new $root.socket_api.AsyncProgress();
            if (object.key != null)
                message.key = String(object.key);
            if (object.progress != null)
                message.progress = Number(object.progress);
            if (object.info != null)
                message.info = String(object.info);
            if (object.done != null)
                message.done = Boolean(object.done);
            if (object.errorMessage != null)
                message.errorMessage = String(object.errorMessage);
            return message;
        };

        /**
         * Creates a plain object from an AsyncProgress message. Also converts values to other types if specified.
         * @function toObject
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {socket_api.AsyncProgress} message AsyncProgress
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsyncProgress.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.key = "";
                object.progress = 0;
                object.info = "";
                object.done = false;
                object.errorMessage = "";
            }
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.progress != null && message.hasOwnProperty("progress"))
                object.progress = options.json && !isFinite(message.progress) ? String(message.progress) : message.progress;
            if (message.info != null && message.hasOwnProperty("info"))
                object.info = message.info;
            if (message.done != null && message.hasOwnProperty("done"))
                object.done = message.done;
            if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                object.errorMessage = message.errorMessage;
            return object;
        };

        /**
         * Converts this AsyncProgress to JSON.
         * @function toJSON
         * @memberof socket_api.AsyncProgress
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsyncProgress.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AsyncProgress;
    })();

    socket_api.UpgradeApiVersion = (function() {

        /**
         * Properties of an UpgradeApiVersion.
         * @memberof socket_api
         * @interface IUpgradeApiVersion
         * @property {number|null} [latest] UpgradeApiVersion latest
         */

        /**
         * Constructs a new UpgradeApiVersion.
         * @memberof socket_api
         * @classdesc Represents an UpgradeApiVersion.
         * @implements IUpgradeApiVersion
         * @constructor
         * @param {socket_api.IUpgradeApiVersion=} [properties] Properties to set
         */
        function UpgradeApiVersion(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpgradeApiVersion latest.
         * @member {number} latest
         * @memberof socket_api.UpgradeApiVersion
         * @instance
         */
        UpgradeApiVersion.prototype.latest = 0;

        /**
         * Creates a new UpgradeApiVersion instance using the specified properties.
         * @function create
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {socket_api.IUpgradeApiVersion=} [properties] Properties to set
         * @returns {socket_api.UpgradeApiVersion} UpgradeApiVersion instance
         */
        UpgradeApiVersion.create = function create(properties) {
            return new UpgradeApiVersion(properties);
        };

        /**
         * Encodes the specified UpgradeApiVersion message. Does not implicitly {@link socket_api.UpgradeApiVersion.verify|verify} messages.
         * @function encode
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {socket_api.IUpgradeApiVersion} message UpgradeApiVersion message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpgradeApiVersion.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.latest != null && Object.hasOwnProperty.call(message, "latest"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.latest);
            return writer;
        };

        /**
         * Encodes the specified UpgradeApiVersion message, length delimited. Does not implicitly {@link socket_api.UpgradeApiVersion.verify|verify} messages.
         * @function encodeDelimited
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {socket_api.IUpgradeApiVersion} message UpgradeApiVersion message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpgradeApiVersion.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpgradeApiVersion message from the specified reader or buffer.
         * @function decode
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {socket_api.UpgradeApiVersion} UpgradeApiVersion
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpgradeApiVersion.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.socket_api.UpgradeApiVersion();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.latest = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpgradeApiVersion message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {socket_api.UpgradeApiVersion} UpgradeApiVersion
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpgradeApiVersion.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpgradeApiVersion message.
         * @function verify
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpgradeApiVersion.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.latest != null && message.hasOwnProperty("latest"))
                if (!$util.isInteger(message.latest))
                    return "latest: integer expected";
            return null;
        };

        /**
         * Creates an UpgradeApiVersion message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {socket_api.UpgradeApiVersion} UpgradeApiVersion
         */
        UpgradeApiVersion.fromObject = function fromObject(object) {
            if (object instanceof $root.socket_api.UpgradeApiVersion)
                return object;
            let message = new $root.socket_api.UpgradeApiVersion();
            if (object.latest != null)
                message.latest = object.latest | 0;
            return message;
        };

        /**
         * Creates a plain object from an UpgradeApiVersion message. Also converts values to other types if specified.
         * @function toObject
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {socket_api.UpgradeApiVersion} message UpgradeApiVersion
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpgradeApiVersion.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.latest = 0;
            if (message.latest != null && message.hasOwnProperty("latest"))
                object.latest = message.latest;
            return object;
        };

        /**
         * Converts this UpgradeApiVersion to JSON.
         * @function toJSON
         * @memberof socket_api.UpgradeApiVersion
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpgradeApiVersion.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpgradeApiVersion;
    })();

    return socket_api;
})();

export const uploader = $root.uploader = (() => {

    /**
     * Namespace uploader.
     * @exports uploader
     * @namespace
     */
    const uploader = {};

    uploader.UploadTask = (function() {

        /**
         * Properties of an UploadTask.
         * @memberof uploader
         * @interface IUploadTask
         * @property {number|Long|null} [created] UploadTask created
         * @property {string|null} [path] UploadTask path
         * @property {string|null} [name] UploadTask name
         * @property {string|null} [mime] UploadTask mime
         * @property {uploader.UploadStatus|null} [status] UploadTask status
         * @property {number|null} [retryCounter] UploadTask retryCounter
         * @property {string|null} [error] UploadTask error
         * @property {string|null} [fingerprint] UploadTask fingerprint
         * @property {string|null} [url] UploadTask url
         * @property {Object.<string,string>|null} [metadata] UploadTask metadata
         */

        /**
         * Constructs a new UploadTask.
         * @memberof uploader
         * @classdesc Represents an UploadTask.
         * @implements IUploadTask
         * @constructor
         * @param {uploader.IUploadTask=} [properties] Properties to set
         */
        function UploadTask(properties) {
            this.metadata = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadTask created.
         * @member {number|Long} created
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * UploadTask path.
         * @member {string} path
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.path = "";

        /**
         * UploadTask name.
         * @member {string} name
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.name = "";

        /**
         * UploadTask mime.
         * @member {string} mime
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.mime = "";

        /**
         * UploadTask status.
         * @member {uploader.UploadStatus} status
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.status = 0;

        /**
         * UploadTask retryCounter.
         * @member {number} retryCounter
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.retryCounter = 0;

        /**
         * UploadTask error.
         * @member {string} error
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.error = "";

        /**
         * UploadTask fingerprint.
         * @member {string} fingerprint
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.fingerprint = "";

        /**
         * UploadTask url.
         * @member {string} url
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.url = "";

        /**
         * UploadTask metadata.
         * @member {Object.<string,string>} metadata
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.metadata = $util.emptyObject;

        /**
         * Creates a new UploadTask instance using the specified properties.
         * @function create
         * @memberof uploader.UploadTask
         * @static
         * @param {uploader.IUploadTask=} [properties] Properties to set
         * @returns {uploader.UploadTask} UploadTask instance
         */
        UploadTask.create = function create(properties) {
            return new UploadTask(properties);
        };

        /**
         * Encodes the specified UploadTask message. Does not implicitly {@link uploader.UploadTask.verify|verify} messages.
         * @function encode
         * @memberof uploader.UploadTask
         * @static
         * @param {uploader.IUploadTask} message UploadTask message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadTask.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.created != null && Object.hasOwnProperty.call(message, "created"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.created);
            if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.path);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.mime != null && Object.hasOwnProperty.call(message, "mime"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.mime);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.status);
            if (message.retryCounter != null && Object.hasOwnProperty.call(message, "retryCounter"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.retryCounter);
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.error);
            if (message.fingerprint != null && Object.hasOwnProperty.call(message, "fingerprint"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.fingerprint);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.url);
            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                for (let keys = Object.keys(message.metadata), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 10, wireType 2 =*/82).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.metadata[keys[i]]).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UploadTask message, length delimited. Does not implicitly {@link uploader.UploadTask.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uploader.UploadTask
         * @static
         * @param {uploader.IUploadTask} message UploadTask message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadTask.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadTask message from the specified reader or buffer.
         * @function decode
         * @memberof uploader.UploadTask
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uploader.UploadTask} UploadTask
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadTask.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.uploader.UploadTask(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.created = reader.uint64();
                    break;
                case 2:
                    message.path = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                case 4:
                    message.mime = reader.string();
                    break;
                case 5:
                    message.status = reader.int32();
                    break;
                case 6:
                    message.retryCounter = reader.int32();
                    break;
                case 7:
                    message.error = reader.string();
                    break;
                case 8:
                    message.fingerprint = reader.string();
                    break;
                case 9:
                    message.url = reader.string();
                    break;
                case 10:
                    if (message.metadata === $util.emptyObject)
                        message.metadata = {};
                    let end2 = reader.uint32() + reader.pos;
                    key = "";
                    value = "";
                    while (reader.pos < end2) {
                        let tag2 = reader.uint32();
                        switch (tag2 >>> 3) {
                        case 1:
                            key = reader.string();
                            break;
                        case 2:
                            value = reader.string();
                            break;
                        default:
                            reader.skipType(tag2 & 7);
                            break;
                        }
                    }
                    message.metadata[key] = value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadTask message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uploader.UploadTask
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uploader.UploadTask} UploadTask
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadTask.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadTask message.
         * @function verify
         * @memberof uploader.UploadTask
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadTask.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.created != null && message.hasOwnProperty("created"))
                if (!$util.isInteger(message.created) && !(message.created && $util.isInteger(message.created.low) && $util.isInteger(message.created.high)))
                    return "created: integer|Long expected";
            if (message.path != null && message.hasOwnProperty("path"))
                if (!$util.isString(message.path))
                    return "path: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.mime != null && message.hasOwnProperty("mime"))
                if (!$util.isString(message.mime))
                    return "mime: string expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 3:
                case 4:
                case 5:
                case 6:
                    break;
                }
            if (message.retryCounter != null && message.hasOwnProperty("retryCounter"))
                if (!$util.isInteger(message.retryCounter))
                    return "retryCounter: integer expected";
            if (message.error != null && message.hasOwnProperty("error"))
                if (!$util.isString(message.error))
                    return "error: string expected";
            if (message.fingerprint != null && message.hasOwnProperty("fingerprint"))
                if (!$util.isString(message.fingerprint))
                    return "fingerprint: string expected";
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                if (!$util.isObject(message.metadata))
                    return "metadata: object expected";
                let key = Object.keys(message.metadata);
                for (let i = 0; i < key.length; ++i)
                    if (!$util.isString(message.metadata[key[i]]))
                        return "metadata: string{k:string} expected";
            }
            return null;
        };

        /**
         * Creates an UploadTask message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uploader.UploadTask
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uploader.UploadTask} UploadTask
         */
        UploadTask.fromObject = function fromObject(object) {
            if (object instanceof $root.uploader.UploadTask)
                return object;
            let message = new $root.uploader.UploadTask();
            if (object.created != null)
                if ($util.Long)
                    (message.created = $util.Long.fromValue(object.created)).unsigned = true;
                else if (typeof object.created === "string")
                    message.created = parseInt(object.created, 10);
                else if (typeof object.created === "number")
                    message.created = object.created;
                else if (typeof object.created === "object")
                    message.created = new $util.LongBits(object.created.low >>> 0, object.created.high >>> 0).toNumber(true);
            if (object.path != null)
                message.path = String(object.path);
            if (object.name != null)
                message.name = String(object.name);
            if (object.mime != null)
                message.mime = String(object.mime);
            switch (object.status) {
            case "scheduled":
            case 0:
                message.status = 0;
                break;
            case "uploading":
            case 1:
                message.status = 1;
                break;
            case "paused":
            case 3:
                message.status = 3;
                break;
            case "done":
            case 4:
                message.status = 4;
                break;
            case "error":
            case 5:
                message.status = 5;
                break;
            case "restored":
            case 6:
                message.status = 6;
                break;
            }
            if (object.retryCounter != null)
                message.retryCounter = object.retryCounter | 0;
            if (object.error != null)
                message.error = String(object.error);
            if (object.fingerprint != null)
                message.fingerprint = String(object.fingerprint);
            if (object.url != null)
                message.url = String(object.url);
            if (object.metadata) {
                if (typeof object.metadata !== "object")
                    throw TypeError(".uploader.UploadTask.metadata: object expected");
                message.metadata = {};
                for (let keys = Object.keys(object.metadata), i = 0; i < keys.length; ++i)
                    message.metadata[keys[i]] = String(object.metadata[keys[i]]);
            }
            return message;
        };

        /**
         * Creates a plain object from an UploadTask message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uploader.UploadTask
         * @static
         * @param {uploader.UploadTask} message UploadTask
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadTask.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.metadata = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.created = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.created = options.longs === String ? "0" : 0;
                object.path = "";
                object.name = "";
                object.mime = "";
                object.status = options.enums === String ? "scheduled" : 0;
                object.retryCounter = 0;
                object.error = "";
                object.fingerprint = "";
                object.url = "";
            }
            if (message.created != null && message.hasOwnProperty("created"))
                if (typeof message.created === "number")
                    object.created = options.longs === String ? String(message.created) : message.created;
                else
                    object.created = options.longs === String ? $util.Long.prototype.toString.call(message.created) : options.longs === Number ? new $util.LongBits(message.created.low >>> 0, message.created.high >>> 0).toNumber(true) : message.created;
            if (message.path != null && message.hasOwnProperty("path"))
                object.path = message.path;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.mime != null && message.hasOwnProperty("mime"))
                object.mime = message.mime;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.uploader.UploadStatus[message.status] : message.status;
            if (message.retryCounter != null && message.hasOwnProperty("retryCounter"))
                object.retryCounter = message.retryCounter;
            if (message.error != null && message.hasOwnProperty("error"))
                object.error = message.error;
            if (message.fingerprint != null && message.hasOwnProperty("fingerprint"))
                object.fingerprint = message.fingerprint;
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            let keys2;
            if (message.metadata && (keys2 = Object.keys(message.metadata)).length) {
                object.metadata = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.metadata[keys2[j]] = message.metadata[keys2[j]];
            }
            return object;
        };

        /**
         * Converts this UploadTask to JSON.
         * @function toJSON
         * @memberof uploader.UploadTask
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadTask.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UploadTask;
    })();

    /**
     * UploadStatus enum.
     * @name uploader.UploadStatus
     * @enum {number}
     * @property {number} scheduled=0 scheduled value
     * @property {number} uploading=1 uploading value
     * @property {number} paused=3 paused value
     * @property {number} done=4 done value
     * @property {number} error=5 error value
     * @property {number} restored=6 restored value
     */
    uploader.UploadStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "scheduled"] = 0;
        values[valuesById[1] = "uploading"] = 1;
        values[valuesById[3] = "paused"] = 3;
        values[valuesById[4] = "done"] = 4;
        values[valuesById[5] = "error"] = 5;
        values[valuesById[6] = "restored"] = 6;
        return values;
    })();

    uploader.UploadUFile = (function() {

        /**
         * Properties of an UploadUFile.
         * @memberof uploader
         * @interface IUploadUFile
         * @property {string|null} [fileId] UploadUFile fileId
         * @property {uploader.IUploadTask|null} [task] UploadUFile task
         */

        /**
         * Constructs a new UploadUFile.
         * @memberof uploader
         * @classdesc Represents an UploadUFile.
         * @implements IUploadUFile
         * @constructor
         * @param {uploader.IUploadUFile=} [properties] Properties to set
         */
        function UploadUFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadUFile fileId.
         * @member {string} fileId
         * @memberof uploader.UploadUFile
         * @instance
         */
        UploadUFile.prototype.fileId = "";

        /**
         * UploadUFile task.
         * @member {uploader.IUploadTask|null|undefined} task
         * @memberof uploader.UploadUFile
         * @instance
         */
        UploadUFile.prototype.task = null;

        /**
         * Creates a new UploadUFile instance using the specified properties.
         * @function create
         * @memberof uploader.UploadUFile
         * @static
         * @param {uploader.IUploadUFile=} [properties] Properties to set
         * @returns {uploader.UploadUFile} UploadUFile instance
         */
        UploadUFile.create = function create(properties) {
            return new UploadUFile(properties);
        };

        /**
         * Encodes the specified UploadUFile message. Does not implicitly {@link uploader.UploadUFile.verify|verify} messages.
         * @function encode
         * @memberof uploader.UploadUFile
         * @static
         * @param {uploader.IUploadUFile} message UploadUFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadUFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fileId != null && Object.hasOwnProperty.call(message, "fileId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.fileId);
            if (message.task != null && Object.hasOwnProperty.call(message, "task"))
                $root.uploader.UploadTask.encode(message.task, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UploadUFile message, length delimited. Does not implicitly {@link uploader.UploadUFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uploader.UploadUFile
         * @static
         * @param {uploader.IUploadUFile} message UploadUFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadUFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadUFile message from the specified reader or buffer.
         * @function decode
         * @memberof uploader.UploadUFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uploader.UploadUFile} UploadUFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadUFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.uploader.UploadUFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.fileId = reader.string();
                    break;
                case 2:
                    message.task = $root.uploader.UploadTask.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadUFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uploader.UploadUFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uploader.UploadUFile} UploadUFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadUFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadUFile message.
         * @function verify
         * @memberof uploader.UploadUFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadUFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                if (!$util.isString(message.fileId))
                    return "fileId: string expected";
            if (message.task != null && message.hasOwnProperty("task")) {
                let error = $root.uploader.UploadTask.verify(message.task);
                if (error)
                    return "task." + error;
            }
            return null;
        };

        /**
         * Creates an UploadUFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uploader.UploadUFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uploader.UploadUFile} UploadUFile
         */
        UploadUFile.fromObject = function fromObject(object) {
            if (object instanceof $root.uploader.UploadUFile)
                return object;
            let message = new $root.uploader.UploadUFile();
            if (object.fileId != null)
                message.fileId = String(object.fileId);
            if (object.task != null) {
                if (typeof object.task !== "object")
                    throw TypeError(".uploader.UploadUFile.task: object expected");
                message.task = $root.uploader.UploadTask.fromObject(object.task);
            }
            return message;
        };

        /**
         * Creates a plain object from an UploadUFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uploader.UploadUFile
         * @static
         * @param {uploader.UploadUFile} message UploadUFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadUFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.fileId = "";
                object.task = null;
            }
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                object.fileId = message.fileId;
            if (message.task != null && message.hasOwnProperty("task"))
                object.task = $root.uploader.UploadTask.toObject(message.task, options);
            return object;
        };

        /**
         * Converts this UploadUFile to JSON.
         * @function toJSON
         * @memberof uploader.UploadUFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadUFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UploadUFile;
    })();

    uploader.DeleteUFile = (function() {

        /**
         * Properties of a DeleteUFile.
         * @memberof uploader
         * @interface IDeleteUFile
         * @property {string|null} [id] DeleteUFile id
         */

        /**
         * Constructs a new DeleteUFile.
         * @memberof uploader
         * @classdesc Represents a DeleteUFile.
         * @implements IDeleteUFile
         * @constructor
         * @param {uploader.IDeleteUFile=} [properties] Properties to set
         */
        function DeleteUFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteUFile id.
         * @member {string} id
         * @memberof uploader.DeleteUFile
         * @instance
         */
        DeleteUFile.prototype.id = "";

        /**
         * Creates a new DeleteUFile instance using the specified properties.
         * @function create
         * @memberof uploader.DeleteUFile
         * @static
         * @param {uploader.IDeleteUFile=} [properties] Properties to set
         * @returns {uploader.DeleteUFile} DeleteUFile instance
         */
        DeleteUFile.create = function create(properties) {
            return new DeleteUFile(properties);
        };

        /**
         * Encodes the specified DeleteUFile message. Does not implicitly {@link uploader.DeleteUFile.verify|verify} messages.
         * @function encode
         * @memberof uploader.DeleteUFile
         * @static
         * @param {uploader.IDeleteUFile} message DeleteUFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteUFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Encodes the specified DeleteUFile message, length delimited. Does not implicitly {@link uploader.DeleteUFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uploader.DeleteUFile
         * @static
         * @param {uploader.IDeleteUFile} message DeleteUFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteUFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteUFile message from the specified reader or buffer.
         * @function decode
         * @memberof uploader.DeleteUFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uploader.DeleteUFile} DeleteUFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteUFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.uploader.DeleteUFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteUFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uploader.DeleteUFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uploader.DeleteUFile} DeleteUFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteUFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteUFile message.
         * @function verify
         * @memberof uploader.DeleteUFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteUFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            return null;
        };

        /**
         * Creates a DeleteUFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uploader.DeleteUFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uploader.DeleteUFile} DeleteUFile
         */
        DeleteUFile.fromObject = function fromObject(object) {
            if (object instanceof $root.uploader.DeleteUFile)
                return object;
            let message = new $root.uploader.DeleteUFile();
            if (object.id != null)
                message.id = String(object.id);
            return message;
        };

        /**
         * Creates a plain object from a DeleteUFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uploader.DeleteUFile
         * @static
         * @param {uploader.DeleteUFile} message DeleteUFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteUFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.id = "";
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            return object;
        };

        /**
         * Converts this DeleteUFile to JSON.
         * @function toJSON
         * @memberof uploader.DeleteUFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteUFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteUFile;
    })();

    uploader.UploadSlot = (function() {

        /**
         * Properties of an UploadSlot.
         * @memberof uploader
         * @interface IUploadSlot
         * @property {uploader.IUFile|null} [file] UploadSlot file
         * @property {Object.<string,string>|null} [metadata] UploadSlot metadata
         */

        /**
         * Constructs a new UploadSlot.
         * @memberof uploader
         * @classdesc Represents an UploadSlot.
         * @implements IUploadSlot
         * @constructor
         * @param {uploader.IUploadSlot=} [properties] Properties to set
         */
        function UploadSlot(properties) {
            this.metadata = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadSlot file.
         * @member {uploader.IUFile|null|undefined} file
         * @memberof uploader.UploadSlot
         * @instance
         */
        UploadSlot.prototype.file = null;

        /**
         * UploadSlot metadata.
         * @member {Object.<string,string>} metadata
         * @memberof uploader.UploadSlot
         * @instance
         */
        UploadSlot.prototype.metadata = $util.emptyObject;

        /**
         * Creates a new UploadSlot instance using the specified properties.
         * @function create
         * @memberof uploader.UploadSlot
         * @static
         * @param {uploader.IUploadSlot=} [properties] Properties to set
         * @returns {uploader.UploadSlot} UploadSlot instance
         */
        UploadSlot.create = function create(properties) {
            return new UploadSlot(properties);
        };

        /**
         * Encodes the specified UploadSlot message. Does not implicitly {@link uploader.UploadSlot.verify|verify} messages.
         * @function encode
         * @memberof uploader.UploadSlot
         * @static
         * @param {uploader.IUploadSlot} message UploadSlot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadSlot.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.file != null && Object.hasOwnProperty.call(message, "file"))
                $root.uploader.UFile.encode(message.file, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                for (let keys = Object.keys(message.metadata), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.metadata[keys[i]]).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UploadSlot message, length delimited. Does not implicitly {@link uploader.UploadSlot.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uploader.UploadSlot
         * @static
         * @param {uploader.IUploadSlot} message UploadSlot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadSlot.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadSlot message from the specified reader or buffer.
         * @function decode
         * @memberof uploader.UploadSlot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uploader.UploadSlot} UploadSlot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadSlot.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.uploader.UploadSlot(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.file = $root.uploader.UFile.decode(reader, reader.uint32());
                    break;
                case 2:
                    if (message.metadata === $util.emptyObject)
                        message.metadata = {};
                    let end2 = reader.uint32() + reader.pos;
                    key = "";
                    value = "";
                    while (reader.pos < end2) {
                        let tag2 = reader.uint32();
                        switch (tag2 >>> 3) {
                        case 1:
                            key = reader.string();
                            break;
                        case 2:
                            value = reader.string();
                            break;
                        default:
                            reader.skipType(tag2 & 7);
                            break;
                        }
                    }
                    message.metadata[key] = value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadSlot message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uploader.UploadSlot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uploader.UploadSlot} UploadSlot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadSlot.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadSlot message.
         * @function verify
         * @memberof uploader.UploadSlot
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadSlot.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.file != null && message.hasOwnProperty("file")) {
                let error = $root.uploader.UFile.verify(message.file);
                if (error)
                    return "file." + error;
            }
            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                if (!$util.isObject(message.metadata))
                    return "metadata: object expected";
                let key = Object.keys(message.metadata);
                for (let i = 0; i < key.length; ++i)
                    if (!$util.isString(message.metadata[key[i]]))
                        return "metadata: string{k:string} expected";
            }
            return null;
        };

        /**
         * Creates an UploadSlot message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uploader.UploadSlot
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uploader.UploadSlot} UploadSlot
         */
        UploadSlot.fromObject = function fromObject(object) {
            if (object instanceof $root.uploader.UploadSlot)
                return object;
            let message = new $root.uploader.UploadSlot();
            if (object.file != null) {
                if (typeof object.file !== "object")
                    throw TypeError(".uploader.UploadSlot.file: object expected");
                message.file = $root.uploader.UFile.fromObject(object.file);
            }
            if (object.metadata) {
                if (typeof object.metadata !== "object")
                    throw TypeError(".uploader.UploadSlot.metadata: object expected");
                message.metadata = {};
                for (let keys = Object.keys(object.metadata), i = 0; i < keys.length; ++i)
                    message.metadata[keys[i]] = String(object.metadata[keys[i]]);
            }
            return message;
        };

        /**
         * Creates a plain object from an UploadSlot message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uploader.UploadSlot
         * @static
         * @param {uploader.UploadSlot} message UploadSlot
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadSlot.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.metadata = {};
            if (options.defaults)
                object.file = null;
            if (message.file != null && message.hasOwnProperty("file"))
                object.file = $root.uploader.UFile.toObject(message.file, options);
            let keys2;
            if (message.metadata && (keys2 = Object.keys(message.metadata)).length) {
                object.metadata = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.metadata[keys2[j]] = message.metadata[keys2[j]];
            }
            return object;
        };

        /**
         * Converts this UploadSlot to JSON.
         * @function toJSON
         * @memberof uploader.UploadSlot
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadSlot.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UploadSlot;
    })();

    uploader.UFile = (function() {

        /**
         * Properties of a UFile.
         * @memberof uploader
         * @interface IUFile
         * @property {string|null} [id] UFile id
         * @property {string|null} [src] UFile src
         * @property {string|null} [preview] UFile preview
         * @property {string|null} [previewLarge] UFile previewLarge
         * @property {string|null} [name] UFile name
         * @property {string|null} [uploadTask] UFile uploadTask
         * @property {string|null} [localPath] UFile localPath
         */

        /**
         * Constructs a new UFile.
         * @memberof uploader
         * @classdesc Represents a UFile.
         * @implements IUFile
         * @constructor
         * @param {uploader.IUFile=} [properties] Properties to set
         */
        function UFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UFile id.
         * @member {string} id
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.id = "";

        /**
         * UFile src.
         * @member {string} src
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.src = "";

        /**
         * UFile preview.
         * @member {string} preview
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.preview = "";

        /**
         * UFile previewLarge.
         * @member {string} previewLarge
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.previewLarge = "";

        /**
         * UFile name.
         * @member {string} name
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.name = "";

        /**
         * UFile uploadTask.
         * @member {string} uploadTask
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.uploadTask = "";

        /**
         * UFile localPath.
         * @member {string} localPath
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.localPath = "";

        /**
         * Creates a new UFile instance using the specified properties.
         * @function create
         * @memberof uploader.UFile
         * @static
         * @param {uploader.IUFile=} [properties] Properties to set
         * @returns {uploader.UFile} UFile instance
         */
        UFile.create = function create(properties) {
            return new UFile(properties);
        };

        /**
         * Encodes the specified UFile message. Does not implicitly {@link uploader.UFile.verify|verify} messages.
         * @function encode
         * @memberof uploader.UFile
         * @static
         * @param {uploader.IUFile} message UFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.src != null && Object.hasOwnProperty.call(message, "src"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.src);
            if (message.preview != null && Object.hasOwnProperty.call(message, "preview"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.preview);
            if (message.previewLarge != null && Object.hasOwnProperty.call(message, "previewLarge"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.previewLarge);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.name);
            if (message.uploadTask != null && Object.hasOwnProperty.call(message, "uploadTask"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.uploadTask);
            if (message.localPath != null && Object.hasOwnProperty.call(message, "localPath"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.localPath);
            return writer;
        };

        /**
         * Encodes the specified UFile message, length delimited. Does not implicitly {@link uploader.UFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uploader.UFile
         * @static
         * @param {uploader.IUFile} message UFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UFile message from the specified reader or buffer.
         * @function decode
         * @memberof uploader.UFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uploader.UFile} UFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.uploader.UFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.src = reader.string();
                    break;
                case 3:
                    message.preview = reader.string();
                    break;
                case 4:
                    message.previewLarge = reader.string();
                    break;
                case 5:
                    message.name = reader.string();
                    break;
                case 6:
                    message.uploadTask = reader.string();
                    break;
                case 7:
                    message.localPath = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uploader.UFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uploader.UFile} UFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UFile message.
         * @function verify
         * @memberof uploader.UFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.src != null && message.hasOwnProperty("src"))
                if (!$util.isString(message.src))
                    return "src: string expected";
            if (message.preview != null && message.hasOwnProperty("preview"))
                if (!$util.isString(message.preview))
                    return "preview: string expected";
            if (message.previewLarge != null && message.hasOwnProperty("previewLarge"))
                if (!$util.isString(message.previewLarge))
                    return "previewLarge: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.uploadTask != null && message.hasOwnProperty("uploadTask"))
                if (!$util.isString(message.uploadTask))
                    return "uploadTask: string expected";
            if (message.localPath != null && message.hasOwnProperty("localPath"))
                if (!$util.isString(message.localPath))
                    return "localPath: string expected";
            return null;
        };

        /**
         * Creates a UFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uploader.UFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uploader.UFile} UFile
         */
        UFile.fromObject = function fromObject(object) {
            if (object instanceof $root.uploader.UFile)
                return object;
            let message = new $root.uploader.UFile();
            if (object.id != null)
                message.id = String(object.id);
            if (object.src != null)
                message.src = String(object.src);
            if (object.preview != null)
                message.preview = String(object.preview);
            if (object.previewLarge != null)
                message.previewLarge = String(object.previewLarge);
            if (object.name != null)
                message.name = String(object.name);
            if (object.uploadTask != null)
                message.uploadTask = String(object.uploadTask);
            if (object.localPath != null)
                message.localPath = String(object.localPath);
            return message;
        };

        /**
         * Creates a plain object from a UFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uploader.UFile
         * @static
         * @param {uploader.UFile} message UFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.src = "";
                object.preview = "";
                object.previewLarge = "";
                object.name = "";
                object.uploadTask = "";
                object.localPath = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.src != null && message.hasOwnProperty("src"))
                object.src = message.src;
            if (message.preview != null && message.hasOwnProperty("preview"))
                object.preview = message.preview;
            if (message.previewLarge != null && message.hasOwnProperty("previewLarge"))
                object.previewLarge = message.previewLarge;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.uploadTask != null && message.hasOwnProperty("uploadTask"))
                object.uploadTask = message.uploadTask;
            if (message.localPath != null && message.hasOwnProperty("localPath"))
                object.localPath = message.localPath;
            return object;
        };

        /**
         * Converts this UFile to JSON.
         * @function toJSON
         * @memberof uploader.UFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UFile;
    })();

    return uploader;
})();

export { $root as default };
