import styled from "styled-components";
import {presentation} from "../proto/compiled";
import {PresentationContentImage} from "./presentation_content_image";
import {PresentationContentVideo} from "./presentation_content_video";
import {PresentationContentJsx} from "./presentation_content_jsx";

const Container = styled.div`
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 100%;
`;

interface PresentationContentProps {
    content: presentation.IPresentationContent
}

export const PresentationContent = (props: PresentationContentProps) => {
    return <Container>
        {props.content.image && <PresentationContentImage content={props.content.image}/>}
        {props.content.video && <PresentationContentVideo content={props.content.video}/>}
        {props.content.jsx && <PresentationContentJsx content={props.content.jsx}/>}
    </Container>;
}