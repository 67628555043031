import {makeAutoObservable} from "mobx";
import {SocketApi} from "proto_socket_typescript";
import {presentation} from "../proto/compiled";
import {proto} from "../proto/messages";
import {SocketApiTxStatus} from "proto_socket_typescript/lib/socket_api";
import {TpRoutes} from "../strings";

export class PresentationStore {
    api: SocketApi;
    presentation?: presentation.IPresentation;
    presentationSlice?: presentation.IPresentationSlice;
    private autoplayTimeout?: NodeJS.Timeout;

    constructor(api: SocketApi) {
        this.api = api;

        this.api.getMessageHandler(new proto.RxPresentation()).subscribe((m) => {
            this.presentation = m.proto;
        });
        this.api.getMessageHandler(new proto.RxPresentationSlice()).subscribe((m) => this.onPresentationSlice(m));

        makeAutoObservable(this);
    }

    open(location: string, sublocation: string, presentation: string): Promise<SocketApiTxStatus> {
        return this.api.sendMessage(proto.TxLoadPresentation.create({
            locationKey: location,
            sublocationKey: sublocation,
            presentationKey: presentation,
        }));
    }

    async next(): Promise<SocketApiTxStatus | undefined> {
        if (!this.presentationSlice?.nextSlideId || !this.presentationSlice?.nextSequenceId) return;
        return await this.loadSlide(this.presentationSlice.nextSequenceId, this.presentationSlice.nextSlideId);
    }

    async prev(): Promise<SocketApiTxStatus | undefined> {
        if (!this.presentationSlice?.prevSlideId || !this.presentationSlice?.prevSequenceId) return;
        return await this.loadSlide(this.presentationSlice.prevSequenceId, this.presentationSlice.prevSlideId);
    }

    async loadSlide(sequenceId?: string, slide?: string): Promise<SocketApiTxStatus | undefined> {
        if (!this.presentation) return;
        if (this.autoplayTimeout) {
            clearTimeout(this.autoplayTimeout);
        }

        return await this.api.sendMessage(proto.TxLoadPresentationSlice.create({
            presentationKey: this.presentation.key,
            sequenceId: sequenceId,
            slideId: slide,
        }), {ack: true});
    }

    private onPresentationSlice(message: proto.RxPresentationSlice) {
        this.presentationSlice = message.proto;
        window.history.replaceState(null, '', TpRoutes.presentationSlide(
            TpRoutes.presentation(this.presentation!.location!.text!, this.presentation!.sublocation!.text!, this.presentation!.key!),
            this.presentationSlice.sequenceId!,
            this.presentationSlice.slideId!,
        ));

        // handle autoplay
        if (this.presentationSlice.durationS) {
            this.autoplayTimeout = setTimeout(() => {
                this.next();
            }, this.presentationSlice.durationS * 1e3);
        }
    }
}
